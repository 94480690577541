import { addDoc, doc, setDoc, getFirestore, getDoc, collection, updateDoc, arrayUnion, runTransaction, getDocs, query, where, deleteDoc, documentId, orderBy } from "@firebase/firestore"; 
import { onAuthStateChanged, getAuth } from '@firebase/auth';
import Cookies from "js-cookie";
import makeid from "./makeid";

export let userAndReadiness = {user: null, ready: false};

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
    if (user) {
        userAndReadiness = {user: user, ready: true};
        if(Cookies.get("isLoggedIn") !== "true"){
            Cookies.set('isLoggedIn', 'true', {path: '/'});
        }
    } else {
        userAndReadiness = {user: null, ready: true};
        Cookies.set('isLoggedIn', 'false', {path: '/'});
        const loggedInRequiredPages = ["/Dashboard","/ActivityClasses","/Setting","/ActivitySetting","/ActivityStudents","/StudentProfile","/Volunteer","/User","AddRecord","CommunityStudents", "/Dashboard", "/SelectClass", "/Admin"];
        for(const loggedInRequiredPage of loggedInRequiredPages){
            if(!window.location.href.toLowerCase().includes(loggedInRequiredPage.toLowerCase())) continue;
            const redirectLink = encodeURIComponent(window.location.href.toString().slice(window.location.href.toString().indexOf(loggedInRequiredPage)));
            window.location.href = "/Login?redirect=" + redirectLink;
            break;
        }

    }
});

// The sign in action is already taken care of by the signInWithPopup() method provided
// by Firebase. Here we check if the user exists and try adding the user data into the db.
export async function addUser(uid, userName, userEmail, userType, referenceId, redirect="", toJoin=null){
    const db = getFirestore();
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    if(!userType) userType = "volunteer";

    // When the user's data is not existing, create the user data 
    // and then redirects the user to the signup page 
    if(!userSnap.exists() || (userSnap.exists() && !userSnap.data().referenceId)){
        setDoc(userRef, {
                'userDisplayName': userName,
                'userEmail': userEmail,
                'referenceId': referenceId,
                'userTypeRecorded': true,
                'userType': userType,
                'toJoin': toJoin,
                'lineUserId': []
            }, {
                merge: true
            })
            .then(() => {
                window.location.href = "/Signup?userType=" + userType + "&toJoin=" + toJoin;
            });

    // The user already exists. No action needed. Simply redirects. 
    }else{
        window.location.href = redirect;
    }
}

// Updates the infomrmation of *this* user in Firestore
export function updateUser(updateContent, notificationFunction=null, thenFunction=null){
    const db = getFirestore();
    const userRef = doc(db, "users", userAndReadiness.user.uid);
    notificationFunction && notificationFunction("儲存中...")
    setDoc(userRef, updateContent, {merge: true})
        .then(() => {
            notificationFunction && notificationFunction("儲存完成")
            thenFunction && thenFunction();
        });
}

export async function updateStudentInCommunity(updateContent, communityReferenceId, 
    studentReferenceId, notificationFunction=null, thenFunction=null){
    
    const db = getFirestore();
    const studentDoc = doc(db, 
        `communityStudents/${communityReferenceId}/students`, studentReferenceId);
    notificationFunction && notificationFunction("儲存中...")
    setDoc(studentDoc, updateContent, {merge: true})
        .then(() => {
            notificationFunction && notificationFunction("儲存完成");
            thenFunction && thenFunction();
        })
}

export async function deleteStudentInCommunity(communityReferenceId, studentReferenceId,
    notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    const studentDoc = doc(db, 
        `communityStudents/${communityReferenceId}/students`, studentReferenceId);
    notificationFunction && notificationFunction("刪除中...")
    await deleteDoc(studentDoc)
        .then(() => {
            notificationFunction && notificationFunction("刪除完成");
            thenFunction && thenFunction();
        })
}

export async function updateUserByReferenceId(referenceId, updateContent, notificationFunction=null, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("referenceId", "==", referenceId));
    let userDocId = "";
    const userSnap = await getDocs(q);
    userSnap.forEach((userDoc) => userDocId = userDoc.id);
    const userRef = doc(db, "users", userDocId);
    notificationFunction && notificationFunction("儲存中...");
    setDoc(userRef, updateContent, {merge: true})
        .then(() => {
            notificationFunction && notificationFunction("儲存完成")
            thenFunction && thenFunction();
        });
}

export async function updateActivityPublicInfoByActvid(actvid, updateContent, notificationFunction=null, thenFunction=null){
    const db = getFirestore();
    const activityPublicCollectionRef = collection(db, "activitiesPublicInfo");
    const q = query(activityPublicCollectionRef, where("actvid", "==", actvid));
    let activityPublicInfoDocId = "";
    const activityPublicInfoSnap = await getDocs(q);
    activityPublicInfoSnap.forEach((activityPublicInfoDoc) => activityPublicInfoDocId = activityPublicInfoDoc.id);
    const activityPublicInfoRef = doc(db, "activitiesPublicInfo", activityPublicInfoDocId);
    notificationFunction && notificationFunction("儲存中...");
    setDoc(activityPublicInfoRef, updateContent, {merge: true})
        .then(() => {
            notificationFunction && notificationFunction("儲存完成")
            thenFunction && thenFunction();
        });
}


export async function getActivityPublicInfo(actvid, thenFunction){
    const db = getFirestore();
    const activitiesPublicInfoRef = collection(db, "activitiesPublicInfo");
    const q = query(activitiesPublicInfoRef, where("actvid", "==", actvid));
    const activitySnap = await getDocs(q);
    activitySnap.forEach((activityDoc) => {
        thenFunction && thenFunction(activityDoc.data());
        return activityDoc.data();
    })
}

export async function getUser(thenFunction, uid=null){
    const db = getFirestore();
    const userRef = doc(db, "users", uid || userAndReadiness.user.uid);
    const userSnap = await getDoc(userRef);
    if(userSnap.exists()){
        thenFunction(userSnap.data());
    }else{
        thenFunction(false);
    }
}

export async function getUserByReferenceId(referenceId, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("referenceId", "==", referenceId));
    const userSnap = await getDocs(q);
    userSnap.forEach((userDoc) => {
        thenFunction && thenFunction(userDoc.data());
        return userDoc.data();
    })
}

export async function getUsersByType(userTypes, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("userType", "in", userTypes));
    const userSnap = await(getDocs(q));
    const returnList = [];
    userSnap.forEach((userDoc) => {
        returnList.push(userDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getUsersByReferenceIds(referenceIds, thenFunction){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("referenceId", "in", referenceIds));
    const userSnap = await getDocs(q);
    const returnList = [];
    userSnap.forEach((userDoc) => {
        returnList.push(userDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getVolunteersByActvid(actvid, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("actvid", "==", actvid), 
        where("userType", "==", "volunteer"));
    const userSnap = await(getDocs(q));
    const returnList = [];
    userSnap.forEach((userDoc) => {
        returnList.push(userDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getUsersByToJoin(actvid, onlyPending=false, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("toJoin", "==", actvid));
    const userSnap = await(getDocs(q));
    const returnList = [];
    userSnap.forEach((userDoc) => {
        if(userDoc.data().actvid === actvid && onlyPending){
            // Do nothing
        }else{
            returnList.push(userDoc.data());
        }
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getUsers(referenceIds, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, where("referenceId", "in", referenceIds));
    const userSnap = await getDocs(q);
    const returnList = [];
    userSnap.forEach((userDoc) => {
        returnList.push(userDoc.data());
    })
    thenFunction && thenFunction(returnList);
}

export async function getActivitiesByCommunity(communityReferenceId, thenFunction=null){
    const db = getFirestore();
    const activitiyCollectionRef = collection(db, "activitiesPublicInfo");
    const q = query(activitiyCollectionRef, where("communityReferenceId", "==", communityReferenceId));
    const activitySnap = await getDocs(q);
    const returnList = [];
    activitySnap.forEach((activityDoc) => {
        returnList.push(activityDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getActivitiesPublicInfoByActvids(actvids, thenFunction=null){
    const db = getFirestore();
    const activityPublicCollectionRef = collection(db, "activitiesPublicInfo");
    const q = query(activityPublicCollectionRef, where("actvid", "in", actvids));
    const activityDocs = await getDocs(q);
    const returnList = [];
    activityDocs.forEach((activityDoc) => {
        returnList.push(activityDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getShowcaseActivitiesPublicInfo(thenFunction=null){
    const db = getFirestore();
    const activitiesPublicInfoRef = collection(db, "activitiesPublicInfo");
    const q = query(activitiesPublicInfoRef, where("activated", "!=", "ended"));
    const activityDocs = await getDocs(q);
    const returnList = [];
    activityDocs.forEach((activityDoc) => {
        returnList.push(activityDoc.data());
    });
    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function createActivity(communityReferenceId, thenFunction=null){
    const db = getFirestore();
    const activityPublicCollectionRef = collection(db, "activitiesPublicInfo");

    const actvid = makeid(20);

    const activityRef = await addDoc(activityPublicCollectionRef, {
        communityReferenceId: communityReferenceId,
        actvid: actvid,
        name: "新增活動梯次",
        activated: "planning"
    });

    updateUserByReferenceId(communityReferenceId, {activities: arrayUnion(actvid)});

    await setDoc(doc(db, "activities", activityRef.id), {
        communityReferenceId: communityReferenceId,
        actvid: actvid,
    });

    thenFunction && thenFunction();
}

export async function getCommunityStudentsByCommunityReferenceId(communityReferenceId, thenFunction=null){
    const db = getFirestore();
    const communityStudentsCollectionRef = collection(db, `communityStudents/${communityReferenceId}/students`);
    const q = query(communityStudentsCollectionRef)
    const studentDocs = await getDocs(q);

    const returnList = [];
    studentDocs.forEach(studentDoc => {
        returnList.push(studentDoc.data());
        returnList[returnList.length - 1].referenceId = studentDoc.id;
    });

    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function createStudentInCommunity(communityReferenceId, studentReferenceId, name, 
    notificationFunction=null, thenFunction=null){

    notificationFunction && notificationFunction("新增中...");
    const db = getFirestore();
    setDoc(doc(db, `communityStudents/${communityReferenceId}/students/${studentReferenceId}`), {
        name: name,
        coins: 0,
        note: "",
        records: [],
        studentReferenceId: studentReferenceId
    }).then(() => {
        notificationFunction && notificationFunction("新增完成");
        thenFunction && thenFunction();
    })
}

export async function createClassInActivity(actvid, volunteerReferenceId, classData,
    notificationFunction=null, thenFunction=null){

    notificationFunction && notificationFunction("儲存中...");

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const q = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(q);
    let activityDocId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
    })

    const classId = makeid(15);
    const saveData = classData;
    saveData.volunteerReferenceId = volunteerReferenceId;
    saveData.classId = classId;
    await setDoc(doc(db, `activities/${activityDocId}/classes`, classId), saveData, {
        merge: true
    }).then(() => {
        notificationFunction && notificationFunction("儲存完成");
        thenFunction && thenFunction();
    })
}

export async function getClassesInActivityByReferenceId(actvid, userType, referenceId, thenFunction=null){

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(activityQuery);
    let activityDocId = "";
    let communityReferenceId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
        communityReferenceId = activityDoc.data().communityReferenceId;
    });

    const classesCollectionRef = collection(db, `activities/${activityDocId}/classes`);
    let classQuery = null;
    if(userType === "volunteer") 
        classQuery = query(classesCollectionRef, where("volunteerReferenceId", "==", referenceId));
    else if(userType === "student")
        classQuery = query(classesCollectionRef, where("students", "array-contains", referenceId));
    else if(userType === "community")
        classQuery = query(classesCollectionRef);

    const classDocs = await getDocs(classQuery);
    const returnList = [];
    classDocs.forEach(classDoc => {
        const thisClass = classDoc.data();
        thisClass.communityReferenceId = communityReferenceId;
        returnList.push(thisClass);
    });

    thenFunction && thenFunction(returnList)
    return returnList;
}

export async function getClassesInActivityByVolunteerReferenceId(actvid, volunteerReferenceId, 
    notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(activityQuery);
    let activityDocId = "";
    let communityReferenceId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
        communityReferenceId = activityDoc.data().communityReferenceId;
    })

    const classesCollectionRef = collection(db, `activities/${activityDocId}/classes`);
    const q = query(classesCollectionRef, where("volunteerReferenceId", "==", volunteerReferenceId));
    const classDocs = await getDocs(q);
    const returnList = [];
    classDocs.forEach(classDoc => {
        const thisClass = classDoc.data();
        thisClass.communityReferenceId = communityReferenceId;
        returnList.push(thisClass);
    })

    thenFunction && thenFunction(returnList)
    return returnList;

}

export async function getClassesInActivity(actvid, thenFunction=null){

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(activityQuery);
    let activityDocId = "";
    let communityReferenceId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
        communityReferenceId = activityDoc.data().communityReferenceId;
    });

    const classesCollectionRef = collection(db, `activities/${activityDocId}/classes`);
    const classDocs = await getDocs(classesCollectionRef);
    const returnList = [];
    classDocs.forEach(classDoc => {
        const thisClass = classDoc.data();
        thisClass.communityReferenceId = communityReferenceId;
        returnList.push(thisClass);
    })

    thenFunction && thenFunction(returnList)
    return returnList;
}

export async function updateClassInActivityByClassId(actvid, classId, updateContent, 
    notificationFunction=null, thenFunction=null){

    notificationFunction && notificationFunction("儲存中...")

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(activityQuery);
    let activityDocId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
    })

    const classDoc = doc(db, `activities/${activityDocId}/classes/${classId}`);
    await setDoc(classDoc, updateContent, {
        merge: true
    }).then(() => {
        notificationFunction && notificationFunction("儲存完成");
        thenFunction && thenFunction();
    })

}

export async function deleteClassFromActivity(actvid, classId, notificationFunction=null, thenFunction=null){

    notificationFunction && notificationFunction("刪除中...")

    const db = getFirestore();
    const activitiesCollectionRef = collection(db, "activities");
    const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
    const activityDocs = await getDocs(activityQuery);
    let activityDocId = "";
    activityDocs.forEach(activityDoc => {
        activityDocId = activityDoc.id;
    })
    const classDoc = doc(db, `activities/${activityDocId}/classes/${classId}`);

    await deleteDoc(classDoc).then(() => {
        thenFunction && thenFunction();
    })
}

export async function getStudentsFromCommunity(communityReferenceId, studentReferenceIds,
    notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    const communityStudentsCollectionRef = 
        collection(db, `communityStudents/${communityReferenceId}/students`);
    const q = query(communityStudentsCollectionRef, where(documentId(), "in", studentReferenceIds));
    
    const returnList = [];
    const studentDocs = await getDocs(q);
    studentDocs.forEach(studentDoc => {
        returnList.push(studentDoc.data());
    })

    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getAllStudentsFromCommunity(communityReferenceId, thenFunction=null){
    const db = getFirestore();
    const communityStudentsCollectionRef = 
        collection(db, `communityStudents/${communityReferenceId}/students`);
    
    const returnList = [];
    const studentDocs = await getDocs(communityStudentsCollectionRef);
    studentDocs.forEach(studentDoc => {
        returnList.push(studentDoc.data());
    })

    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getVolunteersByActivities(actvid, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, 
        where("activities", "array-contains", actvid), 
        where("userType", "==", "volunteer"),
        orderBy("name"));

    const returnList = [];
    const studentDocs = await getDocs(q);
    studentDocs.forEach(studentDoc => {
        returnList.push(studentDoc.data());
    })

    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getStudentsByActivities(actvid, thenFunction=null){
    const db = getFirestore();
    const userCollectionRef = collection(db, "users");
    const q = query(userCollectionRef, 
        where("activities", "array-contains", actvid), 
        where("userType", "==", "student"),
        orderBy("name"));

    const returnList = [];
    const studentDocs = await getDocs(q);
    studentDocs.forEach(studentDoc => {
        returnList.push(studentDoc.data());
    })

    thenFunction && thenFunction(returnList);
    return returnList;
}

export async function getBillboardData(thenFunction=null){
    const db = getFirestore();
    const billboardDocRef = doc(db, "system/billboard");
    const billboardData = await getDoc(billboardDocRef);
    if(billboardData.exists()){
        thenFunction && thenFunction(billboardData.data());
        return billboardData.data();
    } 
}

export async function setBillboardFieldData(field, itemList, notificationFunction=null){
    const db = getFirestore();
    notificationFunction && notificationFunction("儲存中...")
    const billboardDocRef = doc(db, "system/billboard");
    const updateObject = {};
    updateObject[field] = itemList;
    await updateDoc(billboardDocRef, updateObject)
        .then(() => {
            notificationFunction && notificationFunction("儲存完成")
        })

}