import "../style/RectangularChip.css";

export function RectangularChip(props){
    const style = Object.assign({}, (props.style || {}));
    
    style.backgroundColor = props.color;
    style.fontSize = props.fontSize + "px";

    return (
        <div className="rectangular-chip" style={ style }>
            { props.children }
        </div>
    )
}