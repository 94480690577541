import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList, SubmitButton, TextBox } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import ObjectAttributeView from "../components/ObjectAttributeView";
import { TableFrame, TableRow } from "../components/Table";
import { strings } from "../lib/strings";
import { getUserByReferenceId, updateUserByReferenceId } from "../lib/user";
import "../style/User.css";

export default function User(props){

    const topLevelStates = useContext(topLevelStatesContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const referenceId = searchParams.get("referenceId");

    const [userData, setUserData] = useState(null);
    useEffect(() => {
        if(userData === null){
            getUserByReferenceId(referenceId, setUserData);
        }
    }, [topLevelStates.hasLoaded]);
    
    const userObjectAttributes = {
        accountRegistered: "(true/false) 使用者帳號已經註冊完成【2022/06 前所註冊的帳號無此屬性】",
        activities: "(array) 使用者能夠操作的活動，包含任何已結束或未開始的活動",
        actvid: "(string) 使用者*目前*參與的活動 ID，適用於學生與志工使用者",
        admin: "(true/false) 標示使用者是否為管理員與擁有管理員權限",
        availableTime: "(object: 'day': ['time']) 使用者可以上課的時間【此屬性已於 2022/06 後被淘汰】",
        cellphone: "(string) 使用者的手機號碼",
        grade: "(string) 使用者的年級",
        lineId: "(string) 使用者的 LINE ID",
        lineUserId: "(array) 一個包含使用者 LINE USER ID 的陣列。LINE USER ID 與 LINE ID 不同，用於與 LINE 的伺服器溝通（傳送提醒）時。【LINE 提醒功能已於 2022/06 後被淘汰】",
        name: "(string) 使用者的真實姓名",
        notificationTokens: "(array) 一個包含推播通知代碼的陣列。每一個代碼代表一個使用者的裝置，在需要上課提醒時，資料會藉由 Firebase 的系統傳送到各個代碼相對應的裝置",
        referenceId: "(string) 使用者的 ID，用來於平台各處辨認使用者的身份",
        school: "(string) 使用者的學校名稱",
        sex: "(string) 使用者的性別敘述",
        toJoin: "(string) 使用者將要加入，但尚未被核准加入的活動的 Activity ID（actvid）",
        userDisplayName: "(string) 使用者 Google 帳號的名稱",
        userEmail: "(string) 使用者 Google 帳號的信箱",
        userInformationRecorded: "(string) 使用者資料已經登記完成【2022/06 後所註冊的帳號無此屬性】",
        userType: "('community'/'volunteer'/'student') 使用者的帳號類別",
        userTypeRecorded: "(string) 使用者帳號類別已經登記完成【2022/06 後所註冊的帳號無此屬性】"
    }

    return (
        <DashboardFrame selected="dashboard" full="true">
            <AccountTypeAccess accessFor={ ["admin"] }>

                <div className="user-split-view">
                    <div className="user-split-view-left">

                        <UserPreviewBlock userData={ userData } />
                        <ObjectAttributeView object={ userData } noScroll />

                    </div>
                    <div className="user-split-view-right">
                        <h3 className="user-split-view-right-title">使用者屬性說明</h3>
                        <p className="user-split-view-right-paragraph">
                            每個使用者的基本資料都是被儲存在像這樣的表格中。左側列出目前所檢視的使用者的所有資料。若需要進行手動的資料更新，請至 console.firebase.google.com ，用 @infvolunteer.org 結尾的 Google 帳號登入後，於資料庫中進行修改。
                        </p>
                        <p className="user-split-view-right-paragraph">
                            以下列出使用者基本資料的屬性標籤，以及其可能對應的值：
                        </p>
                        <TableFrame rowHeight="auto" width="510" noSidePadding noScroll columns={ [
                            {content: "屬性標籤", width: 170}, {content: "可能值與意涵",  width: 340}] }>
                            
                            { Object.entries(userObjectAttributes).map(([userObjectAttribute, userObjectAttributeValue], index) => {
                                return <TableRow key={ "user-object-attribute-" + index } content={ [
                                    <div className="user-object-attribute-block user-object-attribute-key">
                                        { userObjectAttribute }
                                    </div>,
                                    <div className="user-object-attribute-block">
                                        { userObjectAttributeValue }
                                    </div>
                                ] } />
                            }) }

                        </TableFrame>
                    </div>
                </div>

            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function UserPreviewBlock(props){
    const userData = props.userData;
    const topLevelStates = useContext(topLevelStatesContext);

    const userAdminOptions = [
        {displayName: "true", value: "true"},
        {displayName: "false", value: "false", default: true}
    ]

    const updatedUserData = {};
    function userDataChanged(e, name){
        if(name === "admin"){
            updatedUserData[name] = e.nativeEvent.target.value === "true";
        }else{
            updatedUserData[name] = e.nativeEvent.target.value;
        }
    }

    function saveUserData(){
        updateUserByReferenceId(userData.referenceId, updatedUserData,
            topLevelStates.addNotification, () => window.location.reload());
    }

    return (
        <div className="user-preview-block">
            <h3 className="user-preview-block-title">快速預覽與編輯</h3>
            <div className="user-preview-block-content">
                <div className="user-preview-block-content-left">
                    <IconTextPair svgName="account">{ userData?.name || "無" }</IconTextPair>
                    <IconTextPair svgName="information">{ userData?.referenceId || "無" }</IconTextPair>
                    <IconTextPair svgName="phone">{ userData?.cellphone || "無" }</IconTextPair>
                    <IconTextPair svgName="email">{ userData?.userEmail || "無" }</IconTextPair>
                    <IconTextPair svgName="school">{ userData?.school || "無" }</IconTextPair>
                    <IconTextPair svgName="age">{ userData ? strings[userData.grade] : "" || "無" }</IconTextPair>
                    <IconTextPair svgName="gender">{ userData?.sex || "無" }</IconTextPair>
                </div>
                <div className="user-preview-block-content-right">
                    <TextBox valueSet={ userData } displayName="目前的活動 ID（actvid）" 
                        name="actvid" onChangeFunction={ userDataChanged } />
                    <SelectionList valueSet={ userData } displayName="管理員權限（admin）"
                        name="admin" options={ userAdminOptions } onChangeFunction={ userDataChanged } />
                    <TextBox valueSet={ userData } displayName="申請加入的活動梯次代碼（toJoin）" 
                        name="toJoin" onChangeFunction={ userDataChanged } />
                    <SubmitButton buttonText="確認更新資料" onSubmitFunction={ saveUserData } />
                </div>
            </div>
        </div>
    )
}