import { topLevelStatesContext } from "../App"
import { useContext, useEffect, useState } from "react"

export default function AccountTypeAccess(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const [ displayChildren, setDisplayChildren ] = useState(false);
    function updateDisplayChildren(){
        if(topLevelStates.hasLoaded){
            if(!displayChildren && props.accessFor.includes(topLevelStates.user.userType)){
                setDisplayChildren(true);
            }else if(!displayChildren && topLevelStates.user.admin && props.accessFor.includes("admin")){
                setDisplayChildren(true);
            }
        }
    }
    updateDisplayChildren();
    useEffect(() => {
        updateDisplayChildren();
    }, [topLevelStates.hasLoaded])

    
    if(displayChildren) return <>{ props.children }</>;
    return <></>;
}