import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { topLevelStatesContext } from "../App"
import { getUserByReferenceId } from "../lib/user";
import "../style/UserSideView.css"
import { SubmitButtonCenter } from "./Forms";
import IconTextPair from "./IconTextPair";

export default function UserSideView(props){

    const topLevelStates = useContext(topLevelStatesContext);
    const [userData, setUserData] = useState(null);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if(!!topLevelStates.selectedUser){
            if(!!userData && topLevelStates.selectedUser !== userData.referenceId){
                setUserData(null);
            }
            setDisplay(true);
            getUserByReferenceId(topLevelStates.selectedUser, (newUserData) => {
                setUserData(newUserData);
            });
        }
    }, [topLevelStates.selectedUser])

    return (
        <div className="user-side-view-frame">

            <div className={ "user-side-view-frame-left " +
                (!display ? "user-side-view-frame-hidden-left" : "") }>
                { props.children }
            </div>

            <div className={ "user-side-view-frame-right " +
                (!display ? "user-side-view-frame-hidden-right" : "") } >

                <div className="user-side-view-top">
                    <h3 className="user-side-view-title">
                        { topLevelStates.selectedUser
                            ? (userData?.referenceId ? (userData.name || "未填姓名") : "讀取中⋯")
                            : "請點擊使用者以檢視之" }
                    </h3>
                    <IconTextPair 
                        style={{ transform: 'scale(1.35) translateX(7px)',
                        cursor: 'pointer' }} svgName="close" 
                        onClick={ () => setDisplay(false) } />
                </div>


                { topLevelStates.selectedUser && userData?.referenceId
                    ? <>
                        <Link to={ "/User?referenceId=" + topLevelStates.selectedUser }>
                            <SubmitButtonCenter noSidePadding buttonText="前往使用者頁面" />
                        </Link>
                        { userData.userType === "community"
                            ? <Link to={ "/CommunityStudents?communityReferenceId=" + topLevelStates.selectedUser }>
                                <SubmitButtonCenter noSidePadding buttonText="管理該社區學生" />
                            </Link> : null
                        }
                        
                        <div className="user-side-view-original-string">
                            <h4 className="user-side-view-original-string-title">使用者資料原始碼</h4>
                            {
                                Object.entries(userData).map(([userDataKey, userDataValue], index) => {
                                    return <div key={ "user-side-view-line-" + index }
                                        className="user-side-view-original-string-line"> 
                                        { userDataKey }: { JSON.stringify(userDataValue) } 
                                    </div>
                                })
                            }
                        </div> 
                    </> : null
                }


            </div>
        </div>
    )
}