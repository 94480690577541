import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { AccessDeniedBlock } from "../components/AccessDeniedBlock";
import { AccountStatusBlock } from "../components/AccountStatusBlock";
import AccountTypeAccess from "../components/AccountTypeAccess";
import { CoinDisplay } from "../components/Coin";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { CheckBox, DateBox, SubmitButton, SubmitButtonCenter, TextAreaBox, TextBox } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { RectangularChip } from "../components/RectangularChip";
import { TableFrame, TableRow } from "../components/Table";
import TopNavTabs from "../components/TopNavTabs";
import UserSideView from "../components/UserSideView";
import makeid from "../lib/makeid";
import { addBlankTeachingRecord, getTeachingRecordsInCommunity, updateTeachingRecordsInCommunity } from "../lib/teachingRecords";
import { createStudentInCommunity, deleteStudentInCommunity, getActivitiesByCommunity, getCommunityStudentsByCommunityReferenceId, getUserByReferenceId, updateStudentInCommunity } from "../lib/user";
import "../style/CommunityStudents.css"

export default function CommunityStudents(props){
    const topLevelStates = useContext(topLevelStatesContext);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const communityReferenceId = searchParams.get("communityReferenceId");

    const [communityStudentList, setCommunityStudentList] = useState(null);

    useEffect(() => {
        if(communityStudentList === null){
            getCommunityStudentsByCommunityReferenceId(communityReferenceId, newCommunityStudentList => {
                setCommunityStudentList(newCommunityStudentList);
            });
        }
    }, [topLevelStates.hasLoaded]);

    if(topLevelStates.user?.userType === "community" && topLevelStates.user?.referenceId !== communityReferenceId){
        return (
            <DashboardFrame selected="dashboard">
                <AccessDeniedBlock />
            </DashboardFrame>
        )
    }

    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["admin", "community"] }>
                <UserSideView>
                    <TopNavTabs pageTitle="社區學生管理頁面" pageSubtitle="Infinitas 學生志工計畫"
                        tabs={ [
                            {displayName: "學生簡表",
                                content: <CommunityStudentSimpleListTab
                                    communityStudentList={ communityStudentList } />
                            },
                            {displayName: "學生詳表", 
                                content: <CommunityStudentListTab communityStudentList={ communityStudentList }
                                    communityReferenceId={ communityReferenceId } />},
                            {displayName: "新增學生", 
                                name: "addStudent",
                                content: <AddStudentTab communityStudentList={ communityStudentList }
                                    communityReferenceId={ communityReferenceId } />},
                            {displayName: "教學紀錄管理", 
                                content: <CommunityTeachingRecords communityStudentList={ communityStudentList }
                                    communityReferenceId={ communityReferenceId } />},
                            {displayName: "社區活動列表", 
                                name: "activities",
                                content: <CommunityActivitiesTab communityReferenceId={ communityReferenceId } />}
                        ] } />
                </UserSideView>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function AddStudentTab(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const naviate = useNavigate();
    const newStudentName = useRef("");
    function updateNewStudentName(e, name){
        newStudentName.current = e.nativeEvent.target.value;
    }
    function addNewStudent(){
        const newStudentReferenceId = makeid(15);
        createStudentInCommunity(props.communityReferenceId, newStudentReferenceId, newStudentName.current,
            topLevelStates.addNotification, () => naviate(0));
    }

    return (
        <>
            <DashboardBlock blockTitle="新增學生">
                <TextBox displayName="學生姓名" onChangeFunction={ updateNewStudentName } />
                <SubmitButton buttonText="確認新增" condense onSubmitFunction={ addNewStudent } />
            </DashboardBlock>
        </>
    )
}

function CommunityStudentSimpleListTab(props){

    const topLevelStates = useContext(topLevelStatesContext);
    return (
        <TableFrame width="500" rowContainerHeight="600" rowHeight="auto" columns={ [
            {content: "學生姓名", width: 150}, {content: "學生狀況", width: 333}
        ] }>
            { (props.communityStudentList || []).map((communityStudent, index) => {
                return <TableRow key={ "community-student-simple-list-row-" + index } 
                    selected={ topLevelStates.selectedUser === communityStudent.referenceId }
                    onClick={ () => topLevelStates.selectUser(communityStudent.referenceId) } content={ [
                    <div className="community-student-list-name">
                        <div className="community-student-list-name-top">
                            { communityStudent.name || "未填姓名" }
                        </div>
                        <div className="community-student-list-name-bottom">
                            { communityStudent.referenceId }
                        </div>
                    </div>,
                    <div className="community-student-list-note">
                        { communityStudent.note }
                    </div>
                ] } />
            }) }
        </TableFrame>
    )
}

function CommunityStudentListTab(props){

    return (
        <TableFrame noScroll width="2020" rowHeight="95" columns={ [
            {content: "社區 - 姓名", width: 210}, {content: "社區 - 學生狀況", width: 220},
            {content: "金幣數量", width: 100}, {content: "儲存資料", width: 130},
            {content: "個人帳號", width: 150},
            {content: "個帳 - 姓名", width: 140}, {content: "個帳 - 性別", width: 140},
            {content: "個帳 - 學校", width: 200}, {content: "個帳 - Google 帳號", width: 270 },
            {content: "個帳 - LINE", width: 140}, {content: "個人資料頁面", width: 162},
            {content: "刪除學生", width: 130}
        ] }>
            { (props.communityStudentList || []).map((communityStudent, index) => {
                const studentReferenceId = communityStudent.referenceId;
                return <CommunityStudentListRow key={ "community-student-list-row-" + index }
                    communityReferenceId={ props.communityReferenceId }
                    communityStudent={ communityStudent } studentReferenceId={ studentReferenceId } />
            }) }

        </TableFrame>
    )
}

// A cache for saving the user data that are mapped to the 
// userReferenceIds. useMemo doesn't work here because the 
// props do change, which influences the dependency array
const studentPersonalDataCache = {}; 
function CommunityStudentListRow(props){

    const topLevelStates = useContext(topLevelStatesContext);

    const [userData, setUserData] = useState(null);
    const communityStudent = props.communityStudent || {};

    useEffect(() => {
        if(!studentPersonalDataCache[props.studentReferenceId]){
            getUserByReferenceId(props.studentReferenceId, returnedUserData => {
                studentPersonalDataCache[props.studentReferenceId] = returnedUserData;
                setUserData(returnedUserData);
            });
        }else{
            setUserData(studentPersonalDataCache[props.studentReferenceId]);
        }
    }, [props.studentReferenceId]);

    const [newCommunityStudentData, setNewCommunityStudentData] = useState({})
    function updateCommunityStudentData(e, name){
        const newDataObject = Object.assign({}, newCommunityStudentData);
        newDataObject[name] = e.nativeEvent.target.value;
        setNewCommunityStudentData(newDataObject);
    }

    function saveStudentData(){
        updateStudentInCommunity(newCommunityStudentData, props.communityReferenceId, 
            props.studentReferenceId, topLevelStates.addNotification);
    }

    function deleteStudentData(){
        if(window.confirm("是否確認刪除此學生的社區資料？若該學生有連結的帳號，他將依然能登如此平台，但將不會再是此社區的成員。刪除後將無法復原。")){
            deleteStudentInCommunity(props.communityReferenceId, props.studentReferenceId,
                topLevelStates.addNotification, () => window.location.reload());
        }
    }

    return (
        <TableRow onClick={ () => topLevelStates.selectUser(props.studentReferenceId) } 
            selected={ topLevelStates.selectedUser === props.studentReferenceId } content={ [

            <div className="community-student-list-name">
                <div className="community-student-list-name-top">
                    <TextBox name="name" onChangeFunction={ updateCommunityStudentData }
                        valueSet={{ name: communityStudent.name }} />
                </div>
                <div className="community-student-list-name-bottom">
                    { communityStudent.referenceId }
                </div>
            </div>,

            <div>
                <TextBox displayName="學生狀況" name="note" 
                    onChangeFunction={ updateCommunityStudentData }
                    valueSet={{ note: communityStudent.note }} />
            </div>,

            <div>
                <CoinDisplay fontSize="24" coins={ communityStudent.coins || 0 } />
            </div>,

            <SubmitButtonCenter condense buttonText="確認儲存"
                onSubmitFunction={ saveStudentData } />,

            <div className="community-student-list-standard-block">
                <AccountStatusBlock studentPersonalData={ userData || {} } 
                    showLink studentReferenceId={ communityStudent.studentReferenceId } 
                    communityReferenceId={ props.communityReferenceId } />
            </div>,
            
            <div className="community-student-list-standard-block">{ userData?.referenceId ? userData.name : "無關聯帳號" }</div>,
            <div className="community-student-list-standard-block">{ userData?.sex }</div>,
            <div className="community-student-list-standard-block">{ userData?.school }</div>,
            <div className="community-student-list-standard-block">{ userData?.userEmail }</div>,
            <div className="community-student-list-standard-block">{ userData?.lineId }</div>,
            <div></div>,
            <div>
                <SubmitButtonCenter condense buttonText="永久刪除" warning
                    onSubmitFunction={ deleteStudentData } />
            </div>
        ] } />
    )
}

function CommunityTeachingRecords(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const [showAll, setShowAll] = useState(false);

    return (
        <>
            <div className="community-teaching-records-top">
                <CheckBox default={ showAll } 
                    onChangeFunction={ setShowAll }
                    buttonText="同時顯示所有學生紀錄（可能影響電腦效能）" />
            </div>

            <TableFrame noScroll width="1250" rowHeight="670" columns={ [
                {content: "學生名稱", width: 140}, {content: "教學紀錄", width: 1110}
            ] }>

                {(props.communityStudentList || []).map((communityStudent, index) => {
                    return <TableRow onClick={ () => topLevelStates.selectUser(communityStudent.referenceId) } 
                        selected={ topLevelStates.selectedUser === communityStudent.referenceId }
                        key={ "community-student-list" + index } content={ [
            
                        <div className="community-student-list-name">
                            <div className="community-student-list-name-top">
                                { communityStudent.name }
                            </div>
                            <div className="community-student-list-name-bottom">
                                { communityStudent.referenceId }
                            </div>
                        </div>,

                        topLevelStates.selectedUser === communityStudent.referenceId || showAll
                        ? <CommunityTeachingRecordsBlock showAll={ true }
                            selected={ topLevelStates.selectedUser === communityStudent.referenceId }
                            communityReferenceId={ props.communityReferenceId }
                            studentReferenceId={ communityStudent.referenceId }
                            studentRecords={ communityStudent.records } />
                        : <></>
                        
                    ] } />
                })}
            </TableFrame>
        </>
    )
}

function CommunityTeachingRecordsBlock(props){
    const topLevelStates = useContext(topLevelStatesContext);

    // By default, it loads the teaching records from its props, 
    // which is fetched from CommunityStudents's useEffect.
    // However, when the records are updated (save button clicked),
    // the records of *only this student* are re-fetched.
    const refetched = useRef(0);
    const [newlyFetchedRecords, setNewlyFetchedRecords] = useState([]);
    const studentRecords = useMemo(() => {
        return refetched.current ? newlyFetchedRecords : (props.studentRecords || []);
    }, [refetched.current]);
    

    function addRecord(){
        addBlankTeachingRecord(props.communityReferenceId, props.studentReferenceId,
            topLevelStates.addNotification, refetchRecords);
    }
    function refetchRecords(){
        getTeachingRecordsInCommunity(props.communityReferenceId, props.studentReferenceId, 
            (newTeachingRecords) => {
                refetched.current++
                setNewlyFetchedRecords(newTeachingRecords);
            })
    }

    // Clone an array of teaching records for saving into the db
    const changedTeachingRecords = useRef(studentRecords);
    useEffect(() => {
        changedTeachingRecords.current = studentRecords;
    }, [props.studentRecords, refetched.current]);

    console.log(changedTeachingRecords.current);
    function teachingRecordChanged(index, field, value){
        const newChangedTeachingRecords = JSON.parse(JSON.stringify(changedTeachingRecords.current));
        newChangedTeachingRecords[index][field] = value;
        changedTeachingRecords.current = newChangedTeachingRecords;
    }
    function saveTeachingRecord(index){
        updateTeachingRecordsInCommunity(props.communityReferenceId, props.studentReferenceId, 
            studentRecords[index], changedTeachingRecords.current[index], topLevelStates.addNotification, refetchRecords);
    }
    
    // To improve the performance, only when the showAll checkbox
    // is selected, the records of every student are shown at once
    if(!props.selected && !props.showAll) return null;

    return (
        <div className="community-students-record-block-container">
            <div className="community-students-record-block-wrap"
                style={{ width: (studentRecords.length + 1) * 315 + 20 + "px" }}>

                <div className="community-students-record-block
                    community-students-record-block-add" onClick={ addRecord }>
                    <div className="community-studnets-record-block-add-content">
                        <IconTextPair svgName="add">新增教學紀錄&emsp;</IconTextPair>
                    </div>
                </div>
                { studentRecords.sort((a, b) => (a.date < b.date ? 1 : -1)).map((studentRecord, index) => {
                    return <div className="community-students-record-block"
                        key={ "community-students-record-block-" + studentRecord.recordReferenceId }>
                        <div className="community-students-record-block-content">
                            <DateBox valueSet={ studentRecord } name="date"
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } } />
                            <TextBox name="classLength" 
                                displayName="上課時長"
                                valueSet={ studentRecord }
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } } />
                            <TextBox name="prepLength" 
                                displayName="備課時長"
                                valueSet={ studentRecord }
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } } />
                            <TextBox name="volunteerReferenceId" 
                                displayName="志工 ID"
                                valueSet={ studentRecord }
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } } />
                            <TextBox name="volunteerName" 
                                displayName="志工姓名"
                                valueSet={ studentRecord }
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } } />
                            <TextAreaBox name="teachingRecord" height="39"
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } }
                                valueSet={ studentRecord } />
                            <TextAreaBox name="lifeRecord" height="39"
                                onChangeFunction={ (e, name) => {
                                    teachingRecordChanged(index, name, e.nativeEvent.target.value);
                                } }
                                valueSet={ studentRecord } />
                            <SubmitButton center condense onSubmitFunction={ () => saveTeachingRecord(index) }
                                buttonText={ <>&emsp;儲存資料&emsp;</> } />
                        </div>
                    </div>
                }) }
            </div>
        </div>
    )
}

function CommunityActivitiesTab(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [communityActivities, setCommunityActivities] = useState(null);
    if(communityActivities === null){
        getActivitiesByCommunity(props.communityReferenceId, setCommunityActivities);
    }

    const [displayAll, setDisplayAll] = useState(true);

    return (
        <>
            <div className="community-teaching-records-top">
                <CheckBox default={ displayAll } onChangeFunction={ setDisplayAll }
                    buttonText="顯示所有梯次（包含規劃中與已結束的梯次）" />
            </div>
            <div className="community-activities-container">
                { (communityActivities || []).map((activityData, index) => {
                    if(activityData.activated !== "activated" && !displayAll) return null;
                    return <CommunityActivityBlock activityData={ activityData }  
                        key={ "community-activity-block-" + index } />
                }) }
            </div>
        </>
    )
}

function CommunityActivityBlock(props){
    const activityData = props.activityData || {};

    const activatedStatesMap = {
        activated: {
            color: "#00ba00",
            displayName: "活動進行中"
        },
        planning: {
            color: "#c5b011",
            displayName: "計劃階段"
        },
        ended: {
            color: "#bd4426",
            displayName: "活動已結束"
        }
    }

    return (
        <div className={ "community-students-activity-block " + 
            "community-students-activity-block-" + (activityData.activated || "planning")}>

            <div className="community-students-activity-block-top">
                <h4 className="community-students-activity-block-title">
                    { activityData.name }
                </h4>
                <p className="community-students-activity-block-description">
                    { activityData.description }
                </p>
                <RectangularChip fontSize="15" style={{ marginBottom: 7 }}
                    color={ activatedStatesMap[activityData.activated || "planning"].color }>{ activatedStatesMap[activityData.activated || "planning"].displayName }
                </RectangularChip>
                <Link to={ "/ActivityStudents?actvid=" + activityData.actvid }>
                    <IconTextPair fontSize="16" wrap svgName="launch">
                        學生頁面
                    </IconTextPair>            
                </Link>
                <br />
                <IconTextPair fontSize="16" svgName="time">
                    每週 { activityData.classPerWeek } 堂課，每堂課 { activityData.hourPerClass } 小時
                </IconTextPair>
                <IconTextPair fontSize="16" wrap svgName="age">
                    { activityData.studentAgeDescription }
                </IconTextPair>
                <IconTextPair fontSize="16" wrap svgName="information">
                    { activityData.workingDescription }
                </IconTextPair>
            </div>


            <div className="community-students-activity-block-bottom">
                { activityData.startTime } ～ { activityData.endTime }
            </div>
            
        </div>
    )
}