import { useState } from "react";
import { daysInMonth, getDayOfFirstDate, getThisMonthInt, getTodayDateString } from "../lib/dates";
import "../style/CalendarView.css";
import IconTextPair from "./IconTextPair";
import { RectangularChip } from "./RectangularChip";

export default function CalendarView(props){

    const clickable = !!props.clickable;
    const triggerFunction = props.triggerFunction || console.log;
    const rowHeight = props.rowHeight || 53;

    // Within this component, dates are formated as HTML ISO string:
    // YYYY-MM-DD, and in the variables/ lists, Jan -> 1, Feb -> 2, etc.,
    // and days Sunday with Sun -> 0, Mon -> 1, Tue -> 2, etc.

    const selectedDates = props.selectedDates || [];
    const dayStrings = ["日", "一", "二", "三", "四", "五", "六"];

    // get an array of integers YYYYMM without duplicates
    const yearMonthSet = new Set();
    selectedDates.forEach(selectedDate=>{
        if (selectedDate) yearMonthSet.add (parseInt(selectedDate.split("-").slice(0, 2).join("")))
    })
    let yearMonth = [...yearMonthSet];
    // let yearMonth = [...new Set(selectedDates.map(selectedDate =>{ 
    //     console.log (selectedDate)
    //     return parseInt(selectedDate.split("-").slice(0, 2).join(""))
    // }))];
    if(!selectedDates.length){
        const tempDate = new Date();
        yearMonth = [tempDate.getFullYear() * 100 + tempDate.getMonth() + 1];
    }

    const startMonth = Math.min(...yearMonth);
    const endMonth = Math.max(...yearMonth);

    const thisMonthInt = getThisMonthInt();
    const initialViewingMonth = thisMonthInt >= startMonth && thisMonthInt <= endMonth
        ? thisMonthInt : startMonth;
    const [currentMonth, setCurrentMonth] = useState(initialViewingMonth);
    const currentMonthIndex = currentMonth % 100;
    const currentYearIndex = Math.floor(currentMonth / 100);
    function moveMonth(move){
        if(currentMonth % 100 === 12 && move > 0) move += 88;
        else if(currentMonth % 100 === 1 && move < 0) move -= 88;
        if(currentMonth + move > endMonth || currentMonth + move < startMonth) return null;
        setCurrentMonth(currentMonth + move);
    }

    const calendarBlocks = [];
    for(let i = 0; i < getDayOfFirstDate(currentYearIndex, currentMonthIndex); i++){
        calendarBlocks.push(<CalendarBlock miniview={ props.miniview }
            key={ "calendar-block-blank-" + currentMonth + i } />);
    }
    for(let i = 1; i <= daysInMonth(currentYearIndex, currentMonthIndex); i++){
        const monthString = currentMonthIndex >= 10 ? currentMonthIndex.toString() : "0" + currentMonthIndex;
        const dateString = i >= 10 ? i.toString() : "0" + i;
        calendarBlocks.push(
            <CalendarBlock key={ "calendar-block-" + currentMonth + i } date={ i }
                clickable={ clickable } onClick={ triggerFunction } miniview={ props.miniview }
                viewing={ `${currentYearIndex}-${monthString}-${dateString}` === props.viewingDate }
                yearString={ currentYearIndex.toString() } monthString={ monthString }
                selected={ selectedDates.includes(`${currentYearIndex}-${monthString}-${dateString}`) } />);
    }

    const reduceLastRow = getDayOfFirstDate(currentYearIndex, currentMonthIndex) + daysInMonth(currentYearIndex, currentMonthIndex) <= 35;
    const style = Object.assign({}, props.style || {});
    if(props.noBorder) style.border = "none";

    return (
        <div className="calendar-view" style={ style }>
            <div className="calendar-view-top">
                <div className="calendar-view-top-label">
                    { !props.selectedDates?.length
                        ? <RectangularChip 
                            color={ props.noChip ? "ffffff" : "#dd3333" } 
                            style={{ margin: '7px 0 0 7px' }}>
                            未選取時間
                        </RectangularChip> : null
                    }
                </div>
                <div className={ "calendar-view-top-content " + (props.noChip || 
                    props.selectedDates.length ? "calendar-view-top-content-nochip" : "" ) }>
                    <div className="calendar-view-top-content-button"
                        onClick={ () => moveMonth(-1) }>
                        <IconTextPair svgName="arrow_left_uni"></IconTextPair>
                    </div>
                    <div className="calendar-view-top-content-text">
                        { currentYearIndex } 年 { currentMonthIndex } 月
                    </div>
                    <div className="calendar-view-top-content-button"
                        onClick={ () => moveMonth(1) }>
                        <IconTextPair svgName="arrow_right_uni"></IconTextPair>
                    </div>
                </div>
            </div>
            <div className="calendar-view-content">
                <div className="calendar-view-content-first-row">
                    { dayStrings.map((dayString, index) => {
                        return <div className="calendar-view-content-first-row-block"
                            key={ "calendar-view-content-first-row-block-" + index }>
                            { dayString }
                        </div>
                    }) }
                </div>
                <div className="calendar-view-content-grid"
                    style={ reduceLastRow 
                        ? { gridTemplateRows: `repeat(5, ${rowHeight}px)` } 
                        : { gridTemplateRows: `repeat(6, ${rowHeight}px)` } }>
                    { calendarBlocks }
                </div>
            </div>
        </div>
    )

}

function CalendarBlock(props){
    let dateNumString = props.date?.toString();
    if(dateNumString?.length === 1) dateNumString = "0" + dateNumString;
    return <div className={ "calendar-block " + 
        (props.selected ? "calendar-block-selected " : "") +
        (props.clickable ? "calendar-block-clickable " : "") +
        (props.viewing ? "calendar-block-viewing " : "") }>
        <div className={ "calendar-block-text " + (props.miniview ? "calendar-block-text-mini " : "") }
            onClick={ props.clickable ? () => props.onClick({
                date: props.yearString + "-" + props.monthString + "-" + dateNumString,
                action: props.selected ? "remove" : "add"
            }) : null }>
            { props.date }
        </div>
    </div>
}