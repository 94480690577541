import "../style/ParentRegister.css";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DashboardBlock from "../components/DashboardBlock";
import ExploreBlock from "../components/ExploreBlock";
import { getUser } from "../lib/user";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "@firebase/auth";
import { doc, getFirestore, setDoc } from "@firebase/firestore";
import { GoogleSignInButton } from "../components/SignInButton";
import { topLevelStatesContext } from "../App";
import { SubmitButton, TextBox } from "../components/Forms";
import AccountTypeAccess from "../components/AccountTypeAccess";

export default function ParentRegister(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const studentReferenceId = searchParams.get("studentReferenceId");
    const actvid = searchParams.get("actvid");
    const googleAuthProvider = new GoogleAuthProvider();
    const auth = getAuth();

    function googleSignIn(){
        signInWithPopup(auth, googleAuthProvider)
            .then((result) => {
                getUser((userData) => {
                    if(userData !== false && userData.referenceId){
                        topLevelStates.addNotification("此帳號已經註冊過，請換一個新的 Google 帳號嘗試。")
                    }else{
                        const user = result.user;
                        const db = getFirestore();
                        const userRef = doc(db, "users", user.uid);
                        setDoc(userRef, {
                            'userDisplayName': user.displayName,
                            'userEmail': user.email,
                            'referenceId': studentReferenceId,
                            'userTypeRecorded': true,
                            'userType': "student",
                            'actvid': actvid || "",
                            'activities': actvid ? [actvid] : []
                        }, {
                            merge: true
                        })
                            .then(() => {
                                window.location.href = "./Signup?bypass=true&userType=student";
                            })
                    }
                }, result.user.uid)
            })
    }

    let accountRegistered = false;
    if(topLevelStates.hasLoaded && topLevelStates.user.referenceId === studentReferenceId){
        accountRegistered = true;
    }

    const accountRegistrationBlock = <DashboardBlock blockTitle={ (accountRegistered ? "✅ " : "") + "第 1 步：開通帳號"} 
        blockSubtitle="請使用 Google 帳號登入">
        { accountRegistered
            ? "您已完成此步驟"
            : <>
                <br />
                <GoogleSignInButton 
                signInFunction={ googleSignIn } /> 
            </>
        }
    </DashboardBlock>

    return (
        <div className="parent-register-container">

            { !accountRegistered ?
                accountRegistrationBlock
            : <></>}

        </div>
    )
}