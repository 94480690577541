import { useContext, useEffect, useRef, useState } from "react";
import { Popup } from "./Popup";
import "../style/Chat.css";
import { addMessageToConversation, createConversationAndConnectToTeachingRecord, getConversation } from "../lib/chat";
import { topLevelStatesContext } from "../App";

export function CommentSection(props){

    const [showingConversation, setShowingConversation] = useState(false);
    const [conversationId, setConversationId] = useState(props.conversationId);
    
    return ( showingConversation
        ? <ChatRoom chatRoomHeight="500" conversationId={ conversationId }
            communityReferenceId={ props.communityReferenceId }
            originalTeachingRecord={ props.originalTeachingRecord }
            studentReferenceId={ props.studentReferenceId }
            setConversationId={ setConversationId }
            style={{ border: '1px solid #ccc', borderRadius: 'var(--border-radius)' }} />
        : <div className="comment-section-intro">
            <div className="comment-section-intro-button"
                onClick={ () => setShowingConversation(true) }>
                { !conversationId ? "🟢 點此以新增留言" : "🟡 點此檢視對話內容" }
            </div>
        </div> 
    )
}

export function ChatRoom(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const icoDir = require.context("../ico/");
    const chatUrl = icoDir("./chat-fill.svg");

    const conversationId = props.conversationId;
    const communityReferenceId = props.communityReferenceId;
    const originalTeachingRecord = props.originalTeachingRecord;
    const studentReferenceId = props.studentReferenceId;

    const messageEndRef = useRef();
    const [conversation, setConversation] = useState(null);
    useEffect(() => {
        if(conversation === null){
            getConversation(communityReferenceId, conversationId, setConversation);
        }
    }, [conversationId]);
    useEffect(() => {
        scrollToBottom();
    }, [conversation])

    function appendMessage(message){
        const newConversation = [...(conversation || []), message];
        setConversation(newConversation);
    }
    function scrollToBottom(){
        messageEndRef.current && messageEndRef.current.scrollIntoView();
    }

    const inputRef = useRef();
    function sendMessage(){
        const message = inputRef.current.value;
        if(!message) return null;
        inputRef.current.value = "";
        
        if(!conversationId){
            createConversationAndConnectToTeachingRecord(communityReferenceId, 
                studentReferenceId, message, originalTeachingRecord, 
                topLevelStates.user.referenceId, topLevelStates.user.name || 
                topLevelStates.user.userDisplayName, props.setConversationId);
        }else{
            addMessageToConversation(communityReferenceId, conversationId,
                message, topLevelStates.user.referenceId, topLevelStates.user.name ||
                topLevelStates.user.userDisplayName, appendMessage);
        }
    }

    const style = Object.assign({}, props.style || {});

    return (
        <div className="chat-room" style={ style }>
            { !conversationId
                ? <div className={ "chat-room-top chat-room-top-blank" } 
                    style={{ backgroundImage: `url(${chatUrl})` }}>
                    <div className="chat-room-top-text">
                        輸入文字便可開始對話
                    </div>
                </div>
            : 
                <div className="chat-room-top" style={ props.chatRoomHeight ?
                    {maxHeight: props.chatRoomHeight + "px"} : null} >
                    { (conversation || []).map((message, index) => {
                        return <Message message={ message }
                            key={ "conversation-message-" + index } />
                    }) }
                    <div ref={ messageEndRef }></div>
                </div>
            }
            <div className="chat-room-bottom">
                <input ref={ inputRef } placeholder="輸入文字"
                    className="chat-room-bottom-input" />
                <div className="chat-room-bottom-send"
                    onClick={ sendMessage }>
                    傳送
                </div>
            </div>
        </div>
    )
}

export function Message(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const { senderName, senderReferenceId, content, timestamp } = props.message;
    const iSentIt = senderReferenceId === topLevelStates.user.referenceId;
    return (
        <>
            <div className={ "message-sender-name " + 
                (iSentIt ? "message-sender-name-me " : "" )}>{ senderName }</div>
            
            <div className={ "message-container " + 
                (iSentIt ? "message-container-me " : "" ) }>
                <div className="message-bubble">{ content }</div>
            </div>
        </>
    )
}