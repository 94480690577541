import { useRef, useState } from "react";
import "../style/Coin.css";

export function CoinAdd(props){

    const [coins, setCoins] = useState(props.coins || 0);
    const initialCoins = useRef(props.coins || 0);

    const textbox = useRef();
    function updateCoinValue(direction){
        if(direction * parseInt(textbox.current.value) + coins < 0){
            setCoins(0);
            return null;
        }
        setCoins(direction * parseInt(textbox.current.value) + coins);
    }

    function getCoinChange(){
        return coins - initialCoins.current;
    }

    return (
        <div className="coin-add-container">
            <CoinDisplay coins={ coins } />
            <div className="coin-add-textbox-container">
                <div className="coin-add-textbox-button"
                    onClick={ () => updateCoinValue(-1) }>−</div>
                <input className="coin-add-textbox" type="text"
                    defaultValue={ 0 } ref={ textbox } />
                <div className="coin-add-textbox-button" 
                    onClick={ () => updateCoinValue(1) }>+</div>
            </div>
            <div className="coin-add-button"
                onClick={ () => props.updateCoinFunction && props.updateCoinFunction(getCoinChange()) }>
                更新資料庫
            </div>
        </div>
    )
}

export function CoinDisplay(props){
    const coins = props.coins || 0;
    const icoDir = require.context("../ico/");
    const coinUrl = icoDir("./coins.svg");
    const style = Object.assign({}, props.style);
    if (props.fontSize) style["fontSize"] = props.fontSize + "px";
    style["backgroundImage"] = `url(${coinUrl})`;
    return (
        <div className="coin-container"
            style={ style }>
            { coins }
        </div>
    )
}