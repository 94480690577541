import "../style/IconTextPair.css"

export default function IconTextPair(props){
    const icoDir = require.context("../ico/");
    return (
        <div className={ "icon-text-pair " + 
            (props.wrap ? "icon-text-pair-wrap" : "")} 
            style={ props.style } onClick={ props.onClick }>
            <div 
                className="icon-text-pair-icon"
                style={{ backgroundImage: `url(${icoDir("./" + props.svgName + ".svg")})` }}>
            </div>
            <div className={ "icon-text-pair-text " + 
                (props.wrap ? "icon-text-pair-text-wrap " : " ") + 
                (props.smallText ? "icon-text-pair-text-small " : " ")}
                style={ (props.fontSize ? {fontSize: props.fontSize + "px"} : {}) }>
                { props.children }
            </div>
        </div>
    )
}
