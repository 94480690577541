import { strings } from "../lib/strings";
import "../style/SubjectBlock.css";

export default function SubjectBlock(props){
    const subject = props.subject;
    const subjectDisplayName = strings[subject];

    const icoDir = require.context("../ico/subjects");
    return (
        <div className={ "subjects-selection-block subjects-selection-block-thin " + 
            (props.selected ? "subjects-selection-block-selected" : "")}
            onClick={ () => props.onClick && props.onClick(subject) }>

            <div className="subjects-selection-block-left" style={{
                backgroundImage: `url(${icoDir("./" + subject + ".svg")})`
            }}></div>
            <div className="subjects-selection-block-right">
                { subjectDisplayName }
            </div>
        </div>
    )
}

export function SubjectChip(props){
    const subject = props.subject;
    const subjectDisplayName = strings[subject + "Pure"];

    const icoDir = require.context("../ico/subjects");
    return (
        <div className="subject-chip" style={ props.style }>
            <div className="subject-chip-left" style={{
                backgroundImage: `url(${icoDir("./" + subject + ".svg")})`
            }} />
            <div className="subject-chip-right">{ subjectDisplayName }</div>
        </div>
    )
}