import { useContext } from "react";
import { topLevelStatesContext } from "../App";
import "../style/ClassLink.css";
import IconTextPair from "./IconTextPair";

export default function ClassLink(props){
    const topLevelStates = useContext(topLevelStatesContext);
    let classLink = props.classLink;

    if(classLink && !classLink.startsWith("https://")){
        classLink = "https://" + classLink;
    }
    const meetCode = classLink?.split("/")[classLink.split("/").length - 1];

    function copyClassLink(e){
        e.stopPropagation();
        navigator.clipboard.writeText(classLink);
        topLevelStates.addNotification("已複製連結至剪貼簿");
    }

    if(!classLink) return "未填上課連結";

    return (
        <div className="class-link-element">
            { !props.reduced
                ? <>
                    <div className="class-link-element-top">{ classLink }</div>
                    { props.showCode
                        ? <div className="class-link-element-top">代碼：{ meetCode }&emsp;</div>
                        : null
                    }
                </>
                : null
            }
            <div className="class-link-element-bottom">
                <a href={ classLink } target="_blank" onClick={ e => e.stopPropagation() }>
                    <div className="class-link-elemet-bottom-button">
                        <IconTextPair svgName="launch" fontSize="16">前往</IconTextPair>
                    </div>
                </a>
                <div className="class-link-elemet-bottom-button class-link-elemet-bottom-button-copy"
                    onClick={ (e) => {e.stopPropagation(); copyClassLink(e)} }>
                    <IconTextPair svgName="copy" fontSize="16">複製</IconTextPair>
                </div>
            </div>
        </div>
    )
}