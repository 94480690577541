import { useCallback, useContext, useEffect, useRef, useState } from "react";
/*import { refetchData, volunteerClass, activity, saveData, setSaveData } from '../pages/Volunteer';*/
import { Link, useSearchParams } from "react-router-dom";
import CalendarTimeView from "../components/CalendarTimeView";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { CheckBox, /*DatesSelection,*/ DaysSelection, SelectionList, SubjectsSelection, SubmitButton } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import MinorPaneView from "../components/MinorPaneView";
import SubjectBlock, { SubjectChip } from "../components/SubjectBlock";
import TopNavTabs from "../components/TopNavTabs";
import ClassTimeDays from "../components/ClassTimeDays";
import { strings } from "../lib/strings";
import { getActivityPublicInfo, getClassesInActivity, getStudentsFromCommunity, getUserByReferenceId, getVolunteersByActivities, updateClassInActivityByClassId } from "../lib/user";
import "../style/SelectClass.css";
import { Popup, PopupSection } from "../components/Popup";
import ProgressBar from "../components/ProgressBar";
import { getFirestore, collection, query, getDocs, where, onSnapshot, arrayUnion } from "@firebase/firestore";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import { compareWithToday, containsToday, dateIsOnDays, hasPassedDate } from "../lib/dates";
export default function SelectClass(props){
    const topLevelStates = useContext(topLevelStatesContext);
    /*const [volunteerClasses, setVolunteerClasses] = useState(null);*/
    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");
    /*const [saveData, setSaveData] = useState([]);
    const [activity, setActivity] = useState([]);*/
    const [activityInfo, setActivityInfo] = useState(null);
    const [allClasses, setAllClasses] = useState([]);
    const [studentCommunityData, setStudentCommunityData] = useState(null);
    useEffect(() => {
        if(activityInfo === null && topLevelStates.hasLoaded){
            getActivityPublicInfo(actvid, (returnedData) => {
                setActivityInfo(returnedData);
                getClassesInActivity(actvid, returnedList => {
                    setAllClasses(returnedList.filter(classObject => classObject.volunteerReferenceId));
                });
                getStudentsFromCommunity(returnedData.communityReferenceId, [topLevelStates.user.referenceId], 
                    null, returnedStudents => {
                        if(returnedStudents.length) setStudentCommunityData(returnedStudents[0]);
                    });
            });
        }
    }, [topLevelStates.hasLoaded]);
    
    // Listen to real-time changes in the database
    // A reference to this function can be found in 
    // user.js, named getClassesInActivity, used above.
    useEffect(() => {
        if(!!actvid){
            const db = getFirestore();
            const activitiesCollectionRef = collection(db, "activities");
            const activityQuery = query(activitiesCollectionRef, where("actvid", "==", actvid));
            getDocs(activityQuery)
                .then(activityDocs => {
                    let activityDocId = "";
                    activityDocs.forEach(activityDoc => {
                        activityDocId = activityDoc.id;
                    });

                    const classesQuery = query(collection(db, `activities/${activityDocId}/classes`));
                    const unsubscribe = onSnapshot(classesQuery, (querySnapshot) => {
                        const newAllClasses = [];
                        querySnapshot.forEach((classDoc) => {
                            newAllClasses.push(classDoc.data());
                        });
                        topLevelStates.addNotification("可選課堂的資訊異動已經更新。")
                        setAllClasses(newAllClasses.filter(classObject => classObject.volunteerReferenceId));
                    });
                    return () => unsubscribe();
                });
        }
    }, []);
    
    const [volunteerDataMap, setVolunteerDataMap] = useState(null);
    useEffect(() => {
        if(volunteerDataMap === null){
            getVolunteersByActivities(actvid, volunteerList => {
                const newVolunteerDataMap = {};
                volunteerList.forEach(volunteer => {
                    newVolunteerDataMap[volunteer.referenceId] = volunteer;
                });
                setVolunteerDataMap(newVolunteerDataMap);
            });
        }
    }, [allClasses.length]);


    const tabs = [
        {displayName: "選課說明", content: 
            <ExplanationTab activityInfo={ activityInfo || {} } />},
        {displayName: "開始選課", content: 
            <SelectionTab activityInfo={ activityInfo || {} } allClasses={ allClasses }
                volunteerDataMap={ volunteerDataMap } actvid={ actvid } 
                taughtBy={ studentCommunityData?.taughtBy || [] } />, name: "select"},
        {displayName: "已選擇的課堂", content: 
            <SelectedTab allClasses={ allClasses } volunteerDataMap={ volunteerDataMap } />}
    ]
    

    return (
        <DashboardFrame full selected="dashboard">
            <AccountTypeAccess accessFor={ ["admin","student", "community"] }>
                <TopNavTabs pageTitle={ "學生家長選課頁面" } tabs={ tabs }
                    pageSubtitle={ activityInfo?.name || "Infinitas 學生志工計畫" } >

                </TopNavTabs>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function SelectedTab({allClasses, volunteerDataMap}){
    const topLevelStates = useContext(topLevelStatesContext);
    const myClasses = allClasses.filter(thisClass => (thisClass.students || []).includes(topLevelStates.user.referenceId));

    console.log(myClasses);
    // {classObject, volunteerData, actvid, selected, wasTaughtBy, canSelectAny}

    return (
        <>
            { !myClasses.length
                ? <DashboardBlock blockTitle="🔴 您尚未選擇任何課堂">
                    您可以點擊「開始選課」的頁籤前往選課，或者點擊「選課說明」查看本梯次選課的規則與介面操作方法。
                </DashboardBlock>
            : null }

            <div className="select-class-classes-container">
                { myClasses.map((classObject, index) => {
                    return <ThisClassBlockV2 key={ `selected-tab-${classObject.classId}` } classObject={ classObject }
                        volunteerData={ volunteerDataMap[classObject.volunteerReferenceId] } alreadySelected />
                }) }
            </div>
        </>
    )
}

function SelectionTab({allClasses, activityInfo, actvid, taughtBy, volunteerDataMap}){
    const topLevelStates = useContext(topLevelStatesContext);

    const canSelect = hasPassedDate(activityInfo.parentTaughtBySelectionStart);
    const selectionMode = !canSelect
        ? "none"
        : "all";

    const canSelectAny = canSelect && !containsToday(activityInfo.parentTaughtBySelectionStart, activityInfo.parentAllSelectionStart);

    const subjectOptions = [
        {displayName: "無", value: "default", default: true}
    ];
    (activityInfo.subjects || []).forEach(subject => {
        if(subject !== "assistance") subjectOptions.push({
            displayName: strings[subject + "Pure"],
            value: subject
        })
    });

    const sexOptions = [
        {displayName: "不限", value: "default", default: true},
        {displayName: "男", value: "male"},
        {displayName: "女", value: "female"}
    ];

    const teacherOptions = [
        {displayName: "只顯示教過我的老師", value: "taughtBy"}
    ];
    if(selectionMode === "all" || topLevelStates.user.userType === "community") teacherOptions.push(
        {displayName: "顯示所有老師", value: "all", default: true},
    );

    const viewAllOptions = [
        {displayName: "只顯示我可以選的課", value: false, default: true},
        {displayName: "顯示所有課堂", value: true},
    ]

    const myClasses = (allClasses || []).filter(thisClass => 
        Array.isArray(thisClass.students) && 
        thisClass.students.includes(topLevelStates.user.referenceId));

    const [filters, setFilters] = useState({
        subject: null, sex: null, teacher: selectionMode, viewAll: false, days: [1, 2, 3, 4, 5, 6, 7]
    });
    function updateFilter(e, name){
        const newFilters = Object.assign({}, filters);
        if(name === "days") newFilters[name] = e;
        else newFilters[name] = e.nativeEvent.target.value;
        setFilters(newFilters);
    }
    useEffect(() => {
        updateFilter({nativeEvent: {target: {value: selectionMode}}}, "teacher");
    }, [selectionMode]);
    const filterLookup = {
        'subject': (subject, thisClass) => {
            if(subject === null || subject === "default") return true;
            else return (thisClass.subjects || []).includes(subject);
        },
        'sex': (sex, thisClass) => {
            if(sex === null || sex === "default") return true;
            else return volunteerDataMap[thisClass.volunteerReferenceId].sex?.includes(sex === "male" ? "男" : (sex === "female" ? "女" : ""))
        },
        'teacher': (teacher, thisClass) => {
            if(teacher === "all" || teacher === null) return true;
            if(teacher === "taughtBy"){
                return taughtBy.includes(thisClass.volunteerReferenceId);
            } 
            return true;
        },
        'viewAll': (viewAllArg, thisClass) => {
            if(viewAllArg === "none") return false;
            const viewAll = (viewAllArg === true || viewAllArg === "true") ? true : false;
            if(viewAll) return true;
            if((thisClass.students || []).length === (thisClass.volunteerHandle || 1)) return false;
            if((thisClass.students || []).includes(topLevelStates.user.referenceId)) return false;
            return true;
        },
        'days': (days, thisClass) => {
            for(let i = 0; i < (thisClass.classTime || []).length; i++){
                if(!dateIsOnDays(thisClass.classTime[i].date, days)) return false;
            }
            return true;
        }
    }

    const displayClasses = allClasses
        .filter(thisClass => thisClass.classTime?.length)
        .filter(thisClass => thisClass.classTime.length <= (activityInfo.volunteerClassTimeLimit || 10000))
        .filter(thisClass => filterLookup.subject(filters.subject, thisClass))
        .filter(thisClass => (thisClass.students || []).length < (thisClass.volunteerHandle || 1) || (thisClass.students || []).includes(topLevelStates.user.referenceId))
        // .filter(thisClass => filterLookup.sex(filters.sex, thisClass))
        // .filter(thisClass => filterLookup.teacher(filters.teacher, thisClass))
        // .filter(thisClass => filterLookup.viewAll(filters.viewAll, thisClass))
        .filter(thisClass => filterLookup.days(filters.days, thisClass));

    if(selectionMode === "none")
        return (
            <DashboardBlock blockTitle="🔴 本梯次尚未開放選擇！">
                <IconTextPair svgName="time" wrap>{ activityInfo.parentTaughtBySelectionStart } 始，開放各學生家長選擇以往教過該學生的志工老師提供的課程。</IconTextPair>
                <IconTextPair svgName="time" wrap>{ activityInfo.parentAllSelectionStart } 始，開放各學生家長選擇所有課程。</IconTextPair>
            </DashboardBlock>
        )
    return (
        <>
            <MinorPaneView side={ 
                <FilterBlock myClasses={ myClasses } activityInfo={ activityInfo } updateFilter={ updateFilter }
                    subjectOptions={ subjectOptions } sexOptions={ sexOptions } teacherOptions={ teacherOptions } viewAllOptions={ viewAllOptions } />
            }>

                { displayClasses.length && !canSelectAny
                    ? <DashboardBlock blockTitle="💜 只可選擇教過我的老師">
                            目前僅開放您選擇之前教過您孩童的志工老師所提供的課程，這些老師的姓名前面將用一紫色 💜 標記。其他老師所提供的課程將於 {activityInfo.parentAllSelectionStart} 開放選擇。
                    </DashboardBlock>
                : null}
                
                { parseInt(activityInfo.parentSelectionLimit) <= myClasses.length 
                    ? <DashboardBlock blockTitle="🟢 您已完成選課">
                        此梯次每學生可選 { activityInfo.parentSelectionLimit } 堂課，您已完成選擇。
                    </DashboardBlock>
                : null }
                
                { !displayClasses.length
                    ? <DashboardBlock blockTitle="🔴 無符合條件的課堂">
                        <IconTextPair svgName="information">🟢 您已選取了 { myClasses.length } 堂課</IconTextPair>
                        { selectionMode === "taughtBy"
                            ? `目前僅開放您選擇之前教過您孩童的志工老師所提供的課程。其他老師所提供的課程將於 ${activityInfo.parentAllSelectionStart} 開放選擇。`
                        : null}
                        { selectionMode === "all"
                            ? `無符合條件的課堂，請更換篩選條件。若無任何課堂可以對上您的時間，請與您的社區或學校反應，或者聯繫 contact@infvolunteer.org。`
                        : null}
                    </DashboardBlock>
                : null }

                <div className="select-class-classes-container">
                    { parseInt(activityInfo.parentSelectionLimit) > myClasses.length && displayClasses.map((thisClass, index) => {
                        const thisVolunteer = (volunteerDataMap || {})[thisClass.volunteerReferenceId];
                        return <ThisClassBlockV2 actvid={ actvid } key={ "select-class-" + thisClass.classId } 
                            volunteerData={ thisVolunteer } classObject={ thisClass } wasTaughtBy={ taughtBy.includes(thisClass.volunteerReferenceId) }
                            selected={ (thisClass.students || []).includes(topLevelStates.user.referenceId) } canSelectAny={ canSelectAny } />
                    }) }
                </div>

            </MinorPaneView>
        </>
    )
}

function FilterBlock({ activityInfo, myClasses, updateFilter, sexOptions, subjectOptions, teacherOptions, viewAllOptions }){
    const [expandedView, setExpandedView] = useState(true);

    return (
        <DashboardBlock blockTitle="選課狀態與篩選條件" noSubtitle>
            <IconTextPair svgName="information">本梯次每學生可選：{activityInfo.parentSelectionLimit || 1} 堂</IconTextPair>
            <IconTextPair svgName="information">目前我已經選擇了：{ myClasses.length } 堂</IconTextPair>
            {/* <IconTextPair svgName="arrow_down_uni" style={{ marginTop: 15 }} 
                onClick={ () => setExpandedView(!expandedView) }>
                點擊展開篩選條件
            </IconTextPair> */}
            { expandedView
                ? <>
                    <SelectionList onChangeFunction={ updateFilter } name="subject"
                        displayName="必須包含可選科目：" options={ subjectOptions } />
                    {/* <SelectionList onChangeFunction={ updateFilter } name="sex"
                        displayName="志工老師性別：" options={ sexOptions } />
                    <SelectionList onChangeFunction={ updateFilter } name="teacher"
                        displayName="老師條件：" options={ teacherOptions } />
                    <SelectionList onChangeFunction={ updateFilter } name="viewAll"
                        displayName="是否顯示所有課程：" options={ viewAllOptions } /> */}
                    <DaysSelection selectedDays={ [1, 2, 3, 4, 5, 6, 7] }
                        onChangeFunction={ (newDays => updateFilter(newDays, "days")) } displayName="可上課日" />
                </>
            : null }
        </DashboardBlock>
    )
}

function ThisClassBlockV2({classObject, volunteerData, actvid, selected, wasTaughtBy, canSelectAny, alreadySelected}){
    const [expandedView, setExpandedView] = useState(false);
    function toggleExpandedView(){
        setExpandedView(!expandedView);
    }

    const [showingPopup, setShowingPopup] = useState(false);
    const canSelect = 
        (classObject.students || []).length < (classObject.volunteerHandle || 1)
        && (canSelectAny || wasTaughtBy);
console.log(classObject)
    return (
        <>
            <div className="this-class-block" style={ selected ? { display: 'none' } : {} }>
                <div className="this-class-block-title-container" onClick={ toggleExpandedView }>
                    <div className="this-class-block-title">{ (selected ? "🟢 已選 " : "") + 
                        (wasTaughtBy ? " 💜 " : "") + 
                        `${volunteerData?.name}（${volunteerData?.sex}）` } - 共 { (classObject.classTime || []).length } 次上課 </div>
                    <div className="this-class-block-title-right"></div>
                </div>
                { !expandedView
                    ? <div className="this-class-block-content" onClick={ e => e.stopPropagation() }>
                        <ClassTimeDays allMonths classTime={ classObject.classTime } />
                        { [...(classObject.subjects || []), ...(!classObject.students?.length ? ["assistance"] : [])].map((subject, index) => {
                            return <SubjectChip style={{ display: 'inline-flex' }} 
                                key={ "select-class-" + classObject.classId + "-" + index } subject={ subject } />
                        }) }
                    </div>
                    : <div className="this-class-block-content">
                        <div className={ "select-class-content-flex" }>
                            <div className={ "select-class-content-flex-time"}>
                                <CalendarTimeView classTime={ classObject.classTime }
                                    rowHeight="32" miniview style={{ marginTop: 0 }} />
                            </div>
                            <div className="select-class-content-flex-information">
                                <div className="select-class-content-flex-information-text">可選擇科目：</div>
                                <div>
                                    { [...(classObject.subjects || []), ...(!classObject.students?.length ? ["assistance"] : [])].map((subject, index) => {
                                        return <SubjectChip style={{ display: 'inline-flex' }} 
                                            key={ "select-class-" + classObject.classId + "-" + index } subject={ subject } />
                                    }) }
                                </div>
                                {/*<div className="select-class-content-flex-information-text">
                                    老師學校：{volunteerData?.school}
                                </div>*/}
                                <div className="select-class-content-flex-information-text">
                                    老師年級：{volunteerData?.grade}
                                </div>
                                <div className="select-class-content-flex-information-text">
                                    課堂人數上限：{ classObject.volunteerHandle || 1 }
                                </div>
                                <div className="select-class-content-flex-information-text">
                                    剩餘人數空缺：{ (classObject.volunteerHandle || 1) - (classObject.students || []).length }
                                </div>
                                <div className="select-class-content-flex-information-text">
                                    課程連結： { (classObject.classLink)}
                                </div>
                                { classObject.volunteerHandle > 1
                                    ? <div className="select-class-content-flex-information-text">
                                        若選課業輔導，則為一對一課程。
                                    </div>
                            : null }
                            </div>
                        </div>
                        { !alreadySelected
                            ? (canSelect
                                ?  (selected
                                    ? <SubmitButton buttonText="已經選擇此課" condense noSidePadding decolorized />    
                                    : <SubmitButton buttonText="選擇這堂課" condense noSidePadding
                                        onSubmitFunction={ () => setShowingPopup(true) } />
                                )
                                : <SubmitButton buttonText="不開放選擇" condense noSidePadding decolorized />)
                        : null}
                    </div>
                }
            </div>
            <SelectClassPopup actvid={ actvid } showing={ showingPopup } closePopupFunction={ setShowingPopup }
                classObject={ classObject || {} } volunteerData={ volunteerData || {} } />
        </>
    )
}

function SelectClassPopup({ showing, actvid, closePopupFunction, classObject, volunteerData }){
    const topLevelStates = useContext(topLevelStatesContext);

    const [availableSubjects, setAvailableSubjects] = useState(classObject.subjects || []);
    useEffect(() => {
        setAvailableSubjects(classObject.subjects);
    }, [classObject.subjects?.length]);
    if((availableSubjects || []).includes("assistance")){
        const newAvailableSubjects = [...availableSubjects];
        newAvailableSubjects.splice(newAvailableSubjects.indexOf("assistance"), 1);
        setAvailableSubjects(newAvailableSubjects);
    }

    const allSelectibleSubjects = [...(classObject.subjects || []), 
    ...(!classObject.students?.length ? ["assistance"] : [])]
    const selectedSubject = useRef(classObject.subjects?.length > 1 ? [] : classObject.subjects);

    function selectedSubjectChanged(newSelectedSubjects){
        selectedSubject.current = newSelectedSubjects;
    }

    const [removedDates, setRemovedDates] = useState([]);

    function updateRemovedDates(calendarClickEvent) {
        setRemovedDates(prevDates => {
            if (!prevDates.includes(calendarClickEvent.date)) {
                return [...prevDates, calendarClickEvent.date];
            }
            return prevDates.filter(date => date !== calendarClickEvent.date);
        });
    }

    function confirmSelection(){
        if(selectedSubject.current?.length && selectedSubject.current[0] === "assistance"){
            selectAssistance();
        } else {
            selectSubject();
        }
        saveRemovedDates();
    }

    function selectSubject(){
        if(topLevelStates.user.userType === "community") return;
        if(!selectedSubject.current?.length){
            topLevelStates.addNotification("請選擇科目");
            return;
        }
        updateClassInActivityByClassId(actvid, classObject.classId, {
            students: arrayUnion(topLevelStates.user.referenceId),
            subjects: selectedSubject.current
        }, topLevelStates.addNotification);
    }

    function selectAssistance(){
        if(topLevelStates.user.userType === "community") return;
        updateClassInActivityByClassId(actvid, classObject.classId, {
            students: arrayUnion(topLevelStates.user.referenceId),
            volunteerHandle: 1,
            subjects: ["assistance"]
        }, topLevelStates.addNotification);
    }

    function saveRemovedDates() {
        const updatedClassTime = classObject.classTime.filter(time => !removedDates.includes(time.date));
        updateClassInActivityByClassId(actvid, classObject.classId, {
            classTime: updatedClassTime
        }, topLevelStates.addNotification);
    }

    const stepsContent = {
        1: {
            title: "再次確認課程資訊",
            subtitle: "點擊日曆上的日期可以取消或重新選擇該日上課時間",
            content: <>
                <IconTextPair svgName="smile">老師：{volunteerData.name}（{volunteerData.sex}）</IconTextPair>
                <IconTextPair svgName="note">
                    剩餘空缺/人數上限：{(classObject.volunteerHandle || 1) - (classObject.students || []).length}/{classObject.volunteerHandle || 1}
                </IconTextPair>
                <IconTextPair svgName="calendar">選擇上課日期（請選擇一週內一天的特定時段，直至梯次結束為止都將以此為每次上課時間）：</IconTextPair>
                <CalendarTimeView 
                    clickable 
                    classTime={classObject.classTime}
                    rowHeight="32" 
                    triggerFunction={updateRemovedDates}
                    miniview 
                    style={{ marginTop: 5, marginBottom: 40 }} 
                    removedDates={removedDates}
                />
                <IconTextPair svgName="hint" wrap>課內的英文輔導、課內的數學輔導等學科輔導，皆請選擇「課業輔導」。</IconTextPair><br/>
                <SubjectsSelection 
                    showTitle 
                    selectionLimit="1"
                    selectedSubjects={selectedSubject.current}
                    onChangeFunction={selectedSubjectChanged}
                    availableSubjects={allSelectibleSubjects} 
                />
                <SubmitButton 
                    buttonText="確認選擇這堂課與上課時間" 
                    center
                    onSubmitFunction={confirmSelection} 
                />
            </>
        }
    };

    return (
        <Popup showing={showing} closePopupFunction={closePopupFunction}>
            <PopupSection sectionTitle={stepsContent[1].title}
                sectionSubtitle={stepsContent[1].subtitle}>
                {stepsContent[1].content}
            </PopupSection>
        </Popup>
    );
}
    function ExplanationTab({activityInfo}){
        return (
            <>
                <DashboardBlock blockTitle="1. 關於本梯次的服務">
                    <IconTextPair svgName="time">開始日期：{ activityInfo.startTime }</IconTextPair>
                    <IconTextPair svgName="time">結束日期：{ activityInfo.endTime }</IconTextPair>
                    <IconTextPair svgName="information" wrap>{ activityInfo.workingDescription }</IconTextPair>
                    <IconTextPair svgName="file">每位學生可選 { activityInfo.parentSelectionLimit } 堂課</IconTextPair>
                    { activityInfo.workingMaterialLink
                        ? <IconTextPair svgName="launch">簡章/說明書：
                            <a href={ activityInfo.workingMaterialLink } target="_blank" >[連結點此]</a>
                        </IconTextPair>
                    : null }
                    <IconTextPair svgName="subject">可選擇的科目： </IconTextPair>
                    <div>
                        {(activityInfo.subjects || []).map((subject, index) => {
                            return <SubjectChip key={ "select-class-subject-chip-" + subject }
                                subject={ subject } style={{ display: 'inline-flex' }} />
                        })}
                    </div>
                </DashboardBlock>
                <DashboardBlock blockTitle="2. 選課開放時間">
                    <IconTextPair svgName="time" wrap>{ activityInfo.parentTaughtBySelectionStart } 始，開放各學生家長選擇以往教過該學生的志工老師提供的課程。</IconTextPair>
                    <IconTextPair svgName="time" wrap>{ activityInfo.parentAllSelectionStart } 始，開放各學生家長選擇所有課程。</IconTextPair>
                    <IconTextPair svgName="report">注意，每位學生可選 { activityInfo.parentSelectionLimit } 堂課</IconTextPair>
                </DashboardBlock>
                <DashboardBlock blockTitle="3. 選課方法教學">
                    <IconTextPair svgName="link">選課教學影片：
                        <a href="https://youtu.be/rCiVsDb8USo" target="_blank" >[連結點此]</a>
                    </IconTextPair>
                </DashboardBlock>
                <Link to={ "/SelectClass?actvid=" + activityInfo.actvid + "&tab=" + "select" }>
                    <SubmitButton center noSidePadding buttonText={ <>&emsp;我已詳閱，前往選課&emsp;</> } />
                </Link>
            </>
        )
    }