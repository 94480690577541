import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList, SubmitButton } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { Popup, PopupSection } from "../components/Popup";
import TopNavTabs from "../components/TopNavTabs";
import { getActivityPublicInfo, getAllStudentsFromCommunity, getClassesInActivity, getStudentsByActivities, getStudentsFromCommunity, getVolunteersByActivities } from "../lib/user";
import { chopArray } from "../lib/util";
import "../style/ClassCheck.css";

export default function ClassCheck() {

    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityInfo, setActivityInfo] = useState(null);
    const [activityClasses, setActivityClasses] = useState([]);
    const [volunteerPersonalDataMap, setVolunteerPersonalDataMap] = useState({});
    const [communityStudentsMap, setCommunityStudentsMap] = useState({});

    useEffect(() => {
        if (activityInfo === null) {
            getActivityPublicInfo(actvid, returnedData => {
                setActivityInfo(returnedData);
                getAllStudentsFromCommunity(returnedData.communityReferenceId, returnedList => {
                    const newCommunityStudentsMap = {};
                    returnedList.forEach(studentObject => {
                        newCommunityStudentsMap[studentObject.studentReferenceId] = studentObject;
                    });
                    setCommunityStudentsMap(newCommunityStudentsMap);
                });
            });
            getClassesInActivity(actvid, setActivityClasses);
            getVolunteersByActivities(actvid, returnedList => {
                const newVolunteerPersonalDataMap = {};
                returnedList.forEach(volunteerObject => {
                    newVolunteerPersonalDataMap[volunteerObject.referenceId] = volunteerObject;
                });
                setVolunteerPersonalDataMap(newVolunteerPersonalDataMap);
            })
        }
    }, [actvid, activityInfo]);

    const volunteerClassesMap = useMemo(() => {
        const newVolunteerClassesMap = {};
        activityClasses.forEach(classObject => {
            if (!classObject.volunteerReferenceId) return;
            if (!newVolunteerClassesMap[classObject.volunteerReferenceId]) newVolunteerClassesMap[classObject.volunteerReferenceId] = [];
            newVolunteerClassesMap[classObject.volunteerReferenceId].push(classObject);
        });
        return newVolunteerClassesMap;
    }, [activityClasses.length]);

    const tabs = [
        {
            displayName: "檢索單一志工",
            name: "single",
            content: (
                <SingleView
                    communityStudentsMap={communityStudentsMap}
                    volunteerClassesMap={volunteerClassesMap}
                    volunteerPersonalDataMap={volunteerPersonalDataMap}
                    activityInfo={activityInfo}
                />
            ),
        },
        {
            displayName: "檢索多位志工",
            name: "multiple",
            content: (
                <MultipleView
                    communityStudentsMap={communityStudentsMap}
                    volunteerClassesMap={volunteerClassesMap}
                    volunteerPersonalDataMap={volunteerPersonalDataMap}
                    activityInfo={activityInfo}
                />
            ),
        },
    ];

    return (
        <AccountTypeAccess accessFor={["community", "admin"]}>
            <DashboardFrame selected="dashboard" full>
                <TopNavTabs
                    pageTitle="志工教學稽核頁面"
                    pageSubtitle="Infinitas 學生志工計畫"
                    tabs={tabs}
                />
            </DashboardFrame>
        </AccountTypeAccess>
    )
}

function MultipleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }) {
    return (
        <div className="class-check-classes-container">
            {Object.keys(volunteerClassesMap).map((volunteerReferenceId, index) => {
                const thisVolunteer = volunteerPersonalDataMap[volunteerReferenceId];
                return (
                    <div className="multiple-view-row" key={volunteerReferenceId}>
                        <div className="multiple-view-row-left">
                            {thisVolunteer?.name || "查無志工"}
                        </div>
                        <div className="multiple-view-row-right">
                            <div className="multiple-view-row-right-row class-check-classes">
                                {volunteerClassesMap[volunteerReferenceId].map((volunteerClass, index2) => {
                                    if (!volunteerClass.students?.length) return <></>;
                                    return (
                                        <div className={"class-check-class " + (volunteerClass.students.length > 0 ? "multiple-view-row-class-block-filled" : "")} key={`${volunteerReferenceId}-${index2}`}>
                                            <div className="class-check-class-info">
                                                <div className="class-check-class-info-class-id">Class ID:</div>
                                                <div className="class-check-class-info-class-id">{volunteerClass.classId}</div>
                                                <div className="class-check-class-info-student-name">學生：{communityStudentsMap[volunteerClass.students[0]].name} </div>
                                                <div className="class-check-class-info-stats">
                                                    服務 {volunteerClass.classTime.reduce((acc, curr) => acc + parseInt(curr.classLength) + parseInt(curr.prepLength), 0)} 分鐘
                                                </div>
                                            </div>
                                            <div className="class-check-class-student">
                                                {volunteerClass.classTime.map((classTimeObject, index3) => {
                                                    const recordContent = Object.values(classTimeObject)
                                                    return (
                                                        <div className={"class-check-class-occurrence " + (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")}
                                                            key={`class-check-class-occurrence-${volunteerReferenceId}-${volunteerClass.classId}-${index3}`}>
                                                            <div className="class-check-class-occurrence-title">{classTimeObject.date}</div>
                                                            {classTimeObject.linkedRecord
                                                                ? <>
                                                                    <div className="class-check-class-occurence-content">
                                                                        <IconTextPair svgName="time" fontSize="15">上課 {classTimeObject.linkedRecord.classLength} 分鐘</IconTextPair>
                                                                        <IconTextPair svgName="time" fontSize="15">備課 {classTimeObject.linkedRecord.prepLength} 分鐘</IconTextPair>
                                                                    </div>
                                                                    <div className="class-check-class-occurence-content">
                                                                        教學紀錄：<br />
                                                                        {classTimeObject.linkedRecord.teachingRecord}<br />
                                                                        生活關懷：<br />
                                                                        {classTimeObject.linkedRecord.lifeRecord}
                                                                        <Link target="_blank" to={`/StudentProfile?communityReferenceId=${activityInfo.communityReferenceId}&studentReferenceId=${communityStudentsMap[volunteerClass.students[0]].studentReferenceId}&recordReferenceId=${classTimeObject.linkedRecord.recordReferenceId}`}>
                                                                            <SubmitButton squeeze center buttonText="前往教學紀錄" />
                                                                        </Link>
                                                                    </div>
                                                                </>
                                                                : null}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}


function SingleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }) {

    const [viewingVolunteer, setViewingVolunteer] = useState(null);
    const viewingVolunteerSelection = [{ value: null, displayName: "無" }];
    let i = 0;
    Object.keys(volunteerClassesMap).forEach((volunteerReferenceId) => {
        const volunteerName = ((volunteerPersonalDataMap || {})[volunteerReferenceId] || {}).name;
        if (!volunteerName) return;
        if (i === 0 && volunteerName && viewingVolunteer === null) setViewingVolunteer(volunteerReferenceId);
        viewingVolunteerSelection.push({
            value: volunteerReferenceId,
            displayName: volunteerName
        });
        i++;
    });
    function viewingVolunteerChanged(e) {
        setViewingVolunteer(e.nativeEvent.target.value);
    }

    return (
        <>
            <div className="class-check-selection">
                <SelectionList
                    options={viewingVolunteerSelection}
                    name="viewingVolunteer"
                    valueSet={{ viewingVolunteer: viewingVolunteer }}
                    onChangeFunction={viewingVolunteerChanged}
                />
            </div>
            <div className="class-check-classes-container">
                {viewingVolunteer && ((volunteerClassesMap || {})[viewingVolunteer] || []).map((classObject, index) => {
                    return <>
                        {(classObject.students || []).map((studentReferenceId, index) => {
                            return <ClassStudentBlock
                                key={`class-check-${viewingVolunteer}-${index}`}
                                studentCommunityData={(communityStudentsMap || {})[studentReferenceId] || {}}
                                viewingVolunteer={viewingVolunteer}
                                classObject={classObject}
                                activityInfo={activityInfo}
                            />
                        })}

                    </>

                })}
            </div>
        </>
    )
}

function ClassStudentBlock({ viewingVolunteer, studentCommunityData, classObject, activityInfo }) {

    const studentRecords = studentCommunityData.records || [];
    const modClassTime = classObject.classTime || [];
    let totalClassLength = 0;
    let totalPrepLength = 0;
    let totalRecords = 0;
    for (let i = 0; i < modClassTime.length; i++) {
        modClassTime[i].linkedRecord = studentRecords.find(recordObject => {
            if (recordObject.date === modClassTime[i].date && recordObject.volunteerReferenceId === viewingVolunteer) {
                totalClassLength += parseInt(recordObject.classLength);
                totalPrepLength += parseInt(recordObject.prepLength);
                totalRecords++;
                return true;
            }
            return false;
        });
    }

    const choppedClassTimes = useMemo(() => {
        return chopArray(modClassTime || [], 6);
    }, [(modClassTime || []).length]);

    const [currentPage, setCurrentPage] = useState(0);
    function updateCurrentPage(direction) {
        if (direction === 1 && currentPage === choppedClassTimes.length - 1) return;
        if (direction === -1 && currentPage === 0) return;
        setCurrentPage(currentPageArg => currentPageArg + direction);
    }

    const [showingPopup, setShowingPopup] = useState(false);

    return (
        <>
            <div className="class-check-class">
                <div className="class-check-class-info">
                    <div className="class-check-class-info-class-id">Class ID:</div>
                    <div className="class-check-class-info-class-id">{classObject.classId}</div>
                    <div className="class-check-class-info-student-name">學生：{studentCommunityData.name} </div>
                    <div className="class-check-class-info-stats" onClick={() => setShowingPopup(true)}>
                        服務 {totalClassLength + totalPrepLength} 分鐘 <br />
                        (點擊顯示詳情)
                    </div>
                    <div className="class-check-class-info-buttons-container">
                        <SubmitButton center condense buttonText="<" onSubmitFunction={() => updateCurrentPage(-1)}
                            decolorized={currentPage === 0} />
                        <SubmitButton center condense buttonText=">" onSubmitFunction={() => updateCurrentPage(1)}
                            decolorized={currentPage === choppedClassTimes.length - 1} />
                    </div>
                </div>
                <div className="class-check-class-student">
                    {choppedClassTimes.length && choppedClassTimes[currentPage].map((classTimeObject, index) => {
                        const recordContent = Object.values(classTimeObject)

                        return (
                            <div className={"class-check-class-occurrence " + (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")}
                                key={`class-check-class-occurrence-${viewingVolunteer}-${classObject.classId}-${index}`}>
                                <div className="class-check-class-occurrence-title">{classTimeObject.date}</div>
                                {classTimeObject.linkedRecord
                                    ? <>
                                        <div className="class-check-class-occurence-content">
                                            <IconTextPair svgName="time" fontSize="15">上課 {classTimeObject.linkedRecord.classLength} 分鐘</IconTextPair>
                                            <IconTextPair svgName="time" fontSize="15">備課 {classTimeObject.linkedRecord.prepLength} 分鐘</IconTextPair>
                                        </div>
                                        <div className="class-check-class-occurence-content">
                                            教學紀錄：<br />
                                            {classTimeObject.linkedRecord.teachingRecord}<br />
                                            生活關懷：<br />
                                            {classTimeObject.linkedRecord.lifeRecord}
                                            <Link target="_blank" to={`/StudentProfile?communityReferenceId=${activityInfo.communityReferenceId}&studentReferenceId=${studentCommunityData.studentReferenceId}&recordReferenceId=${classTimeObject.linkedRecord.recordReferenceId}`}>
                                                <SubmitButton squeeze center buttonText="前往教學紀錄" />
                                            </Link>
                                        </div>
                                    </>

                                    : null}
                            </div>
                        )
                    })}
                </div>
            </div>
            <Popup showing={showingPopup} closePopupFunction={setShowingPopup} thin>
                <PopupSection sectionTitle="服務統計資料">
                    <IconTextPair svgName="information">學生：{studentCommunityData.name}</IconTextPair>
                    <IconTextPair svgName="time">總備課時間：{totalPrepLength} 分鐘</IconTextPair>
                    <IconTextPair svgName="time">總上課時間：{totalClassLength} 分鐘</IconTextPair>
                    <IconTextPair svgName="time">總服務時間：{totalClassLength + totalPrepLength} 分鐘</IconTextPair>
                    <IconTextPair svgName="information">已經完成的教學紀錄：{Math.floor(totalRecords / (modClassTime || []).length * 100)} %</IconTextPair>
                </PopupSection>

            </Popup>
        </>
    )
}


/*import { useEffect, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList, SubmitButton } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { Popup, PopupSection } from "../components/Popup";
import TopNavTabs from "../components/TopNavTabs";
import { getActivityPublicInfo, getAllStudentsFromCommunity, getClassesInActivity, getStudentsByActivities, getStudentsFromCommunity, getVolunteersByActivities } from "../lib/user";
import { chopArray } from "../lib/util";
import "../style/ClassCheck.css";

export default function ClassCheck(){

    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityInfo, setActivityInfo] = useState(null);
    const [activityClasses, setActivityClasses] = useState([]);
    const [volunteerPersonalDataMap, setVolunteerPersonalDataMap] = useState({});
    const [communityStudentsMap, setCommunityStudentsMap] = useState({});
    useEffect(() => {
        if(activityInfo === null){
            getActivityPublicInfo(actvid, returnedData => {
                setActivityInfo(returnedData);
                getAllStudentsFromCommunity(returnedData.communityReferenceId, returnedList => {
                    const newCommunityStudentsMap = {};
                    returnedList.forEach(studentObject => {
                        newCommunityStudentsMap[studentObject.studentReferenceId] = studentObject;
                    });
                    setCommunityStudentsMap(newCommunityStudentsMap);
                });
            });
            getClassesInActivity(actvid, setActivityClasses);
            getVolunteersByActivities(actvid, returnedList => {
                const newVolunteerPersonalDataMap = {};
                returnedList.forEach(volunteerObject => {
                    newVolunteerPersonalDataMap[volunteerObject.referenceId] = volunteerObject;
                });
                setVolunteerPersonalDataMap(newVolunteerPersonalDataMap);
            })
        }
    });

    const volunteerClassesMap = useMemo(() => {
        const newVolunteerClassesMap = {};
        activityClasses.forEach(classObject => {
            if(!classObject.volunteerReferenceId) return;
            if(!newVolunteerClassesMap[classObject.volunteerReferenceId]) newVolunteerClassesMap[classObject.volunteerReferenceId] = [];
            newVolunteerClassesMap[classObject.volunteerReferenceId].push(classObject);
        });
        return newVolunteerClassesMap;
    }, [activityClasses.length]);


    const tabs = [
        {displayName: "檢索單一志工", name: "single", content: <SingleView communityStudentsMap={ communityStudentsMap }
            volunteerClassesMap={ volunteerClassesMap } volunteerPersonalDataMap={ volunteerPersonalDataMap } activityInfo={ activityInfo } />},
        {displayName: "檢索多位志工", name: "multiple", content: <MultipleView communityStudentsMap={ communityStudentsMap }
            volunteerClassesMap={ volunteerClassesMap } volunteerPersonalDataMap={ volunteerPersonalDataMap } activityInfo={ activityInfo } />},
    ]

    return (
        <AccountTypeAccess accessFor={ ["community", "admin"] }>
            <DashboardFrame selected="dashboard" full>
                <TopNavTabs pageTitle="志工教學稽核頁面" pageSubtitle="Infinitas 學生志工計畫" tabs={ tabs } />
            </DashboardFrame>
        </AccountTypeAccess>
    )
}

function MultipleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }) {
    return (
        <div className="class-check-classes-container">
            {Object.keys(volunteerClassesMap).map((volunteerReferenceId, index) => {
                const thisVolunteer = volunteerPersonalDataMap[volunteerReferenceId];
                return (
                    <div className="class-check-volunteer" key={volunteerReferenceId}>
                        <h3>{thisVolunteer?.name || "查無志工"}</h3>
                        <div className="class-check-classes">
                            {volunteerClassesMap[volunteerReferenceId].map((volunteerClass, index2) => {
                                if (!volunteerClass.students?.length) return <></>;
                                return (
                                    <div className="class-check-class" key={`${volunteerReferenceId}-${index2}`}>
                                        <div className="class-check-class-info">
                                            <div className="class-check-class-info-class-id">Class ID:</div>
                                            <div className="class-check-class-info-class-id">{volunteerClass.classId}</div>
                                            <div className="class-check-class-info-student-name">學生：{volunteerClass.students.join(', ')}</div>
                                            <div className="class-check-class-info-stats">
                                                {(volunteerClass.classTime || []).map((classTimeObject, index3) => {
                                                    const date = (classTimeObject.date || "").split("-");
                                                    date.shift();
                                                    return (
                                                        <div
                                                            className={"class-check-class-occurrence " +
                                                                (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")}
                                                            key={`${volunteerReferenceId}-${volunteerClass.classId}-${index3}`}
                                                        >
                                                            <div className="class-check-class-occurrence-title">{date.join("/")}</div>
                                                            <div className="class-check-class-occurrence-content">
                                                                <IconTextPair svgName="time" fontSize="15">上課 {classTimeObject.linkedRecord?.classLength || 0} 分鐘</IconTextPair>
                                                                <IconTextPair svgName="time" fontSize="15">備課 {classTimeObject.linkedRecord?.prepLength || 0} 分鐘</IconTextPair>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

/*function MultipleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }){

    return (
        <div className="class-check-classes-container">
            { Object.keys(volunteerClassesMap).map((volunteerReferenceId, index) => {
                const thisVolunteer = volunteerPersonalDataMap[volunteerReferenceId];
                return (
                    <div className="class-check-volunteer" key={volunteerReferenceId}>
                        <h3>{ thisVolunteer?.name || "查無志工" }</h3>
                        { volunteerClassesMap[volunteerReferenceId].map((volunteerClass, index2) => {
                            if(!volunteerClass.students?.length) return <></>;
                            return (
                                <div className="class-check-class" key={`${volunteerReferenceId}-${index2}`}>
                                    <div className="class-check-class-info">
                                        <div className="class-check-class-info-class-id">Class ID:</div>
                                        <div className="class-check-class-info-class-id">{ volunteerClass.classId }</div>
                                        <div className="class-check-class-info-student-name">學生：{ volunteerClass.students.join(', ') }</div>
                                        <div className="class-check-class-info-stats">
                                            { (volunteerClass.classTime || []).map((classTimeObject, index3) => {
                                                const date = (classTimeObject.date || "").split("-");
                                                date.shift();
                                                return (
                                                    <div 
                                                        className={ "class-check-class-occurrence " + 
                                                            (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")}
                                                        key={`${volunteerReferenceId}-${volunteerClass.classId}-${index3}`}
                                                    >
                                                        <div className="class-check-class-occurrence-title">{ date.join("/") }</div>
                                                        <div className="class-check-class-occurrence-content">
                                                            <IconTextPair svgName="time" fontSize="15">上課 { classTimeObject.linkedRecord?.classLength || 0 } 分鐘</IconTextPair>
                                                            <IconTextPair svgName="time" fontSize="15">備課 { classTimeObject.linkedRecord?.prepLength || 0 } 分鐘</IconTextPair>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    )
}*/

/*function MultipleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }){

    return (
        <div className="class-check-classes-container">
            { Object.keys(volunteerClassesMap).map((volunteerReferenceId, index) => {
                const thisVolunteer = volunteerPersonalDataMap[volunteerReferenceId];
                return (
                    <div className="class-check-volunteer" key={volunteerReferenceId}>
                        <h3>{ thisVolunteer?.name || "查無志工" }</h3>
                        { volunteerClassesMap[volunteerReferenceId].map((volunteerClass, index2) => {
                            if(!volunteerClass.students?.length) return <></>;
                            return (
                                <div className="class-check-class" key={`${volunteerReferenceId}-${index2}`}>
                                    <div className="class-check-class-info">
                                        <div className="class-check-class-info-class-id">Class ID:</div>
                                        <div className="class-check-class-info-class-id">{ volunteerClass.classId }</div>
                                        <div className="class-check-class-info-student-name">學生：{ volunteerClass.students.join(', ') }</div>
                                        <div className="class-check-class-info-stats">
                                            { (volunteerClass.classTime || []).map((classTimeObject, index3) => {
                                                const date = (classTimeObject.date || "").split("-");
                                                date.shift();
                                                return (
                                                    <div 
                                                        className={ "class-check-class-occurrence " + 
                                                            (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")}
                                                        key={`${volunteerReferenceId}-${volunteerClass.classId}-${index3}`}
                                                    >
                                                        <div className="class-check-class-occurrence-title">{ date.join("/") }</div>
                                                        <div className="class-check-class-occurrence-content">
                                                            <IconTextPair svgName="time" fontSize="15">上課 { classTimeObject.linkedRecord?.classLength || 0 } 分鐘</IconTextPair>
                                                            <IconTextPair svgName="time" fontSize="15">備課 { classTimeObject.linkedRecord?.prepLength || 0 } 分鐘</IconTextPair>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    )
}*/


/*function MultipleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }){
    console.log(communityStudentsMap);
    
    return (
        <div className="multiple-view-root">
            { (Object.keys(volunteerClassesMap) || []).map((volunteerReferenceId, index) => {
                const thisVolunteer = volunteerPersonalDataMap[volunteerReferenceId];
                return (
                    <div className="multiple-view-row" key={volunteerReferenceId}>
                        <div className="multiple-view-row-left">
                            { thisVolunteer?.name || "查無志工" }
                        </div>
                        <div className="multiple-view-row-right">
                            { volunteerClassesMap[volunteerReferenceId].map((volunteerClass, index2) => {
                                if(!volunteerClass.students?.length) return <></>;
                                const studentReferenceId = volunteerClass.students[0];
                                const studentRecords = (communityStudentsMap[studentReferenceId]?.records || []).filter(recordObject => {
                                    return recordObject.volunteerReferenceId === volunteerReferenceId;
                                });
                                return (
                                    <div className="multiple-view-row-right-row" key={`${volunteerReferenceId}-${index2}`}>
                                        <div className="multiple-view-row-left multiple-view-row-left-classId">
                                            { volunteerClass.classId }
                                        </div>
                                        <div className="multiple-view-row-left multiple-view-row-left-studentName">
                                            { communityStudentsMap[studentReferenceId]?.name || "查無學生" }
                                        </div>
                                        { (volunteerClass.classTime || []).map((classTimeObject, index3) => {
                                            const date = (classTimeObject.date || "").split("-");
                                            date.shift();
                                            return (
                                                <div 
                                                    className={ "multiple-view-row-class-block " + 
                                                        (studentRecords.find(record => record.date === classTimeObject.date) ? "multiple-view-row-class-block-filled" : "") }
                                                    key={`${volunteerReferenceId}-${volunteerClass.classId}-${index3}`}
                                                >
                                                    <div>{ date.join("/") }</div>
                                                    <div>{ classTimeObject.time }</div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}*/


/*function SingleView({ volunteerPersonalDataMap, communityStudentsMap, volunteerClassesMap, activityInfo }){

    const [viewingVolunteer, setViewingVolunteer] = useState(null);
    const viewingVolunteerSelection = [{value: null, displayName: "無"}];
    let i = 0;
    Object.keys(volunteerClassesMap).forEach((volunteerReferenceId) => {
        const volunteerName = ((volunteerPersonalDataMap || {})[volunteerReferenceId] || {}).name;
        if(!volunteerName) return;
        if(i === 0 && volunteerName && viewingVolunteer === null) setViewingVolunteer(volunteerReferenceId); 
        viewingVolunteerSelection.push({
            value: volunteerReferenceId,
            displayName: volunteerName
        });
        i++;
    });
    function viewingVolunteerChanged(e){
        setViewingVolunteer(e.nativeEvent.target.value);
    }

    return (
        <>
            <div className="class-check-selection">
                <SelectionList options={ viewingVolunteerSelection } name="viewingVolunteer" 
                    valueSet={{ viewingVolunteer: viewingVolunteer }} onChangeFunction={ viewingVolunteerChanged } />
            </div>
            <div className="class-check-classes-container">
                { viewingVolunteer && ((volunteerClassesMap || {})[viewingVolunteer] || []).map((classObject, index) => {
                    return <>
                        { (classObject.students || []).map((studentReferenceId, index) => {
                            return <ClassStudentBlock key={ `class-check-${viewingVolunteer}-${index}` }
                                studentCommunityData={ (communityStudentsMap || {})[studentReferenceId] || {} } 
                                viewingVolunteer={viewingVolunteer} classObject={ classObject } activityInfo={ activityInfo } />
                        }) }
                    
                    </>
                        
                }) }
            </div>
        </>
    )
}

function ClassStudentBlock({ viewingVolunteer, studentCommunityData, classObject, activityInfo }){

    const studentRecords = studentCommunityData.records || [];
    const modClassTime = classObject.classTime || [];
    let totalClassLength = 0;
    let totalPrepLength = 0;
    let totalRecords = 0;
    for(let i = 0; i < modClassTime.length; i++){
        modClassTime[i].linkedRecord = studentRecords.find(recordObject => {
            if(recordObject.date === modClassTime[i].date && recordObject.volunteerReferenceId === viewingVolunteer){
                totalClassLength += parseInt(recordObject.classLength);
                totalPrepLength += parseInt(recordObject.prepLength);
                totalRecords++;
                return true;
            }
            return false;
        });
    }

    const choppedClassTimes = useMemo(() => {
        return chopArray(modClassTime || [], 6);
    }, [(modClassTime || []).length]);

    const [currentPage, setCurrentPage] = useState(0);
    function updateCurrentPage(direction){
        if(direction === 1 && currentPage === choppedClassTimes.length - 1) return;
        if(direction === -1 && currentPage === 0) return;
        setCurrentPage(currentPageArg => currentPageArg + direction);
    }

    const [showingPopup, setShowingPopup] = useState(false);

    return (
        <>
            <div className="class-check-class">
                <div className="class-check-class-info">
                    <div className="class-check-class-info-class-id">Class ID:</div>
                    <div className="class-check-class-info-class-id">{ classObject.classId }</div>
                    <div className="class-check-class-info-student-name">學生：{studentCommunityData.name} </div>
                    <div className="class-check-class-info-stats" onClick={ () => setShowingPopup(true) }>
                        服務 { totalClassLength + totalPrepLength } 分鐘 <br/>
                        (點擊顯示詳情)
                    </div>
                    <div className="class-check-class-info-buttons-container">
                        <SubmitButton center condense buttonText="<" onSubmitFunction={ () => updateCurrentPage(-1) }
                            decolorized={ currentPage === 0 } />
                        <SubmitButton center condense buttonText=">" onSubmitFunction={ () => updateCurrentPage(1) }
                            decolorized={ currentPage === choppedClassTimes.length - 1 } />
                    </div>
                </div>
                <div className="class-check-class-student">
                    { choppedClassTimes.length && choppedClassTimes[currentPage].map((classTimeObject, index) => {
                        const recordContent = Object.values(classTimeObject)

                        return (
                            <div className={ "class-check-class-occurrence " + (classTimeObject.linkedRecord ? "class-check-class-occurrence-completed" : "")} 
                                key={ `class-check-class-occurrence-${viewingVolunteer}-${classObject.classId}-${index}` }>
                                <div className="class-check-class-occurrence-title">{ classTimeObject.date }</div>
                                { classTimeObject.linkedRecord
                                    ? <>
                                        <div className="class-check-class-occurence-content">
                                            <IconTextPair svgName="time" fontSize="15">上課 { classTimeObject.linkedRecord.classLength } 分鐘</IconTextPair>
                                            <IconTextPair svgName="time" fontSize="15">備課 { classTimeObject.linkedRecord.prepLength } 分鐘</IconTextPair>
                                        </div>
                                        <div className="class-check-class-occurence-content">
                                            教學紀錄：<br />
                                            { classTimeObject.linkedRecord.teachingRecord }<br/>
                                            生活關懷：<br />
                                            { classTimeObject.linkedRecord.lifeRecord }
                                            <Link target="_blank" to={ `/StudentProfile?communityReferenceId=${activityInfo.communityReferenceId}&studentReferenceId=${studentCommunityData.studentReferenceId}&recordReferenceId=${classTimeObject.linkedRecord.recordReferenceId}` }>
                                                <SubmitButton squeeze center buttonText="前往教學紀錄" />
                                            </Link>
                                        </div>
                                    </>
                                    
                                : null }
                            </div>
                        )
                    }) }
                </div>
            </div>
            <Popup showing={ showingPopup } closePopupFunction={ setShowingPopup } thin>
                <PopupSection sectionTitle="服務統計資料">
                    <IconTextPair svgName="information">學生：{ studentCommunityData.name }</IconTextPair>
                    <IconTextPair svgName="time">總備課時間：{ totalPrepLength } 分鐘</IconTextPair>
                    <IconTextPair svgName="time">總上課時間：{ totalClassLength } 分鐘</IconTextPair>
                    <IconTextPair svgName="time">總服務時間：{ totalClassLength + totalPrepLength } 分鐘</IconTextPair>
                    <IconTextPair svgName="information">已經完成的教學紀錄：{ Math.floor(totalRecords / (modClassTime || []).length * 100) } %</IconTextPair>
                </PopupSection>

            </Popup>
        </>
    )
}*/