import { TableFrame, TableRow } from "./Table";
import "../style/ObjectAttributeView.css";

export default function ObjectAttributeView(props){

    const objectData = props.object;

    return <TableFrame width="800" rowHeight="auto" 
        noScroll={ props.noScroll }
        columns={ [{content: "屬性標籤", width: 200},
        {content: "屬性標籤內容", width: 700}] }>

        { Object.entries(objectData || {}).sort().map(([objectDataKey, objectDataContent], index) => {
            return (
                <TableRow key={ "user-data-row-" + index } content={ [
                    <div className="object-attribute-view-block object-attribute-view-key">
                        { objectDataKey }
                    </div>,
                    <div className="object-attribute-view-block object-attribute-view-content">
                        { JSON.stringify(objectDataContent) }
                    </div>
                ] } />
            )
        }) }

    </TableFrame>
}