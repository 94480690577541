import { Link } from "react-router-dom";
import "../style/ChipButton.css";

export function ChipButton(props){
    const dir = require.context("../ico/");
    const icoUrl = dir("./" + props.svgName + ".svg");
    const buttonElement = <div className="chip-button"
        onClick={ props.onClick }>
        <div className="chip-button-left" style={{
            backgroundImage: `url(${icoUrl})`
        }}></div>
        <div className="chip-button-right">{ props.buttonText }</div>
    </div>
    if(!props.link) return buttonElement;
    return <Link to={ props.link } >{ buttonElement }</Link>;
}