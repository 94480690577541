export function daysInMonth(year, month) { 
    return (new Date(year, month, 0)).getDate();
}
export function getDayOfFirstDate(year, month){
    return (new Date(year, month - 1, 1)).getDay();
}
export function fillYearMonth(yearMonthArray/** array of YYYYMM in int */){
    const filledYearMonth = [];
    let startMonth = Math.min(...yearMonthArray);
    let endMonth = Math.max(...yearMonthArray);
    let monthPointer = startMonth;
    while(monthPointer <= endMonth){
        if(monthPointer % 100 > 12){
            monthPointer += 87;
            continue;
        }
        if(monthPointer % 100){
            filledYearMonth.push(monthPointer);
        }
        monthPointer++;
    }
    return filledYearMonth;
}
// Always make sure that the date strings passed in are formatted as:
// YYYY-MM-DD and not YYYY-M-DD or YYYY-MM-D or YYYY-M-D, because the
// Apple's JavaScriptCore, used in Safari, can't fucking recognize that.
export function getDateTimeFromDays(startDate, endDate, dayTimeMap, 
    restrictionLower=null, restrictionUpper=null){
        
    const dates = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const day = new Date(start);
    
    const lower = restrictionLower ? new Date(restrictionLower) : null;
    const upper = restrictionUpper ? new Date(restrictionUpper) : null;

    const dayArray = [];
    Object.keys(dayTimeMap || {}).forEach(dayString => {
        if(Array.isArray(dayTimeMap[dayString]) && dayTimeMap[dayString].length){
            dayArray.push(parseInt(dayString));
        }
    });
    while(day <= end){
        // getDay returns 0 through 6, but dayArray has 1 through 7
        if(lower && day < lower){
            day.setDate(lower.getDate());
        }
        if(upper && day > upper) break;
        console.log(day);
        if(dayArray.indexOf((day.getDay() + 6) % 7 + 1) > -1){
            dayTimeMap[(day.getDay() + 6) % 7 + 1].forEach(hour => {
                const newDateObject = new Date(day);
                newDateObject.setHours(hour);
                newDateObject.setMinutes(Math.round((hour % 1) * 60));
                dates.push({
                    date: getDateStringFromDate(newDateObject),
                    time: getTimeStringFromDate(newDateObject)
                });
            });
        }
        day.setDate(day.getDate() + 1);            
    }
    return dates;
}
export function getDateStringFromDate(date){
    let monthString = (date.getMonth() + 1).toString();
    if(monthString.length === 1) monthString = "0" + monthString;
    let dateString = date.getDate().toString();
    if(dateString.length === 1) dateString = "0" + dateString;
   
    return `${date.getFullYear()}-${monthString}-${dateString}`;
}
export function getTimeStringFromDate(date){
    let hourString = date.getHours().toString();
    if(hourString.length === 1) hourString = "0" + hourString;
    let minuteString = date.getMinutes().toString();
    if(minuteString.length === 1) minuteString = "0" + minuteString;
    return `${hourString}:${minuteString}`;
}
export function getDatesFromDateTime(dateTimeMapArray){
    return [...new Set(dateTimeMapArray.map(dateTime => dateTime.date))]
}
export function getTodayDateString(){
    return getDateStringFromDate(new Date());
}
export function getThisMonthInt(){
    const todayDate = new Date();
    return todayDate.getMonth() + 1 + todayDate.getFullYear() * 100;
}
export function getTimeStringFromHour(hourInt){
    let minuteString = Math.round((hourInt % 1) * 60).toString();
    if(minuteString.length === 1) minuteString = "0" + minuteString;
    let hourString = Math.floor(hourInt).toString();
    if(hourString.length === 1) hourString = "0" + hourString;
    return `${hourString}:${minuteString}`
}
export function getHourFromTimeString(timeString){
    const [hourString, minuteString] = timeString.split(":");
    return parseInt(hourString) + Math.round(parseInt(minuteString) / 60 * 10000) / 10000;
}
export function getClassTimeInThisMonth(classTimeArray){
    const returnList = [];
    let thisMonthString = ((new Date()).getMonth() + 1).toString();
    if(thisMonthString.length === 1) thisMonthString = "0" + thisMonthString;
    classTimeArray.forEach(classTimeObject => {
        if(classTimeObject.date?.split("-")[1] === thisMonthString) returnList.push(classTimeObject);
    });
    return returnList;
}
export function getDayTimeMapFromClassTime(classTimeArray){
    const returnMap = {};
    classTimeArray.forEach((classTimeObject) => {
        const thisClassDate = new Date(classTimeObject.date);
        const thisClassDay = (thisClassDate.getDay() + 6) % 7 + 1;
        if(!returnMap[thisClassDay]) returnMap[thisClassDay] = [];
        if(returnMap[thisClassDay].indexOf(classTimeObject.time) === -1) 
            returnMap[thisClassDay].push(classTimeObject.time);
    })
    return returnMap;
}
export function containsToday(startDateString, endDateString){
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const currentDate = new Date();
    return startDate.getTime() < currentDate.getTime() && currentDate.getTime() < endDate.getTime();
}
/**
 * 
 * @param {string} comparedDateString 
 * @returns 1 if current date has passed the compared date and -1 if otherwise.
 */
export function compareWithToday(comparedDateString){
    const comparedDate = new Date(comparedDateString);
    const currentDate = new Date();
    return comparedDate.getTime() > currentDate.getTime() ? -1 : 1;
}
export function compareWithTodayWithOffset(comparedDateString, offsetDays){
    const comparedDate = new Date(comparedDateString);
    comparedDate.setDate(comparedDate.getDate() - offsetDays);
    return compareWithToday(comparedDate);
}
export function hasPassedDate(thresholdDateString){
    return compareWithToday(thresholdDateString) === 1;
}
export function dateIsOnDays(dateString, daysArray){
    const date = new Date(dateString);
    const onDay = (date.getDay() + 6) % 7 + 1;
    return daysArray.includes(onDay);
}