import "../style/MinorPaneView.css";

export default function MinorPaneView(props){
    const { children, side, style } = props;
    return (
        <div className="minor-pane-view" style={ style }>
            <div className="minor-pane-view-right">{ side }</div>
            <div className="minor-pane-view-left">{ children }</div>
        </div>
    )
}