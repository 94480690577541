import LeftNav from "./LeftNav";
import "../style/Dashboard.css";

function DashboardFrame(props){
    return (
        <div className="bottom dashboard-bottom">
            <LeftNav selected={ props.selected }></LeftNav>
            <main className={ "dashboard-main " + 
                (props.full ? "dashboard-main-full " : " ") +
                (props.noBottomPadding ? "dashboard-main-full-no-bottom-padding " : " ") +
                (props.columnView ? "dashboard-main-column-view " : " ") }>
                
                { props.children }
            </main>
        </div>
    );
}

export default DashboardFrame;