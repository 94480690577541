import "../style/Signup.css";
import "../style/Dashboard.css";
import DashboardBlock from "../components/DashboardBlock";
import ProgressBar from "../components/ProgressBar";
import { SelectionList, SubjectsSelection, SubmitButton, TextAreaBox, TextBox } from "../components/Forms";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { updateUser } from "../lib/user";

export default function Signup(){

    const gradeOptions = [
        {value: "", displayName: "無"},
        {value: -3, displayName: "小班"},
        {value: -2, displayName: "中班"},
        {value: -1, displayName: "大班"},
        {value: 1, displayName: "一年級"},
        {value: 2, displayName: "二年級"},
        {value: 3, displayName: "三年級"},
        {value: 4, displayName: "四年級"},
        {value: 5, displayName: "五年級"},
        {value: 6, displayName: "六年級"},
        {value: 7, displayName: "七年級"},
        {value: 8, displayName: "八年級"},
        {value: 9, displayName: "九年級"},
        {value: 10, displayName: "高中一年級"},
        {value: 11, displayName: "高中二年級"},
        {value: 12, displayName: "高中三年級"},
    ];

    const topLevelStates = useContext(topLevelStatesContext);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(topLevelStates.user?.accountRegistered){
            window.location.href = "./Dashboard"
        }
    }, [topLevelStates.hasLoaded]);

    const totalStep = 5;
    const [currentStep, setCurrentStep] = useState(searchParams.get("bypass") === "true" ? 2 : 1);

    // This object will be uploaded to the Firestore
    const userData= useRef({
        accountRegistered: true,
        userType: searchParams.get("userType") || "volunteer",
        toJoin: searchParams.get("toJoin") && searchParams.get("toJoin") !== "null" 
            ? searchParams.get("toJoin") : ""
    })
    function updateUserData(key, value){
        userData.current[key] = value;
    }
    function onUserDataTextBoxInput(e, name){
        if(name === "subjects") updateUserData(name, e);
        else updateUserData(name, e.nativeEvent.target.value);
    }

    const [userType, setUserType] = useState(userData.current['userType']);
    function changeSelectedAccountType(newSelectedAccountType){
        searchParams.set("userType", newSelectedAccountType);
        updateUserData("userType", newSelectedAccountType);
        setUserType(newSelectedAccountType)
    }

    function nextStep(){
        if(currentStep === totalStep){
            updateUser(userData.current, null, () => window.location.href = "./Setting?tab=line");           
            return null;
        }
        const mustCompleteInThisStep = mustComplete[currentStep];
        for(const mustCompleteField of mustCompleteInThisStep){
            if(!userData.current[mustCompleteField]){
                topLevelStates.addNotification("請全部填寫完成。")
                return null;   
            }
        }
        setCurrentStep(currentStep + 1);
    }

    const mustComplete = {
        1: ["userType"],
        2: ["name", "sex", "birthMonth"],
        3: ["school", "grade"],
        4: ["lineId", "cellphone"]
    }

    const yoursString = userType === "student" ? "學生" : "你的"; 

    const steps = {

        // Step 1 is to confirm the user account type
        1: <DashboardBlock blockTitle="1. 確認帳號型態">
            <AccountTypeSelectionItem accountType="volunteer" accountTypeDisplay="志工帳號"
                selectedAccountType={ userType }
                changeSelectedAccountType={ changeSelectedAccountType }>
                我是高中生或大學生，想加入志工的行列
            </AccountTypeSelectionItem>
            <AccountTypeSelectionItem accountType="student" accountTypeDisplay="學生帳號"
                selectedAccountType={ userType }
                changeSelectedAccountType={ changeSelectedAccountType }>
                我是需要接受服務的學生或其家長，想尋求課後輔導的機會
            </AccountTypeSelectionItem>
            <AccountTypeSelectionItem accountType="community" accountTypeDisplay="社區/學校 帳號"
                selectedAccountType={ userType }
                changeSelectedAccountType={ changeSelectedAccountType }>
                我的社區或學校需要志工的服務
            </AccountTypeSelectionItem>
        </DashboardBlock>,

        // Step 2 is to obtain the personal information
        2: <DashboardBlock blockTitle="2. 關於你的基本資料"
            blockSubtitle="若您為欲受服務的學生家長，請填寫學生本人的資料">
            <TextBox displayName={ `${yoursString}姓名（例：王大明）` } name="name" key="name"
                onChangeFunction={ onUserDataTextBoxInput } />
            <TextBox displayName={ `${yoursString}性別敘述（例：女）` } name="sex" key="sex"
                onChangeFunction={ onUserDataTextBoxInput } />
            <TextBox placeholder="YYYY-MM" name="birthMonth" key="birthMonth"
                onChangeFunction={ onUserDataTextBoxInput }
                displayName={ `${yoursString}出生年月（例：1995-02）` } />
        </DashboardBlock>,

        // Step 3 is to obtain the user school information
        3: <DashboardBlock blockTitle="3. 關於你的學校"
            blockSubtitle="若您為欲受服務的學生家長，請填寫學生本人的資料；若您非學生，請各填無">
            <TextBox displayName="你的社區/學校" name="school" key="school"
                onChangeFunction={ onUserDataTextBoxInput } />
            <SelectionList options={ gradeOptions } displayName="你的年級" name="grade" key="grade"
                onChangeFunction={ onUserDataTextBoxInput } />
        </DashboardBlock>,

        // Step 4 is to obtain the contact information
        4: <DashboardBlock blockTitle="4. 你的聯絡方式"
            blockSubtitle="若您為欲受服務的學生家長，請填寫您（家長）本人的資料">
            <TextBox displayName="你的手機" name="cellphone" key="cellphone"
                onChangeFunction={ onUserDataTextBoxInput } />
            <TextBox displayName="你的 LINE ID （無請填無）" name="lineId" key="lineId"
                onChangeFunction={ onUserDataTextBoxInput } />
        </DashboardBlock>,

        // Step 5 is to obtain the user's need/goal for
        // using the Infinitas volunteering service
        5: <DashboardBlock blockTitle="5. 最後一步了！"
            blockSubtitle="若您為欲受服務的學生家長，請填寫學生本人的資料">
            
            { userType === "student" ? <>
                <SubjectsSelection showTitle availableSubjects={ ["english", "math", 
                    "assistance", "zhuyin", "chinese_composition", "science"] }
                    onChangeFunction={ subjects => onUserDataTextBoxInput(subjects, "subjects") } />
                <TextAreaBox displayName="學生狀況描述" name="note" key="note" 
                    onChangeFunction={ onUserDataTextBoxInput } />
                <TextBox displayName="輔導內容需求/備註" name="need" key="need" 
                    onChangeFunction={ onUserDataTextBoxInput } />
            </> : <></> }

            { userType === "volunteer" ? <>
                <TextAreaBox displayName="個人自我介紹" name="note" key="note" 
                    onChangeFunction={ onUserDataTextBoxInput } />
                <TextBox displayName="最喜歡的科目" name="subject" key="subject" 
                    onChangeFunction={ onUserDataTextBoxInput } />
                <TextBox displayName="活動邀請代碼（已知自己欲參與的志工活動者，請填代碼；若無代碼，則免填）"
                    name="toJoin" key="toJoin" valueSet={ userData.current }
                    onChangeFunction={ onUserDataTextBoxInput } />
            </> : <></> }

            { userType === "community" ? <>
                至此，您的帳號資料都已填寫完成。請按「下一步」按鈕已儲存您的資料，並於儲存後聯絡：<br/><br/> contact@infvolunteer.org <br/><br/>我們將有專人暸解您的需求。
            </> : <></> }

        </DashboardBlock>,
    }

    // Display registration form only after the user info is loaded from
    // Firebase and after we are sure that the account is not registered
    if(!topLevelStates.hasLoaded || topLevelStates.user?.accountRegistered){
        return null;
    }

    return (
        <div className="signup-container">
            
            <DashboardBlock blockTitle="歡迎您的加入 😀"
                blockSubtitle="請根據以下步驟完成帳號註冊。若您已經擁有帳號，請嘗試登入正確帳號。">
                <ProgressBar currentStep={ currentStep } totalStep={ totalStep } />
            </DashboardBlock>

            { steps[currentStep] }

            <SubmitButton condense noSidePadding 
                buttonText={ currentStep === totalStep ? <>&emsp;前往驗證 LINE 帳號&emsp;</> : <>&emsp;下一步&emsp;</> }
                onSubmitFunction={ nextStep } />

        </div>
    );
}

function AccountTypeSelectionItem(props){
    return (
        <div className={ "account-type-selection-item " + 
            (props.selectedAccountType === props.accountType ? "account-type-selection-item-selected " : " " ) }
            onClick={ () => props.changeSelectedAccountType(props.accountType) }>
            <h4 className="account-type-selection-item-title">{ props.accountTypeDisplay }</h4>
            <p className="account-type-selection-item-content">{ props.children }</p>
        </div>
    )
}