import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import CalendarTimeView from "../components/CalendarTimeView";
import { ChipButton } from "../components/ChipButton";
import ClassLink from "../components/ClassLink";
import ClassTimeDays from "../components/ClassTimeDays";
import { CoinDisplay } from "../components/Coin";
import CommunityBanner from "../components/CommunityBanner";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList, SubmitButton } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { Popup, PopupSection } from "../components/Popup";
import { SubjectChip } from "../components/SubjectBlock";
import { TableFrame, TableRow, TableRowFull } from "../components/Table";
import { createClassInActivity, getActivityPublicInfo, getAllStudentsFromCommunity, getClassesInActivity, getStudentsByActivities, getStudentsFromCommunity, getUsersByReferenceIds, getVolunteersByActivities, updateUserByReferenceId } from "../lib/user";
import { chopArray } from "../lib/util";
import "../style/ActivityStudents.css";
import StudentProfile, { CoinDataSplit } from "./StudentProfile";
import { arrayUnion } from "@firebase/firestore";
import { strings } from "../lib/strings";
import { AccountStatusBlock } from "../components/AccountStatusBlock";
import { AccessDeniedBlock } from "../components/AccessDeniedBlock";

export default function ActivityStudents(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [blankState, setBlankState] = useState({});

    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityPublicInfo, setActivityPublicInfo] = useState(null);
    const [activityClasses, setActivityClasses] = useState(null);
    const communityReferenceId = activityPublicInfo?.communityReferenceId;
    useEffect(() => {
        if(activityPublicInfo === null){
            getActivityPublicInfo(actvid, setActivityPublicInfo);
            getClassesInActivity(actvid, setActivityClasses);
        }
    });


    // In the array activityClasses, each class is an object, which can
    // include multiple students. This means that a student can be in 
    // multiple classes. Here we map the classes to the students.
    const studentClassMap = useMemo(() => {
        const returnedMap = {};
        for(const activityClass of activityClasses || []){
            const studentReferenceIds = activityClass.students || [];
            for(const studentReferenceId of studentReferenceIds){
                if(!studentReferenceId) continue;
                if(!returnedMap[studentReferenceId]){
                    returnedMap[studentReferenceId] = [];
                }
                returnedMap[studentReferenceId].push(activityClass); 
            }
        }
        return returnedMap;
    }, [Object.keys(activityClasses || {}).join("")]);
    

    // Loads the students' personal data and save it into the state
    const [studentPersonalDataMap, setStudentPersonalDataMap] = useState(null);
    const [studentsWithActvid, setStudentsWithActvid] = useState([]);
    const referenceIds = Object.keys(studentClassMap);
    const choppedReferenceIds = chopArray(referenceIds, 10);
    useEffect(() => {
        if(activityClasses !== null){
            const newStudentPersonalDataMap = {};
            for(const referenceIdSection of choppedReferenceIds){
                getUsersByReferenceIds(referenceIdSection, (returnedList) => {
                    returnedList.forEach(returnedStudent => {
                        newStudentPersonalDataMap[returnedStudent.referenceId] = returnedStudent;
                    });
                });
            }
            setStudentPersonalDataMap(newStudentPersonalDataMap);
            getStudentsByActivities(actvid, setStudentsWithActvid);
        }
    }, [referenceIds.join("")]);


    // Get the students' data from community 
    const [studentCommunityDataMap, setStudentCommunityDataMap] = useState(null);
    useEffect(() => {
        if(activityPublicInfo !== null){
            getAllStudentsFromCommunity(activityPublicInfo.communityReferenceId, returnedList => {
                const newStudentCommunityDataMap = {};
                returnedList.forEach(returnedStudent => {
                    newStudentCommunityDataMap[returnedStudent.studentReferenceId] = returnedStudent;
                })
                setStudentCommunityDataMap(newStudentCommunityDataMap);
            })
        }
    }, [(activityPublicInfo || {}).communityReferenceId]);

    // Get all the volunteers' personal data who might be
    // associated with this activity and load it into the state
    const [volunteerPersonalDataMap, setVolunteerPersonalDataMap] = useState(null);
    useEffect(() => {
        if(volunteerPersonalDataMap === null){
            getVolunteersByActivities(actvid, returnedList => {
                const newVolunteerPersonalDataMap = {};
                returnedList.forEach(returnedVolunteer => {
                    newVolunteerPersonalDataMap[returnedVolunteer.referenceId] = returnedVolunteer;
                });
                setVolunteerPersonalDataMap(newVolunteerPersonalDataMap);
            });
        }
    })

    const [showingAddStudentPopup, setShowingAddStudentPopup] = useState(false);
    const [showingSelectClassPopup, setShowingSelectClassPopup] = useState(false);
    
    const availableStudentsOptions = useMemo(() => {
        const newAvailableStudentsOptions = [{value: "", displayName: "（無）", default: true}];
        Object.keys(studentCommunityDataMap || {}).forEach(studentReferenceId => {
            referenceIds.includes(studentReferenceId) 
                || newAvailableStudentsOptions.push({
                    value: studentReferenceId,
                    displayName: 
                        `${studentCommunityDataMap[studentReferenceId].name}（${studentReferenceId}）`
                });
        });
        return newAvailableStudentsOptions;
    }, [Object.keys(studentPersonalDataMap || {}).join(""), blankState]);
    
    const addedStudentReferenceId = useRef("");
    function updateAddedStudentReferenceId(e){
        addedStudentReferenceId.current = e.nativeEvent.target.value;
    }
    function addStudent(){
        const referenceId = addedStudentReferenceId.current;
        const newClassObject = {
            students: [referenceId],
        }
        updateUserByReferenceId(referenceId, {
            activities: arrayUnion(actvid)
        });
        createClassInActivity(actvid, "", newClassObject, 
            topLevelStates.addNotification, () => window.location.reload());
    }

    // 選課權限 (adds actvid to his/her activities without creating class)
    const invitedStudentReferenceId = useRef("");
    function updateInvitedStudentReferenceId(e){
        invitedStudentReferenceId.current = e.nativeEvent.target.value;
    }
    function inviteStudent(){
        const referenceId = invitedStudentReferenceId.current;
        updateUserByReferenceId(referenceId, {
            activities: arrayUnion(actvid)
        }, null, () => window.location.reload());
    }

    if(topLevelStates.user?.userType === "community" && topLevelStates.user?.referenceId !== communityReferenceId){
        return <DashboardFrame selected="dashboard">
            <AccessDeniedBlock />
        </DashboardFrame>
    }

    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["admin", "community"] }>

                <CommunityBanner communityReferenceId={ communityReferenceId }
                    width="1900" height="250" style={{ margin: "30px" }}
                    bannerTitle={ activityPublicInfo?.name }
                    bannerSubtitle={ activityPublicInfo?.description } />

                <div className="activity-students-overview" style={{ width: 1880 }}>
                    擁有梯次權限的學生（💜：以往教過該學生的某位志工老師也在本梯次中，學生可以於優先日期選課）
                    <div className="activity-students-overview-names-container">
                        { studentsWithActvid.map((studentData, index) => {
                            const studentName = (studentCommunityDataMap[studentData.referenceId] || {}).name || "非學生";
                            if(studentName === "非學生") console.log(studentData.referenceId);
                            const studentTaughtBy = (studentCommunityDataMap[studentData.referenceId] || {}).taughtBy || [];
                            let hasTaughtBy = false;
                            for(let i = 0; i < studentTaughtBy.length; i++){
                                if(!!(volunteerPersonalDataMap || {})[studentTaughtBy[i]]){
                                    hasTaughtBy = true;
                                    break;
                                }
                            }

                            const classCount = studentClassMap[studentData.referenceId]?.length;
                            return (
                                <div className={ "activity-classes-overview-name " + (!!classCount ? "activity-classes-overview-name-has-class" : "") }
                                    key={ "activity-classes-overview-name-" + index }>

                                    { (hasTaughtBy ? "💜" : "") } { studentName }
                                </div>
                            )
                        }) }
                    </div>
                </div>

                <div className="activity-students-row">
                    <ChipButton svgName="add" buttonText="新增梯次學生" 
                        onClick={ () => setShowingAddStudentPopup(true) } />
                    <ChipButton svgName="community" buttonText="社區學生資料"
                        link={ "/CommunityStudents?communityReferenceId=" + activityPublicInfo?.communityReferenceId } />
                    <ChipButton svgName="share-go" buttonText="顯示選課資訊"
                        onClick={ () => setShowingSelectClassPopup(true) } />
                    <ChipButton svgName="course" buttonText="教學稽核頁面"
                        link={ "/ClassCheck?actvid=" + actvid } />
                    <AccountTypeAccess accessFor={ ["admin"] }>
                        <ChipButton svgName="list" buttonText="配對與課程設定"
                            link={ "/ActivityClasses?actvid=" + actvid } />
                        <ChipButton svgName="setting" buttonText="活動梯次設定"
                            link={ "/ActivitySetting?actvid=" + actvid } />
                        <ChipButton svgName="message" buttonText="活動梯次訊息"
                            link={ "/ActivityMessaging?actvid=" + actvid } />
                    </AccountTypeAccess>
                </div>

                <Popup closePopupFunction={ setShowingSelectClassPopup }
                    showing={ showingSelectClassPopup } >
                    <PopupSection sectionTitle="選課資訊" sectionSubtitle="每個梯次活動開始前的幾個星期，家長可以開始選課（時間設定視人事幹部所訂定）。">
                        <IconTextPair svgName="time" wrap>優先選課日期（限已教過的老師）：{ (activityPublicInfo || {}).parentTaughtBySelectionStart || "本梯次非家長選課制" }</IconTextPair>
                        <IconTextPair svgName="time" wrap>開放選課日期（可選擇所有老師）：{ (activityPublicInfo || {}).parentAllSelectionStart || "本梯次非家長選課制" }</IconTextPair>
                        <IconTextPair svgName="link" wrap>本梯次的選課連結： https://infvolunteer.org/SelectClass?actvid={ actvid }</IconTextPair>
                    </PopupSection>
                </Popup>

                <Popup closePopupFunction={ setShowingAddStudentPopup } 
                    showing={ showingAddStudentPopup } thin tall >
                    <PopupSection sectionTitle="為現有的社區學生新增一堂課">
                        <SelectionList displayName="選擇新增的學生"
                            options={ availableStudentsOptions }
                            onChangeFunction={ updateAddedStudentReferenceId } />
                        <SubmitButton buttonText="新增至梯次" condense
                            onSubmitFunction={ addStudent } />
                        <SubmitButton buttonText="重新讀取學生列表" condense
                            onSubmitFunction={ () => setBlankState({}) } />
                    </PopupSection>
                    <PopupSection sectionTitle="將現有的社區學生加入可選課名單中">
                        <SelectionList displayName="選擇新增的學生"
                            options={ availableStudentsOptions }
                            onChangeFunction={ updateInvitedStudentReferenceId } />
                        <SubmitButton buttonText="使其可選課" condense
                            onSubmitFunction={ inviteStudent } />
                        <SubmitButton buttonText="重新讀取學生列表" condense
                            onSubmitFunction={ () => setBlankState({}) } />
                    </PopupSection>
                    <PopupSection sectionTitle="新增新的學生" 
                        sectionSubtitle="若社區的學生系統中還沒有欲新增至梯次的學生資料，請至社區學生系統中新增。">
                        <Link to={ "/CommunityStudents?communityReferenceId=" + activityPublicInfo?.communityReferenceId }>
                            <SubmitButton buttonText="前往新增" condense />
                        </Link>
                    </PopupSection>
                </Popup>

                <TableFrame noScroll width="1900" rowHeight="auto" columns={ [ 
                    {content: "#", width: 70}, {content: "受服務學生", width: 150},
                    {content: "金幣", width: 90},{content: "老師姓名", width: 150}, 
                    {content: "本月上課時間", width: 380}, {content: "上課連結", width: 200},
                    {content: "上課科目", width: 400}, {content: "家長帳號", width: 200},  ] }>

                    { Object.keys(studentClassMap).map((studentReferenceId, index) => {

                        const selected = topLevelStates.selectedUser === studentReferenceId;
                        return <>
                            <StudentRow key={ "activity-students-student-row-" + index } actvid={ actvid }
                                index={ index } studentClasses={ (studentClassMap || {})[studentReferenceId] }
                                studentPersonalData={ (studentPersonalDataMap || {})[studentReferenceId] } 
                                studentCommunityData={ (studentCommunityDataMap || {})[studentReferenceId] }
                                volunteerPersonalDataMap={ volunteerPersonalDataMap } />
                            { !selected ? null
                                : <StudentRowExpanded studentClasses={ (studentClassMap || {})[studentReferenceId] }
                                    studentPersonalData={ (studentPersonalDataMap || {})[studentReferenceId] } 
                                    studentCommunityData={ (studentCommunityDataMap || {})[studentReferenceId] }
                                    volunteerPersonalDataMap={ volunteerPersonalDataMap }
                                    activityPublicInfo={ activityPublicInfo } /> }
                        </>
                    }) }
                    
                </TableFrame>
                <br/>
                <br/>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function StudentRow(props){
    const topLevelStates = useContext(topLevelStatesContext);
    
    const studentPersonalData = props.studentPersonalData || {};
    const studentCommunityData = props.studentCommunityData || {};
    const volunteerPersonalDataMap = props.volunteerPersonalDataMap || {};
    const studentClasses = props.studentClasses || [];
    const index = props.index;

    console.log(studentCommunityData.name);
    
    const compositeBlocks = useMemo(() => {
        const newCompositeBlocks = {};

        newCompositeBlocks.volunteerName = [];
        for(const studentClass of studentClasses){
            newCompositeBlocks.volunteerName.push(
                <div className="activity-students-student-row-block-reduced">
                    { volunteerPersonalDataMap[studentClass.volunteerReferenceId]?.name }
                </div>
            )
        }

        newCompositeBlocks.classLink = [];
        for(const studentClass of studentClasses){
            newCompositeBlocks.classLink.push(
                <ClassLink reduced classLink={ studentClass.classLink } />
            )
        }

        newCompositeBlocks.subjects = [];
        for(const studentClass of studentClasses){
            const subjectChips = [];
            for(const subject of studentClass.subjects || []){
                subjectChips.push(<SubjectChip subject={ subject } 
                    style={{ display: 'inline-flex' }} />)
            }
            newCompositeBlocks.subjects.push(
                <div className="activity-students-student-row-block-reduced
                    activity-students-student-row-block-left">
                    { subjectChips }
                </div>
            )
        }

        newCompositeBlocks.classTime = [];
        for(const studentClass of studentClasses){
            newCompositeBlocks.classTime.push(
                <ClassTimeDays classTime={ studentClass.classTime } />
            )
        }

        return newCompositeBlocks;
    });

    function rowClicked(){
        if(topLevelStates.selectedUser !== studentPersonalData.referenceId){
            topLevelStates.selectUser(studentPersonalData.referenceId);
        }else{
            topLevelStates.selectUser(null);
        }
    }
    
    return (
        <TableRow className="activity-students-student-row"
            selected={ topLevelStates.selectedUser === studentPersonalData.referenceId }
            onClick={ rowClicked } content={ [

            <div className="activity-students-student-row-block">
                { index + 1 }
            </div>,

            <div className="activity-students-student-row-block">
                { studentCommunityData.name }
            </div>,

            <CoinDisplay fontSize="20" 
                coins={ studentCommunityData.coins } />,

            <div className="activity-students-student-row-block">
                { compositeBlocks.volunteerName }
            </div>,

            <div className="activity-students-student-row-block">
                { compositeBlocks.classTime }
            </div>,

            <div className="activity-students-student-row-block">
                { compositeBlocks.classLink }
            </div>,

            <div className="activity-students-student-row-block">
                { compositeBlocks.subjects }
            </div>,

            <div className="activity-students-student-row-block">
                <AccountStatusBlock studentReferenceId={ studentCommunityData.studentReferenceId } 
                    showLink studentPersonalData={ studentPersonalData } actvid={ props.actvid } />
            </div>,



        ] } />
    )
}

function StudentRowExpanded(props){

    const studentPersonalData = props.studentPersonalData || {};
    const studentCommunityData = props.studentCommunityData || {};
    const volunteerPersonalDataMap = props.volunteerPersonalDataMap || {};
    const studentClasses = props.studentClasses || [];
    const activityPublicInfo = props.activityPublicInfo || {};


    return (
        <TableRowFull>
            <div className="student-row-expanded">
                { studentClasses.map((studentClass, index) => {
                    return <div className="student-row-expanded-block" key={ "student-row-expanded-block-class-time-" + index }>
                        <div className="student-row-expanded-block-title">
                            { volunteerPersonalDataMap[studentClass.volunteerReferenceId]?.name }（{ studentClass.volunteerReferenceId }）
                        </div>
                        <CalendarTimeView classTime={ studentClass.classTime } />
                    </div>
                }) }
                <div className="student-row-expanded-block student-row-expanded-block-info">
                    <div className="student-row-expanded-block-title">
                        { studentCommunityData.name }（{ studentPersonalData.sex }）<br/>
                        （{ studentPersonalData.referenceId }）
                    </div><br/>
                    <CoinDataSplit communityStudentData={ studentCommunityData }
                        studentPersonalData={ studentPersonalData } />
                    <Link to={ "/StudentProfile?communityReferenceId=" + activityPublicInfo.communityReferenceId + "&studentReferenceId=" + studentPersonalData.referenceId }>
                        <SubmitButton center buttonText="前往教學紀錄" />
                    </Link>
                </div>
                <div>
                    { studentClasses.map((studentClass, index) => {
                        const thisVolunteerPersonalData = volunteerPersonalDataMap[studentClass.volunteerReferenceId] || {};
                        return <div className="student-row-expanded-block student-row-expanded-block-vertical">
                            <div className="student-row-expanded-block-title">
                                { thisVolunteerPersonalData.name }
                            </div>
                            <IconTextPair svgName="age">{ strings[thisVolunteerPersonalData.grade] }</IconTextPair>
                            <IconTextPair svgName="school">{ thisVolunteerPersonalData.school }</IconTextPair>
                            <IconTextPair svgName="information">{ thisVolunteerPersonalData.note || "無自我介紹" }</IconTextPair>
                            <IconTextPair svgName="link" wrap>{ studentClass.classLink }</IconTextPair>
                            <br/>
                        </div>
                    })}
                </div>
            </div>
        </TableRowFull>
    )
}