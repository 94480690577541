import '../style/SignInButton.css';

function GoogleSignInButton(props){
    return (
        <button type="button" className="sign-in-button google-sign-in-button" 
        onClick={ () => {props.signInFunction(props.userType) } }>
            使用 Google 登入
        </button>
    )
}

function FacebookSignInButton(props){
    return (
        <button type="button" className="sign-in-button facebook-sign-in-button" 
        onClick={ () => {props.signInFunction(props.userType) } }>
            使用 Facebook 登入
        </button>
    )
}

export { GoogleSignInButton, FacebookSignInButton };