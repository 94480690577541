import { useRef } from "react";
import { daysInMonth, getClassTimeInThisMonth, getDayTimeMapFromClassTime } from "../lib/dates";
import "../style/ClassTimeDays.css";

export default function ClassTimeDays(props){
    const classTime = 
        !props.allMonths ? getClassTimeInThisMonth(props.classTime || [])
        : props.classTime || [];
    const dayTimeMap = getDayTimeMapFromClassTime(classTime);

    const icoDir = require.context("../ico/");
    const contentContainerRef = useRef()

    function scrollContent(direction){
        contentContainerRef.current.scrollLeft += 100 * direction;
    }

    return (
        <div className="class-time-days" style={ props.height ? {height: props.height + "px"} : null } 
            onClick={ (e) => {
                if(props.stopProp) e.stopPropagation();
            } }>
            <div className="class-time-days-button-container"
                onClick={ () => scrollContent(-1) }>
                <div className="class-time-days-button"
                    style={{ backgroundImage: `url(${icoDir("./arrow_left_uni.svg")})` }}></div>
            </div>
            <div className="class-time-days-content" ref={ contentContainerRef }>
                { (Object.keys(dayTimeMap) || []).map((dayIndex, index) => {
                    return dayTimeMap[dayIndex].map((time, index2) => {
                        return <DayTime day={ dayIndex } time={ time } key={ "day-time-view-" + index + index2 } />
                    })
                }).flat() }
            </div>
            <div className="class-time-days-button-container"
                onClick={ () => scrollContent(1) }>
                <div className="class-time-days-button"
                    style={{ backgroundImage: `url(${icoDir("./arrow_right_uni.svg")})` }}></div>
            </div>
        </div>
    )
}

function DayTime(props){
    const { day, time } = props;
    const dayStrings = {1: "一", 2: "二", 3: "三", 4: "四", 5: "五", 6: "六", 7: "日"};

    return (
        <div className="day-time-wrap">
            <div className="day-time-wrap-day">{ dayStrings[day] }</div>
            <div className="day-time-wrap-time">{ time }</div>
        </div>
    )
}