export const strings = {
    math: "數學", 
    english: "英文",
    science: <>自然<br />科學</>,
    language: <>語言<br />表達</>,
    world: <>國際<br />議題</>,
    culture: <>世界<br />文化</>,
    chinese_composition: <>中文<br />寫作</>,
    assistance: <>課業<br />輔導</>,
    zhuyin: <>國語<br />注音</>,
    
    mathPure: "數學",
    englishPure: "英文",
    sciencePure: "自然科學",
    languagePure: "語言表達",
    worldPure: "國際議題",
    culturePure: "世界文化",
    chinese_compositionPure: "中文寫作",
    assistancePure: "課業輔導",
    zhuyinPure: "國語注音",

    "-3": "小班",
    "-2": "中班",
    "-1": "大班",
    "1": "一年級",
    "2": "二年級",
    "3": "三年級",
    "4": "四年級",
    "5": "五年級",
    "6": "六年級",
    "7": "七年級",
    "8": "八年級",
    "9": "九年級",
    "10": "高中一年級",
    "11": "高中二年級",
    "12": "高中三年級",
}