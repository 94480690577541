import "../style/ProgressBar.css";

export default function ProgressBar(props){

    const progressPercentage = 
        (props.currentStep / props.totalStep * 100).toString() + "%";

    return (
        <div className="progress-bar-container">
            <div className="progress-bar-left">
                <div className="progress-bar-left-content"
                    style={{ width: progressPercentage }}></div>
            </div>
            <div className="progress-bar-right">{ props.currentStep } / { props.totalStep }</div>
        </div>
    )

}