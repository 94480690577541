import { useContext, useRef, useState } from "react";
import { topLevelStatesContext } from "../App";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { DateBox, SubmitButton, TextAreaBox, TextBox } from "../components/Forms";
import { compareWithTodayWithOffset, hasPassedDate } from "../lib/dates";
import "../style/AddLeave.css";

export default function(props){
    return (
        <DashboardFrame selected="dashboard">
            <AddLeaveSnipset />
        </DashboardFrame>
    )
}

export function AddLeaveSnipset(){
    const topLevelStates = useContext(topLevelStatesContext);
    const currentActivitiesClasses = topLevelStates.currentActivitiesClasses || {};
    const activitiesPublicInfo = topLevelStates.activitiesPublicInfo || [];

    const allClassTime = [];
    Object.keys(currentActivitiesClasses).forEach(actvid => {
        (currentActivitiesClasses[actvid].flat() || []).forEach(classObject => {
            (classObject.classTime || []).forEach(classTimeObject => {
                // 課堂三天前即需請假
                if(compareWithTodayWithOffset(classTimeObject.date, 3) === 1) return;
                const modClassTimeObject = Object.assign({}, classTimeObject);
                modClassTimeObject.actvid = actvid;
                modClassTimeObject.classId = classObject.classId;
                allClassTime.push(modClassTimeObject);
            });
        });
    });

    const [selectedClass, setSelectedClass] = useState({
        date: "",
        time: "",
        classId: "",
        actvid: ""
    });
    function classItemClicked(date, time, classId, actvid){
        setSelectedClass({
            date: date,
            time: time,
            classId: classId,
            actvid: actvid
        });
    }
    


    return (
        <>
            <DashboardBlock blockTitle="1. 選擇欲請假的課堂">
                <div className="class-time-list-items-container">
                    { allClassTime.map((classTimeObject, index) => {
                        const selected = classTimeObject.date === selectedClass.date && classTimeObject.time === selectedClass.time && classTimeObject.actvid === selectedClass.actvid && classTimeObject.classId === selectedClass.classId;
                        return <div className={ "class-time-list-item " + (selected ? "class-time-list-item-selected " : "")}
                            onClick={ () => classItemClicked(classTimeObject.date, classTimeObject.time, classTimeObject.classId, classTimeObject.actvid) }>
                            <div className="class-time-list-item-title">{ classTimeObject.date.replace(/-/g, "/") }&nbsp;&nbsp;{ classTimeObject.time }</div>
                            <div className="class-time-list-item-description">
                                { (activitiesPublicInfo.find(activity => activity.actvid === classTimeObject.actvid) || {}).name }
                            </div>
                        </div>
                    }) }
                </div>
            </DashboardBlock>
            { selectedClass.date
                ? <DashboardBlock blockTitle="2. 填寫請假理由">
                    <TextAreaBox displayName="請假事由" />
                    <SubmitButton buttonText="確認送出請假" />
                </DashboardBlock>
            : null}
        </>
    )

    console.log(currentActivitiesClasses);
}