/**
 * Given an input array, chop it into arrays of the same size
 * and then returns an array that contains these arrays
 * @param {array} inputArray 
 * @param {number} size
 */
export function chopArray(inputArray, size){
    const returnedList = [];
    for(let i = 0; i < inputArray.length; i++){
        if(i % size === 0) returnedList.push([]);
        returnedList[returnedList.length - 1].push(inputArray[i]);
    }
    return returnedList;
}