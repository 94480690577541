import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "../style/TopNavTabs.css";

export default function TopNavTabs(props){
    const [searchParams, setSearchParams] = useSearchParams();

    const tabParam = searchParams.get("tab");
    let originalSelectedTab = 0;

    useLayoutEffect(() => {
        if(parseInt(tabParam)) originalSelectedTab = parseInt(tabParam);
        else{
            (props.tabs || []).forEach((tab, index) => {
                if(tab.name === tabParam) originalSelectedTab = index;
            });
        }
        if(originalSelectedTab !== selected) setSelected(originalSelectedTab);
    }, [props.tabs.length, tabParam]);

    const [selected, setSelected] = useState(originalSelectedTab || 0);
    function updateSelectedTab(tabIndex){
        setSelected(tabIndex);
        const newSearchParamsObject = {};
        for(const [key, value] of searchParams.entries()){
            newSearchParamsObject[key] = value;
        }
        newSearchParamsObject.tab = props.tabs[tabIndex].name ? props.tabs[tabIndex].name : tabIndex;
        setSearchParams(newSearchParamsObject);
    }

    const displaySelected = useMemo(() => selected, [selected]);

    return (
        <div className="top-nav-tabs-frame">
            <div className="top-nav-tabs-top">
                <p className="top-nav-tabs-subtitle">{ props.pageSubtitle }</p>
                <h1 className="top-nav-tabs-title">{ props.pageTitle }</h1>
                <div className="top-nav-tabs-container">

                    {(props.tabs || []).map((tab, index) => {
                        const thisTabSelected = selected === index;
                        return <TopNavTabButton key={ "top-nav-tab-button-" + index }
                            buttonText={ tab.displayName } selected={ thisTabSelected }
                            onClick={ updateSelectedTab } tabIndex={ index } />
                    })}

                </div>
            </div>
            <div className="top-nav-tabs-content">
                { props.tabs[displaySelected]?.content }
            </div>
        </div>
    )
}

function TopNavTabButton(props){
    return (
        <div className={ "top-nav-tab-button " +
            (props.selected ? "top-nav-tab-button-selected " : " " ) }
            onClick={ () => props.onClick(props.tabIndex) }>
            { props.buttonText }
        </div>
    )
}