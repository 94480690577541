import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList, SubjectsSelection, SubmitButton, TextAreaBox, TextBox } from "../components/Forms";
import TopNavTabs from "../components/TopNavTabs";
import { updateUser } from "../lib/user";
import "../style/Setting.css";

export default function Setting(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const userData = topLevelStates.user;

    const tabs = [
        {
            name: "personal",
            displayName: "個人資料", 
            content: <PersonalInformationSetting
                userData={ userData } />
        }
    ];
    if(topLevelStates.user?.userType === "student"){
        tabs.push(
            {
                name: "student",
                displayName: "學生帳號資料", 
                content: <StudentInformation
                    userData={ userData } />
            },
        )
    }
    tabs.push(
        {
            name: "line",
            displayName: "LINE 驗證", 
            content: <LineVerification />
        },
        {
            name: "more",
            displayName: "更多連結", 
            content: <MoreLinks />
        },
        {
            name: "error",
            displayName: "錯誤排解", 
            content: <ErrorHandling />
        }
    )

    return (
        <DashboardFrame selected="setting" full>
            <TopNavTabs pageTitle="個資與聯絡設定" pageSubtitle={ "使用者ID: " + topLevelStates?.user?.referenceId }
                tabs={ tabs } />
        </DashboardFrame>
    )
}

function PersonalInformationSetting(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const userData = props.userData || {};
    const navigate = useNavigate()

    const gradeOptions = [
        {value: "", displayName: "未選擇"},
        {value: -3, displayName: "小班"},
        {value: -2, displayName: "中班"},
        {value: -1, displayName: "大班"},
        {value: 1, displayName: "一年級"},
        {value: 2, displayName: "二年級"},
        {value: 3, displayName: "三年級"},
        {value: 4, displayName: "四年級"},
        {value: 5, displayName: "五年級"},
        {value: 6, displayName: "六年級"},
        {value: 7, displayName: "七年級"},
        {value: 8, displayName: "八年級"},
        {value: 9, displayName: "九年級"},
        {value: 10, displayName: "高中一年級"},
        {value: 11, displayName: "高中二年級"},
        {value: 12, displayName: "高中三年級"},
    ];

    const newInfoData = useRef({});
    function updateInfoData(e, name){
        newInfoData.current[name] = e.nativeEvent.target.value;
    }
    function saveInfoData(){
        updateUser(newInfoData.current, topLevelStates.addNotification,
            () => navigate(0));
    }

    const newContactData = useRef({});
    function updateContactData(e, name){
        newContactData.current[name] = e.nativeEvent.target.value;
    }
    function saveContactData(){
        updateUser(newContactData.current, topLevelStates.addNotification,
            () => navigate(0));
    }

    return (
        <>
            <DashboardBlock blockTitle="關於你">
                <TextBox displayName="真實姓名" name="name" valueSet={ userData }
                    onChangeFunction={ updateInfoData } />
                <TextBox displayName="性別敘述" name="sex" valueSet={ userData }
                    onChangeFunction={ updateInfoData } />
                <SelectionList displayName="目前年級" options={ gradeOptions }
                     name="grade" valueSet={ userData }
                     onChangeFunction={ updateInfoData } />
                <TextBox displayName="就讀學校" name="school" valueSet={ userData }
                    onChangeFunction={ updateInfoData } />
                <TextAreaBox displayName="簡單自我介紹" name="note" valueSet={ userData }
                    placeholder="若為受服務學生，可填寫個人特殊狀況。"
                    onChangeFunction={ updateInfoData } />
                <SubmitButton buttonText="儲存資料" onSubmitFunction={ saveInfoData } />
            </DashboardBlock>
            <DashboardBlock blockTitle="聯絡資料">
                <TextBox displayName="LINE ID" name="lineId" valueSet={ userData }
                    onChangeFunction={ updateContactData } />
                <TextBox displayName="手機號碼" name="cellphone" valueSet={ userData }
                    onChangeFunction={ updateContactData } />
                <TextBox displayName="聯絡 Email" name="userEmail" valueSet={ userData }
                    onChangeFunction={ updateContactData } />
                <SubmitButton buttonText="儲存資料" onSubmitFunction={ saveContactData } />
            </DashboardBlock>
        </>
    )
}

function StudentInformation(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const userData = props.userData || {};
    const navigate = useNavigate();

    const newServiceData = useRef({});
    function updateServiceData(e, name){
        if(name === "subjects") newServiceData.current[name] = e;
        else newServiceData.current[name] = e.nativeEvent.target.value;
    }
    function saveServiceData(){
        updateUser(newServiceData.current, topLevelStates.addNotification,
            () => navigate(0));
    }

    const newParentContactData = useRef(JSON.parse(JSON.stringify(userData.parents || [])));
    function updateParentContactData(e, name, index){
        let updateIndex = index;
        if(updateIndex >= newParentContactData.current.length) newParentContactData.current.push({});
        if(index === 1 && newParentContactData.current.length === 1) updateIndex -= 1;
        newParentContactData.current[updateIndex][name] = e.nativeEvent.target.value;
    }
    function saveParentContactData(){
        updateUser({parents: newParentContactData.current}, topLevelStates.addNotification,
            () => navigate(0));
    }

    return (
        <>
            <DashboardBlock blockTitle="學生服務需求"
                blockSubtitle="選擇學生最需要的服務科目，並可簡述需求服務內容。">
                <SubjectsSelection selectedSubjects={ userData.subjects } showTitle="true"
                    onChangeFunction={ (newSubjects => updateServiceData(newSubjects, "subjects")) } />
                <TextBox displayName="需求服務內容" name="need" valueSet={ userData }
                    onChangeFunction={ updateServiceData } />
                <SubmitButton buttonText="儲存需求內容" 
                    onSubmitFunction={ saveServiceData } />
            </DashboardBlock>
            <DashboardBlock blockTitle="其他聯絡方式"
                blockSubtitle="此部分的資料非必填，填寫可方便必要時聯絡使用。">
                <TextBox displayName="聯絡人 1 姓名" name="name"
                    valueSet={ userData.parents?.length > 0 && userData.parents[0] }
                    onChangeFunction={ (e, name) => updateParentContactData(e, name, 0) } />
                <TextBox displayName="聯絡人 1 手機" name="cellphone"
                    valueSet={ userData.parents?.length > 0 && userData.parents[0] }
                    onChangeFunction={ (e, name) => updateParentContactData(e, name, 0) } />
                <TextBox displayName="聯絡人 2 姓名" name="name"
                    valueSet={ userData.parents?.length > 1 && userData.parents[1] }
                    onChangeFunction={ (e, name) => updateParentContactData(e, name, 1) } />
                <TextBox displayName="聯絡人 2 手機" name="cellphone"
                    valueSet={ userData.parents?.length > 1 && userData.parents[1] }
                    onChangeFunction={ (e, name) => updateParentContactData(e, name, 1) } />
                <SubmitButton buttonText="儲存聯絡方式"
                    onSubmitFunction={ saveParentContactData } />
            </DashboardBlock>
        </>
    )
}

function LineVerification(props){
    const imgDir = require.context("../img/");
    const topLevelStates = useContext(topLevelStatesContext);

    const lineVerified = !!topLevelStates?.user?.lineUserId?.length;
    function copyVerificationMessage(){
        navigator.clipboard.writeText("!vfa: " + topLevelStates.firebaseUser.uid );
        topLevelStates.addNotification("已複製至剪貼簿");
    }

    return (
        <DashboardBlock blockTitle="驗證並連結 LINE 帳號" 
            blockSubtitle="在志工服務的過程中，任何資訊（如請假等）皆會由 Infinitas 的機器人與您聯繫。請先用手機掃描以下 QR Code 將機器人加為好友，並依照以下指令傳送訊息。">

            { lineVerified
                ? <p className="line-verification-steps">
                    ✅&nbsp;&nbsp;已完成連結 { topLevelStates.user.lineUserId.length } 支帳號。
                </p>
                : <p className="line-verification-steps">
                    🔴&nbsp;&nbsp;尚未完成「驗證並連結 LINE 帳號」
                </p> }

            <img className="line-verification-qr-code" src={ imgDir("./line-add-friend.png") } />
            <p className="line-verification-steps">
                1. 掃描 QR Code 或者點擊<a 
                    href="https://line.me/R/ti/p/%40985hbxpn" 
                    target="_blank" style={{ color: "var(--emphasis-color)",
                    textDecoration: "underline" }}>此連結</a>以加入 <br/>
                &emsp;機器人為 LINE 好友。
            </p>
            <p className="line-verification-steps">
                2. 向機器人傳送下列訊息（<b onClick={ copyVerificationMessage }>點此複製</b>）<br />
                <i>!vfa: { topLevelStates.firebaseUser.uid }</i>
            </p>
            <p className="line-verification-steps">
                3. 完成請等待二至五分鐘，接著請重新載入此頁，<br />
                &emsp;若未顯示完成，請嘗試重新傳送一次。
            </p>
        </DashboardBlock>
    )
}

function MoreLinks(props){
    return (
        <DashboardBlock blockTitle="相關資源">

        </DashboardBlock>
    )
}

function ErrorHandling(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const topLevelStatesSring = JSON.stringify(topLevelStates);

    return (
        <DashboardBlock blockTitle="錯誤排解"
            blockSubtitle="遇上平台錯誤時，可以將此畫面截圖回傳 Infinitas 幹部。">

            <div style={{ wordWrap: 'break-word', wordBreak: 'keep-all' }}>
                { topLevelStatesSring }
            </div>
        </DashboardBlock>
    )
}