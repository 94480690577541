import { useContext, useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { arrayUnion } from "@firebase/firestore";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { DateBox, SelectionList, SubjectsSelection, SubmitButton, SubmitButtonCenter, TextAreaBox, TextBox } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { TableFrame, TableRow } from "../components/Table";
import { getActivityPublicInfo, getUsersByToJoin, getVolunteersByActvid, updateActivityPublicInfoByActvid, updateUser, updateUserByReferenceId } from "../lib/user";
import "../style/ActivitySetting.css";

export default function ActivitySetting(props){

    const topLevelStates = useContext(topLevelStatesContext);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityPublicData, setActivityPublicData] = useState(null);
    const [activityPendingVolunteers, setActivityPendingVolunteers] = useState(null);
    const [activityActiveVolunteers, setActivityActiveVolunteers] = useState(null);

    const newActivityPublicData = Object.assign({}, activityPublicData);
    const newActivityActivatedState = {
        activated: activityPublicData?.activated || "planning"
    }
    
    const activitySubjects = newActivityPublicData?.subjects || [];
    const newActivitySubjects = useRef(activitySubjects);
    function activitySubjectsChanged(updatedActivitySubjects){
        newActivitySubjects.current = updatedActivitySubjects;
    }
    function saveActivitySubjects(){
        updateActivityPublicInfoByActvid(actvid, {
            subjects: newActivitySubjects.current
        }, topLevelStates.addNotification, () => window.location.reload());
    }

    useEffect(() => {
        if(activityPublicData === null && !!actvid){
            loadData();
        }
    }, [topLevelStates.hasLoaded]);

    function loadData(){
        getActivityPublicInfo(actvid, setActivityPublicData);
        getUsersByToJoin(actvid, true, setActivityPendingVolunteers);
        getVolunteersByActvid(actvid, setActivityActiveVolunteers);
    }

    function removeToJoinRequest(referenceId){
        updateUserByReferenceId(referenceId, {toJoin: null}, topLevelStates.addNotification, loadData);
    }
    function approveToJoinRequest(referenceId, actvid){
        updateUserByReferenceId(referenceId, {
            actvid: actvid,
            activities: arrayUnion(actvid),
            toJoin: null
        }, topLevelStates.addNotification, loadData);
    }

    function activityPublicDataChanged(e, name){
        newActivityPublicData[name] = e.nativeEvent.target.value;
        console.log(newActivityPublicData);
    }
    function activityActivatedStateChanged(e, name){
        newActivityActivatedState[name] = e.nativeEvent.target.value;
        console.log(newActivityActivatedState);
    }

    function saveActivityPublicData(){
        updateActivityPublicInfoByActvid(actvid, newActivityPublicData, 
            topLevelStates.addNotification, loadData);
    }
    function saveActivityActivatedState(){
        updateActivityPublicInfoByActvid(actvid, newActivityActivatedState, 
            topLevelStates.addNotification, () => window.location.reload());
    }


    const activityActivatedStateOptions = [
        {value: "planning", displayName: "計劃階段", default: true},
        {value: "activated", displayName: "活動進行中"},
        {value: "ended", displayName: "活動已結束"}
    ]

    return (
        <DashboardFrame selected="dashboard" columnView>
            <AccountTypeAccess accessFor={ ["admin"] }>

                <DashboardBlock blockTitle="快速動作">
                    <div className="activity-setting-links-container">
                        <Link style={{ display: 'block' }} to={ "/ActivityStudents?actvid=" + actvid }>
                            <span className="activity-setting-link">學生與上課時間總表</span>
                        </Link>
                        <Link style={{ display: 'block' }} to={ "/Admin" }>
                            <span className="activity-setting-link">社區使用者管理頁面</span>
                        </Link>
                        <Link style={{ display: 'block' }} to={ "/CommunityStudents?communityReferenceId=" + activityPublicData?.communityReferenceId }>
                            <span className="activity-setting-link">社區學生管理頁面</span>
                        </Link>
                        <Link style={{ display: 'block' }} to={ "/" }>
                            <span className="activity-setting-link">此社區使用者頁面</span>
                        </Link>
                    </div>
                    <br/>
                    <div className="activity-setting-quick-action">
                        <div className="activity-setting-quick-action-left-title">分享此梯次的註冊連結</div>
                        <IconTextPair svgName="link" wrap>邀請代碼：{ actvid }</IconTextPair>
                        <IconTextPair svgName="link" wrap>註冊連結（同一行）：<br/>https://infvolunteer.org/Login?toJoin={ actvid }</IconTextPair>
                    </div>

                </DashboardBlock>

                <DashboardBlock blockTitle="活動梯次的基本資料">
                    <TextBox name="name" displayName="本活動梯次名稱"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextAreaBox name="description" 
                        displayName="本活動梯次的敘述"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="communityReferenceId" 
                        displayName="主辦社區的使用者 referenceId"
                        valueSet={ activityPublicData } disabled />
                    <TextBox name="heldByDisplayName" 
                        displayName="主辦社區的名稱"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="classPerWeek" 
                        displayName="每週上課的次數（請填數字，如：2）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="hourPerClass" 
                        displayName="每次上課的小時數（請填數字，如：1）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="parentSelectionLimit" 
                        displayName="家長選課數量上限（請填數字，如：1，預設為1）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="studentAgeDescription" 
                        displayName="學生年齡敘述"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="workingDescription" 
                        displayName="志工教學內容敘述"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="workingMaterialLink" 
                        displayName="服務內容說明書連結"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <TextBox name="volunteerClassTimeLimit" 
                        displayName="志工梯次堂數限制（請填數字，如：1，若無限制請填10000；預設為無限制）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <DateBox name="parentTaughtBySelectionStart" 
                        displayName="家長開始選課時間（教過的老師）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <DateBox name="parentAllSelectionStart" 
                        displayName="家長開始選課時間（所有的老師）"
                        valueSet={ activityPublicData }
                        onChangeFunction={ activityPublicDataChanged } />
                    <DateBox name="startTime" valueSet={ activityPublicData } 
                        displayName="服務開始日期"
                        onChangeFunction={ activityPublicDataChanged } />
                    <DateBox name="endTime" valueSet={ activityPublicData } 
                        displayName="服務結束日期"
                        onChangeFunction={ activityPublicDataChanged } />
                    <SubmitButton squeeze buttonText="儲存梯次資料"
                        onSubmitFunction={ saveActivityPublicData } />
                </DashboardBlock>

                <DashboardBlock blockTitle="家長可選擇科目">
                    <SubjectsSelection selectedSubjects={ activitySubjects }
                        onChangeFunction={ activitySubjectsChanged } />
                    <SubmitButton center buttonText="儲存家長可選擇科目"
                        onSubmitFunction={ saveActivitySubjects } />
                </DashboardBlock>

                <DashboardBlock blockTitle={ `申請加入的志工（${(activityPendingVolunteers || []).length}位）` }>
                    <TableFrame condense rowContainerHeight="370" rowHeight="80"
                        columns={ [{content: "等待接受審核的申請者", width: 1000}] }>
                        { (activityPendingVolunteers || []).map((userData, index) => {
                            return <TableRow key={ "pending-user-" + index} content={ [
                                <ListUserBlock userData={ userData } 
                                    approveToJoinRequest={ approveToJoinRequest }
                                    removeToJoinRequest={ removeToJoinRequest } />
                            ] } />
                        })}
                    </TableFrame>
                </DashboardBlock>
                
                <DashboardBlock blockTitle={ `已經加入的志工（${(activityActiveVolunteers || []).length}位）` } >
                    <TableFrame condense rowContainerHeight="370" rowHeight="80"
                        columns={ [{content: "已經在此梯次中的志工", width: 1000}] }>

                        { (activityActiveVolunteers || []).map((userData, index) => {
                            return <TableRow key={ "active-user-" + index } content={ [
                                <ListUserBlock userData={ userData } approved />
                            ] } />
                        })}

                    </TableFrame>
                </DashboardBlock>

                <DashboardBlock blockTitle="梯次運作狀態"
                    blockSubtitle="">
                    
                    <SelectionList name="activated" 
                        displayName="設定梯次運作狀態"
                        valueSet={ newActivityActivatedState }
                        options={ activityActivatedStateOptions }
                        onChangeFunction={ activityActivatedStateChanged } />
                    <SubmitButton condense buttonText="儲存梯次運作狀態"
                        onSubmitFunction={ saveActivityActivatedState } />

                </DashboardBlock>
                
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function ListUserBlock(props){
    const userData = props.userData;

    return(
        <div className="list-user-block">
            <div className="list-user-name">            
                <div className="list-user-name-title">{ userData.name || "未填姓名" }</div>
                <div className="list-user-name-subtitle">{ userData.referenceId }</div>
            </div>
            <div className="list-user-detail">
                <IconTextPair smallText svgName="email">{ userData.userEmail }</IconTextPair>
                <IconTextPair smallText svgName="link">LINE ID: { userData.lineId }</IconTextPair>
            </div>
            <div className="list-user-action">
                <Link to={ "/User?referenceId=" + userData.referenceId } target="_blank">
                    <div className="list-user-action-button-container">
                        <div className="list-user-action-button">使用者頁面</div>
                    </div>
                </Link>
                { !props.approved
                    ? <div className="list-user-action-button-container">
                        <div className="list-user-action-button 
                            list-user-action-button-confirm"
                            onClick={ () => props.approveToJoinRequest(userData.referenceId, userData.toJoin) }>
                            核准
                        </div>
                        <div className="list-user-action-button"
                            onClick={ () => props.removeToJoinRequest(userData.referenceId) } >
                            移除
                        </div>
                    </div> : null
                }
            </div>
        </div>
    )
}