import { tab } from "@testing-library/user-event/dist/tab";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import CommunityBanner from "../components/CommunityBanner";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { SubmitButton, TextAreaBox, TextBox } from "../components/Forms";
import { Popup, PopupSection } from "../components/Popup";
import { TableFrame, TableRow, TableRowFull } from "../components/Table";
import TopNavTabs from "../components/TopNavTabs";
import { sendLINEMessage } from "../lib/chat";
import { getActivityPublicInfo, getAllStudentsFromCommunity } from "../lib/user";
import "../style/ActivityMessaging.css";

export default function ActivityMessaging(props){

    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityPublicInfo, setActivityPublicInfo] = useState(null);
    const [communityStudents, setCommunityStudents] = useState([]);
    useEffect(() => {
        if(activityPublicInfo === null){
            getActivityPublicInfo(actvid, returnedData => {
                setActivityPublicInfo(returnedData);
                getAllStudentsFromCommunity(returnedData.communityReferenceId, setCommunityStudents);
            })
        }
    })

    const tabs = [{
        name: "broadcast", displayName: "所有社區家長", content: 
            <Broadcast communityStudents={ communityStudents } activityPublicInfo={ activityPublicInfo } />
    }]

    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["admin"] }>
                <TopNavTabs tabs={ tabs } pageTitle="梯次訊息頁面" pageSubtitle="Infinitas 學生志工計畫" />
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function Broadcast({activityPublicInfo, communityStudents}){
    const topLevelStates = useContext(topLevelStatesContext);
    const [showingSendBroadcastPopup, setShowingSendBroadcastPopup] = useState(false);

    const messages = useRef({});
    function messageChanged(referenceId, name, content){
        if(!messages.current[referenceId]) messages.current[referenceId] = {
            target: referenceId
        };
        messages.current[referenceId][name] = content;
    }
    function sendMessage(referenceId){
        sendLINEMessage(messages.current[referenceId], topLevelStates.addNotification);
    }

    const studentReferenceIds = useMemo(() => {
        return communityStudents.map(communityStudent => communityStudent.studentReferenceId);
    }, [communityStudents.length]);

    return (
        <>
            <TableFrame columns={ [{content: "學生姓名", width: 150}, {content: "輸入訊息", width: 250}, 
                {content: "傳送密碼", width: 200}, {content: "傳送訊息", width: 150}] }
                width="754" rowHeight="auto" noScroll >

                <TableRowFull>
                    <SubmitButton buttonText="傳送給所有家長" condense center
                        onSubmitFunction={ () => setShowingSendBroadcastPopup(!showingSendBroadcastPopup) } />
                </TableRowFull>

                { communityStudents.map((communityStudent, index) => {
                    return <TableRow key={ `activity-messaging-student-${index}` } content={ [
                        <div className="activity-messaging-student-name">
                            <div className="activity-messaging-student-name-name">
                                { communityStudent.name }
                            </div>
                            <div className="activity-messaging-student-name-referenceId">
                                { communityStudent.studentReferenceId }
                            </div>
                        </div>,
                        <TextAreaBox displayName="輸入欲傳送訊息" name="message"
                            onChangeFunction={ (e, name) => messageChanged(communityStudent.studentReferenceId, name, e.nativeEvent.target.value) } />,
                        <TextBox displayName="輸入傳送密碼" name="pwd"
                            onChangeFunction={ (e, name) => messageChanged(communityStudent.studentReferenceId, name, e.nativeEvent.target.value) } />,
                        <SubmitButton buttonText="確認傳送" center
                            onSubmitFunction={ () => sendMessage(communityStudent.studentReferenceId) } />
                    ] } />
                }) }

            </TableFrame>
            <SendBroadcastPopup studentReferenceIds={ studentReferenceIds }
                showing={ showingSendBroadcastPopup } closePopupFunction={ setShowingSendBroadcastPopup } />
        </>
    )
}

function SendBroadcastPopup({ studentReferenceIds, ...props }){

    const message = useRef({});
    function messageChanged(e, name){
        message.current[name] = e.nativeEvent.target.value;
    }
    function sendMessage(){
        studentReferenceIds.forEach(studentReferenceId => {
            sendLINEMessage(Object.assign({target: studentReferenceId}, message.current));
        })
    }

    return (
        <Popup {...props}>
            <PopupSection sectionTitle={ `傳送給 ${studentReferenceIds.length} 位學生（家長）` }>
                <TextAreaBox displayName="輸入欲傳送訊息" name="message"
                    onChangeFunction={ messageChanged } />
                <TextBox displayName="輸入傳送密碼" name="pwd"
                    onChangeFunction={ messageChanged } />
                <SubmitButton buttonText="確認傳送"
                    onSubmitFunction={ sendMessage } />
            </PopupSection>
        </Popup>
    )
}