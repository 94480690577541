import { useEffect, useMemo, useRef, useState } from "react";
import { daysInMonth, fillYearMonth, getDatesFromDateTime, getDateTimeFromDays, getHourFromTimeString, getTimeStringFromHour } from "../lib/dates";
import makeid from "../lib/makeid";
import { strings } from "../lib/strings";
import "../style/Forms.css";
import CalendarTimeView from "./CalendarTimeView";
import CalendarView from "./CalendarView";
import IconTextPair from "./IconTextPair";
import { Popup, PopupSection } from "./Popup";
import ProgressBar from "./ProgressBar";
import { TableFrame, TableRow } from "./Table";


export function TextBox(props){
    let valueSet = props.valueSet;
    if(!valueSet){
        valueSet = {};
        valueSet[props.name] = "";
    }
    const bareInput = 
        <input 
            className={ "forms-text " + (props.disabled ? "forms-text-disabled " : " ")} 
            defaultValue={ valueSet[props.name] }
            type="text" name={ props.name } placeholder={ props.disabled ? valueSet[props.name] : props.placeholder }
            onChange={(e) => props.onChangeFunction(e, props.name)} readOnly={ props.disabled }
            stlye={ props.style } />
    if(props.bare) return bareInput;
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title">{ props.displayName }</p>
            { bareInput }
        </div>
    );
}

export function TextAreaBox(props){
    let valueSet = props.valueSet;
    if(!valueSet){
        valueSet = {};
        valueSet[props.name] = "";
    }
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title">{ props.displayName }</p>
            <textarea 
                style={{ height: props.height + "px" }}
                className="forms-text forms-textarea" defaultValue={ props.disabled ? null : valueSet[props.name] }
                type="text" name={ props.name } placeholder={ props.disabled ? valueSet[props.name] : props.placeholder }
                onChange={(e) => props.onChangeFunction(e, props.name)} ></textarea>
        </div>
    );
}

export function DateBox(props){
    let valueSet = props.valueSet;
    if(!valueSet){
        valueSet = {};
        valueSet[props.name] = "";
    }
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title"> { props.displayName } </p>
            <input className="forms-text" type="date" defaultValue={ valueSet[props.name] }
                onChange={(e) => props.onChangeFunction(e, props.name)} disabled={ !!props.disabled } /> 
        </div>
    )
}

export function MonthBox(props){
    let valueSet = props.valueSet;
    if(!valueSet){
        valueSet = {};
        valueSet[props.name] = "";
    }
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title"> { props.displayName } </p>
            <input className="forms-text" type="month" defaultValue={ props.disabled ? null : valueSet[props.name] }
                onChange={(e) => props.onChangeFunction(e, props.name)} /> 
        </div>
    )
}

export function TimeBox(props){
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title"> { props.displayName } </p>
            <input className="forms-text" type="time" 
                onChange={(e) => props.onChangeFunction(e, props.name)} /> 
        </div>
    )
}

export function TextBoxWithButton(props){
    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title">{ props.displayName }</p>
            <input className="forms-text" type="text" name={ props.name } onChangeFunction={ props.onChangeFunction }/>
            <button className="forms-text-button">{ props.buttonText }</button>
        </div>
    );
}

export function SubmitButton(props){
    const addOnStyle = {};
    if(props.condense) addOnStyle["marginTop"] = 0;
    if(props.squeeze) addOnStyle["marginTop"] = "8px";
    return (
        <div className={ "forms-item-wrap " + (props.noSidePadding ? "forms-item-wrap-no-side" : "") }
            style={ props.center ? {textAlign: 'center'} : {} }>
            <button className= { "forms-text-button forms-submit-button " + 
                (props.decolorized ? "forms-submit-button-decolorized " : " ") + 
                (props.warning ? "forms-submit-button-warning " : " ") } 
                onClick={ () => {props.onSubmitFunction && props.onSubmitFunction() } }
                style={ addOnStyle }>
                { props.buttonText }
            </button>
        </div>
    );
}

export function SubmitButtonCenter(props){
    return (
        <div className="forms-item-wrap" style={{'padding': '0'}}>
            <button className= { "forms-text-button forms-submit-button " + 
                (props.decolorized ? "forms-submit-button-decolorized " : " ") + 
                (props.warning ? "forms-submit-button-warning " : " ") } 
                onClick={ props.onSubmitFunction }
                style={{margin: '20px auto 0 auto', display: "block"}}>
                { props.buttonText }
            </button>
        </div>
    )
}

export function SelectionList(props){

    const options = props.options || [];
    const [defaultValue, setDefaultValue] = useState(null);
    const hasChanged = useRef(false);

    useEffect(() => {
        if(!hasChanged.current){
            let targetSelectedValue = null;
            if(props.valueSet && props.valueSet[props.name]){
                targetSelectedValue = props.valueSet[props.name];
            } 
            let selectionFound = false;
            options.forEach(option => {
                if(targetSelectedValue === null){
                    if(option.default){
                        selectionFound = true;
                        if(option.value !== defaultValue) setDefaultValue(option.value);
                    }
                }else{
                    if(option.value?.toString() === targetSelectedValue?.toString()){
                        selectionFound = true;
                        if(option.value !== defaultValue) setDefaultValue(option.value);
                    }
                }
            });
            if(!selectionFound){
                if(options[0].value !== defaultValue) setDefaultValue(options[0].value);
            }
        }
    }, [Object.values(props.valueSet || {}).join("")]);

    
    function updateSelection(e){
        hasChanged.current = true;
        setDefaultValue(e.nativeEvent.target.value);
        props.onChangeFunction && props.onChangeFunction(e, props.name)
    }

    const selectionId = useRef(makeid(10));
    const renderSerial = useRef(0);
    renderSerial.current++;

    return (
        <div className="forms-item-wrap">
            <p className="forms-item-title">{ props.displayName }</p>
            <select key={ `forms-selection-${selectionId.current}-${renderSerial.current}` }
                name={ props.name } onChange={ updateSelection }
                readOnly={ props.disabled } className="forms-select" 
                defaultValue={ defaultValue?.toString() }>

                { options.map((option, index) => {
                    return <option className="forms-option"
                        value={ option.value?.toString() } key={ index }>
                        { option.displayName }
                    </option>
                }) }

            </select>
        </div>
    );
}

export function DayAndTimeSelection(props){
    const [dayAndTimeData, setDayAndTimeData] = useState([...props.dataPreset]);
    useEffect(() => {
        setDayAndTimeData([...props.dataPreset]);
    }, [props.dataPreset]);

    function addMonthBlock(){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData.push({
            month: "2024-07",
            day: [],
            startTime: "19:30",
            endTime: "21:30",
            additionalData: "",
        });
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function onMonthChange(blockIndex, e){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData[blockIndex].month = e.nativeEvent.target.value;
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function onStartTimeChange(blockIndex, e){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData[blockIndex].startTime = e.nativeEvent.target.value;
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function onEndTimeChange(blockIndex, e){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData[blockIndex].endTime = e.nativeEvent.target.value;
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function onEditedDataChange(blockIndex, e){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData[blockIndex].additionalData = e.nativeEvent.target.value;
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function onDaysChange(blockIndex, changedDay){
        const newDayAndTimeData = [...dayAndTimeData];
        if(newDayAndTimeData[blockIndex].day.includes(changedDay)){
            newDayAndTimeData[blockIndex].day.splice(newDayAndTimeData[blockIndex].day.indexOf(changedDay), 1);
        }else{
            newDayAndTimeData[blockIndex].day.push(changedDay);
        }
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }
    function removeBlock(blockIndex){
        const newDayAndTimeData = [...dayAndTimeData];
        newDayAndTimeData.splice(blockIndex, 1);
        setDayAndTimeData(newDayAndTimeData);
        props.dataCallback(newDayAndTimeData);
    }

    return (
        <div className="day-and-time-selection-wrap">
            <p className="forms-item-title">{ props.displayName }</p>
            <div className="day-and-time-selection-block-container">
                {
                    dayAndTimeData.map((dayAndTime, index) => {
                        // console.log(dayAndTime);
                        return <DayAndTimeSelectionBlock key={ index } blockIndex={ index } selectedDays={ dayAndTime.day }
                            selectedStartTime={ dayAndTime.startTime } selectedEndTime={ dayAndTime.endTime } 
                            selectedMonth={ dayAndTime.month } editedAdditionalData={ dayAndTime.additionalData }
                            onMonthChange={ onMonthChange } onStartTimeChange={ onStartTimeChange } 
                            onEndTimeChange={ onEndTimeChange } onDaysChange={ onDaysChange } 
                            onEditedDataChange={ onEditedDataChange } removeBlock={ removeBlock } >
                        </DayAndTimeSelectionBlock>
                    })
                }
            </div>
            <div className="day-and-time-selection-block-button"
                onClick={ addMonthBlock }>新增月份
            </div>
        </div>
    );
}

function DayAndTimeSelectionBlock(props){
    // Eventually save: Monday -> 1; Tuesday -> 2; ...
    const dayWords = ["一", "二", "三", "四", "五", "六", "日"];
    const selectedDays = props.selectedDays;
    const blockIndex = props.blockIndex;
    return (
        <div className="day-and-time-selection-block">
            <div className="day-and-time-selection-days-timeframe">
                <input type="month" defaultValue={ props.selectedMonth } 
                    onChange={ (e) => props.onMonthChange(blockIndex, e) }
                    className="day-and-time-selection-days-timeframe-input" />
            </div>
            <div className="day-and-time-selection-bottom">
                <div className="day-and-time-selection-days-container">
                {dayWords.map((dayWord, index) => {
                    return <div key={ index } onClick={ () => props.onDaysChange(blockIndex, index + 1) }
                        className={ "day-and-time-selection-day " + 
                            (selectedDays.includes(index + 1) ? "day-and-time-selection-day-selected" : "") }>
                        { dayWord }
                    </div>
                })}
                </div>
                <p className="day-and-time-selection-time-hint">從⋯⋯</p>                
                <div className="day-and-time-selection-time-container">
                    <input type="time" defaultValue={ props.selectedStartTime } 
                        onChange={ (e) => props.onStartTimeChange(blockIndex, e) }
                        className="day-and-time-selection-time-input" />
                </div>
                <p className="day-and-time-selection-time-hint">到⋯⋯</p>
                <div className="day-and-time-selection-time-container">
                    <input type="time" defaultValue={ props.selectedEndTime } 
                        onChange={ (e) => props.onEndTimeChange(blockIndex, e) }
                        className="day-and-time-selection-time-input" />
                </div>
                <div className="day-and-time-selection-additional-data-container">
                    <input type="text" defaultValue={ props.editedAdditionalData } 
                        onChange={ (e) => props.onEditedDataChange(blockIndex, e) }
                        className="day-and-time-selection-additional-data-input"/>
                </div>
                <IconTextPair svgName="close" style={{ margin: '10px auto 20px auto', cursor: 'pointer',
                    width: '110px', opacity: .6, transform: 'scale(.9)', userSelect: 'none'}}
                    onClick={ () => props.removeBlock(blockIndex) }>
                    移除月份
                </IconTextPair>
            </div>
        </div>
    );
}

export function CheckBox(props){

    const [checked, setChecked] = useState(
        props.default === undefined ? true : props.default
    );

    function checkBoxClicked(){
        props.onChangeFunction && props.onChangeFunction(!checked);
        setChecked(!checked);
    }

    return (
        <div className="forms-item-wrap" style={{'padding': '10px 0 0 0'}}>
            <div className="forms-check-box-container"
                onClick={ checkBoxClicked }>
                <div className={ "forms-check-box " +
                    (checked ? "forms-check-box-checked " : " " ) }>
                    ∨
                </div>
                <div className="forms-check-box-text">
                    { props.buttonText }
                </div>
            </div>
        </div>
    )
}

export function DatesSelection(props){

    const [showEdit, setShowEdit] = useState(false);
    const closeEditPopup = () => setShowEdit(false);

    const totalSteps = 4;
    const [currentStep, setCurrentStep] = useState(1);

    function nextStep(){
        if(currentStep !== totalSteps){
            setCurrentStep(currentStep + 1);
        }
    }

    function resetShowEdit(){
        setCurrentStep(1);
        setShowEdit(true);
    }

    const [preData, setPreData] = useState({
        startDate: null, // YYYY-MM-DD (string)
        endDate: null
    });
    function updatePreData(e, name){
        const newPreData = Object.assign({}, preData);
        newPreData[name] = e.nativeEvent.target.value;
        setPreData(newPreData);
    }

    const yearMonthSet = new Set();
    (currentStep > 1 ? [preData.startDate, preData.endDate] : []).forEach(selectedDate=>{
        if (selectedDate) yearMonthSet.add (parseInt(selectedDate.split("-").slice(0, 2).join("")))
    })
    let yearMonth = [...yearMonthSet];

    // let yearMonth = [...new Set((currentStep > 1 ? [preData.startDate, preData.endDate] : [])
    //     .map(date =>{ return parseInt(date.split("-").slice(0, 2).join(""))
    // }))];

    const displayYearMonth = fillYearMonth(yearMonth);
    const allowedPeriods = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

    const [monthsData, setMonthsData] = useState({});
    function updateMonthsData(monthIndex, data){
        const newMonthData = Object.assign({}, monthsData);
        newMonthData[monthIndex] = data;
        setMonthsData(newMonthData);
    }

    const classTime = [];
    if(currentStep > 2){
        for(let i = 0; i < Object.keys(monthsData).length; i++){
            let monthIndexString = (displayYearMonth[i] % 100).toString();
            if(monthIndexString.length === 1) monthIndexString = "0" + monthIndexString;
            const thisMonthString = Math.floor(displayYearMonth[i] / 100) 
                + "-" + monthIndexString;
            const startOfMonthString = thisMonthString + "-01";
            const endOfMonthString = thisMonthString + "-" 
                + daysInMonth(Math.floor(displayYearMonth[i] / 100), displayYearMonth[i] % 100);
            const selectedDateTime = getDateTimeFromDays(startOfMonthString, 
                endOfMonthString, monthsData[i], preData.startDate, preData.endDate);
            classTime.push(...selectedDateTime);
        }
    }
    
    const [removedDates, setRemovedDates] = useState([]);
    function updateRemovedDates(calendarClickEvent){
        const newRemovedDates = [...removedDates];
        newRemovedDates.push(calendarClickEvent.date);
        setRemovedDates(newRemovedDates);
    }

    const finalizedClassTime = [];
    classTime.forEach(classTimeObject => {
        if(!removedDates.includes(classTimeObject.date)){
            finalizedClassTime.push(classTimeObject);
        }
    })
    const finalizedClassDates = getDatesFromDateTime(finalizedClassTime);
    if(finalizedClassTime.length){
        props.onChangeFunction && props.onChangeFunction(finalizedClassTime);
    }


    const stepsAndData = {
        1: {
            title: "設定開始與結束月份",
            content: <>
                <DateBox valueSet={ preData } name="startDate"
                    onChangeFunction={ updatePreData } 
                    displayName="梯次開始日期" />
                <DateBox valueSet={ preData } name="endDate"
                    onChangeFunction={ updatePreData } 
                    displayName="梯次結束日期" />
                <SubmitButton buttonText="下一步" onSubmitFunction={ nextStep } />
            </>
        },
        2: {
            title: "星期幾各要選擇幾點？",
            content: <>
                { displayYearMonth.map((month, index) => {
                    return (
                        <DayAndTimeSelectionTable yearMonth={ month } 
                            onChangeFunction={ (data) => updateMonthsData(index, data) }
                            prevMonthData={ JSON.parse(JSON.stringify(monthsData[index - 1] || [])) }
                            key={ "day-and-time-selection-table-" + index }
                            allowedPeriods={ allowedPeriods } copyable={ !!index } />
                    )
                }) }
                <SubmitButtonCenter buttonText={ <>&emsp;下一步&emsp;</> }
                    onSubmitFunction={ nextStep } />
            </>
        },
        3: {
            title: "移除有事無法上課的日期",
            subtitle: "點擊日期即可將該日期移除",
            content: <>
                <div className="date-selection-thin-calendar-wrap">
                   <CalendarView clickable selectedDates={ finalizedClassDates }
                    rowHeight="45" triggerFunction={ updateRemovedDates } />
                </div>
                <SubmitButtonCenter buttonText={ <>&emsp;下一步&emsp;</> }
                    onSubmitFunction={ nextStep } />
            </>
        },
        4: {
            title: "檢查時間是否正確",
            subtitle: "點擊日期可以查看當天時段",
            content: <>
                <CalendarTimeView classTime={ finalizedClassTime } />
                <SubmitButtonCenter buttonText={ <>&emsp;完成時間選擇&emsp;</> }
                    onSubmitFunction={ closeEditPopup } />
            </>
        }
    }

    return <div className="date-selection">
        <div className="date-selection-title">{ props.displayName }</div>
        <CalendarView clickable noBorder rowHeight="45" 
            selectedDates={ finalizedClassDates?.length ? finalizedClassDates : getDatesFromDateTime(props.selectedDates || []) }
            style={{ transform: 'translateY(-20px)' }} />
        <div className="date-selection-bottom"
            onClick={ () => resetShowEdit() }>
            點此以編輯時間
        </div>
        <Popup tall thin showing={ showEdit } closePopupFunction={ closeEditPopup }>
            <ProgressBar currentStep={ currentStep } totalStep={ totalSteps } />
            <PopupSection sectionTitle={ stepsAndData[currentStep]?.title }
                sectionSubtitle={ stepsAndData[currentStep]?.subtitle }>
                { stepsAndData[currentStep]?.content }
            </PopupSection>
        </Popup>
    </div>
}

function DayAndTimeSelectionTable(props){
    const yearMonth = props.yearMonth // YYYYMM
    const allowedPeriods = props.allowedPeriods;

    const days = [1, 2, 3, 4, 5, 6, 7];
    const [data, setData] = useState({
        1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: []
    });

    const [selectedTab, setSelectedTab] = useState(0);

    function updateData(day, hour){
        const newData = Object.assign({}, data);
        if(newData[day].includes(hour)){
            newData[day].splice(newData[day].indexOf(hour), 1);
        }else{
            newData[day].push(hour);
        }
        props.onChangeFunction && props.onChangeFunction(newData);
        setData(newData)
    }

    function copyPrevMonthData(){
        if(!props.prevMonthData) return null;
        props.onChangeFunction && props.onChangeFunction(props.prevMonthData);
        setData(props.prevMonthData)
    }

    const tabContent = {
        0: <TableFrame style={{ transform: 'translateX(-3px)', margin: '0 auto' }}
            width="305" rowHeight="35" noScroll columns={ [
                {width: 50, content: "時段"}, {width: 35, content: "一"},
                {width: 35, content: "二"}, {width: 35, content: "三"},
                {width: 35, content: "四"}, {width: 35, content: "五"},
                {width: 35, content: "六"}, {width: 35, content: "日"},
            ] }>

            { allowedPeriods.map((allowedPeriod, index) => {
                return <TableRow key={ "date-selection-table-row-" + index } content={ [
                    <div className="date-selection-table-key">{ allowedPeriod } 點</div>,
                    ...days.map((day, dayIndex) => {
                        return <div key={ "date-selection-table-" + day + "-" + dayIndex }
                            className={ "date-selection-table-block " + 
                                ( data[day].includes(allowedPeriod) ? "date-selection-table-block-selected" : "" ) }
                            onClick={ () => updateData(day, allowedPeriod) }>
                        </div>
                    })
                ] } />
            }) }

        </TableFrame>,

        1: <DayAndTimeManualSelection data={ data }
            updateData={ updateData } />
    }

    return (
        <div className="day-and-time-selection-table-wrap">
            <div className="day-and-time-selection-table-title">
                <div className="day-and-time-selection-table-title-left">{ Math.floor(yearMonth / 100) } 年 { yearMonth % 100 } 月</div>
                { props.copyable ? 
                    <div className="day-and-time-selection-table-title-right"
                        onClick={ copyPrevMonthData }>
                        從上個月複製
                    </div>
                : null}
            </div>

            <div className="day-and-time-selection-table-tabs-container">
                <div className={ "day-and-time-selection-table-tab " +
                    (selectedTab === 0 ? "day-and-time-selection-table-tab-selected " : "") }
                    onClick={ () => setSelectedTab(0) }>
                    勾選整點
                </div>
                <div className={ "day-and-time-selection-table-tab " +
                    (selectedTab === 1 ? "day-and-time-selection-table-tab-selected " : "") }
                    onClick={ () => setSelectedTab(1) }>
                    手動添加
                </div>
            </div>

            { tabContent[selectedTab] }
            
        </div>
    )
}

function DayAndTimeManualSelection(props){
    const [expansionMap, setExpansionMap] = useState({
        1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false
    });
    function toggleExpansionMap(dayIndex){
        const newExpansionMap = Object.assign({}, expansionMap);
        newExpansionMap[dayIndex] = !newExpansionMap[dayIndex];
        setExpansionMap(newExpansionMap);
    }
    const dayStrings = {
        1: "一", 2: "二", 3: "三", 4: "四", 5: "五", 6: "六", 7: "日"
    }
    const icoDir = require.context("../ico");

    const addTimeMap = useRef({});
    function updateAddTimeMap(e, name){
        addTimeMap.current[name] = getHourFromTimeString(e.nativeEvent.target.value);
    }

    return (
        <div className="day-and-time-manual-selection">
            { Object.keys(expansionMap).map((dayIndex, index) => {
                return <div className="day-and-time-manual-selection-day-container"
                    key={ "day-and-time-manual-selection-day-container-" + index }>
                    <div className="day-and-time-manual-selection-top"
                        onClick={ () => toggleExpansionMap(dayIndex) }>
                        <div className="day-and-time-manual-selection-top-label">
                            星期{ dayStrings[dayIndex] }
                        </div>
                        <div className="day-and-time-manual-selection-top-button"
                            style={{ backgroundImage: expansionMap[dayIndex]
                                ? `url(${icoDir("./arrow_up_uni.svg")})`
                                : `url(${icoDir("./arrow_down_uni.svg")})` }}>
                        </div>
                    </div>
                    { expansionMap[dayIndex]
                        ? <>
                            <div className="day-and-time-manual-selection-time-container">
                                { (props.data[dayIndex] || []).map((hourString, index2) => {
                                    return <div className="day-and-time-manual-selection-time-block"
                                        key={ "day-and-time-manual-selection-time-block-" + index2 }
                                        onClick={ () => props.updateData(dayIndex, hourString) }>
                                        <IconTextPair svgName="close" fontSize="15">
                                            { getTimeStringFromHour(hourString) }
                                        </IconTextPair>
                                    </div>
                                }) }
                            </div>
                            <div className="day-and-time-manual-selection-add-container">
                                <TimeBox onChangeFunction={ updateAddTimeMap } name={ dayIndex } />
                                <div className="day-and-time-manual-selection-add-button-container">
                                    <SubmitButton buttonText="新增時間" condense
                                        onSubmitFunction={ () => props.updateData(dayIndex, addTimeMap.current[dayIndex]) } />
                                </div>
                            </div>
                        </>
                        
                    : null }
                </div>
            }) }
        </div>
    )

}


export function SubjectsSelection(props){

    const [blankState, setBlankState] = useState({});
    const allSubjects = 
        ["math", "english", "science", "language", "world", 
        "culture", "chinese_composition", "assistance", "zhuyin"];
    const [availableSubjects, setAvailableSubjects] = useState((props.availableSubjects || [...allSubjects]).map(subject => {
        if(allSubjects.includes(subject)) return subject;
    }));
    useEffect(() => {
        setAvailableSubjects((props.availableSubjects || [...allSubjects]).map(subject => {
            if(allSubjects.includes(subject)) return subject;
        }))
    }, [props.availableSubjects?.length]);
    
    const selectionLimit = parseInt(props.selectionLimit) || availableSubjects.length;

    const [selectedStateMap, setSelectedStateMap] = 
        useState(availableSubjects.reduce((a,b)=> 
            (a[b]=(props.selectedSubjects || []).indexOf(b) > -1,a),{}));
    useEffect(() => {
        setSelectedStateMap(availableSubjects.reduce((a,b)=> 
            (a[b]=(props.selectedSubjects || []).indexOf(b) > -1,a),{}))
    }, [availableSubjects.length, props.selectedSubjects?.length]);

    function changeSelectedStateMap(subject){
        if(!selectedStateMap[subject] && selectionLimit > 1 &&
            Object.values(selectedStateMap).filter(x=>x).length >= selectionLimit) return;
        const newSelectedStateMap = Object.assign({}, selectedStateMap);
        if(!selectedStateMap[subject] && selectionLimit === 1){
            Object.keys(selectedStateMap).forEach(stateSubject => newSelectedStateMap[stateSubject] = false);
        }
        newSelectedStateMap[subject] = !newSelectedStateMap[subject];
        props.onChangeFunction && props.onChangeFunction(getSelectedSubjects(newSelectedStateMap));
        setSelectedStateMap(newSelectedStateMap);
    }
    function getSelectedSubjects(newSelectedStateMap){
        const selectedSubjects = [];
        Object.keys(newSelectedStateMap).forEach(subject => {
            if(newSelectedStateMap[subject]) selectedSubjects.push(subject);
        });
        return selectedSubjects;
    }

    return (
        <>
            { props.showTitle
                ? <div className="subjects-selection-title">
                    點擊科目圖示以選擇科目
                </div>
            : null }
            <div className="subjects-selection">
                { Object.keys(selectedStateMap).map((subject, index) => {
                    const selectedState = selectedStateMap[subject];
                    return <SubjectsSelectionBlock selected={ selectedState } 
                        onClick={ changeSelectedStateMap } subject={ subject }
                        displayName={ strings[subject] }
                        key={ "subjects-selection-block-" + index } />
                }) }
            </div>
        </>
    )
}

export function SubjectsSelectionBlock(props){
    const icoDir = require.context("../ico/subjects");
    return (
        <div className={ "subjects-selection-block " + 
            (props.selected ? "subjects-selection-block-selected" : "")}
            onClick={ () => props.onClick(props.subject) }>

            <div className="subjects-selection-block-left" style={{
                backgroundImage: `url(${icoDir("./" + props.subject + ".svg")})`
            }}></div>
            <div className="subjects-selection-block-right">
                { props.displayName }
            </div>
        </div>
    )
}

export function DaysSelection({selectedDays=[], displayName, onChangeFunction=null, ...props}){
    const dayStrings = {1: "一", 2: "二", 3: "三", 4: "四", 5: "五", 6: "六", 7: "日"};
    const [selectionStateMap, setSelectionStateMap] = useState([1, 2, 3, 4, 5, 6, ,7].reduce((prevStateMap, thisDay) => {
        prevStateMap[thisDay] = selectedDays.includes(thisDay);
        return prevStateMap;
    }, {}));
    const [thisId, setThisId] = useState(makeid(10));
    
    function dayClicked(dayIndex){
        const newSelectionStateMap = Object.assign({}, selectionStateMap);
        newSelectionStateMap[dayIndex] = !newSelectionStateMap[dayIndex];
        onChangeFunction && onChangeFunction(getSelectedDays(newSelectionStateMap));
        setSelectionStateMap(newSelectionStateMap);
    }

    function getSelectedDays(newSelectionStateMap=selectionStateMap){
        return [1, 2, 3, 4, 5, 6, 7].filter(dayIndex => newSelectionStateMap[dayIndex])
    }

    return (
        <div {...props} className="days-selection">
            { displayName
                ? <p className="forms-item-title">{ displayName }</p>
            : null}
            <div className="days-selection-days-container">
                { Object.keys(selectionStateMap).map((dayIndex, index) => {
                    return <div className={ "days-selection-block " + 
                        (selectionStateMap[dayIndex] ? "days-selection-block-selected" : "")} 
                        key={ `days-selection-${thisId}-${index}` }
                        onClick={ () => dayClicked(dayIndex) }>
                        { dayStrings[dayIndex] }
                    </div>
                }) }
            </div>
        </div>
    )
}