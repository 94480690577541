import BaseFooter from '../components/BaseFooter';
import '../components/BaseNav';
import BaseNav from '../components/BaseNav';
import Landing from '../components/Landing';

function Home(){
    return (
        <div className="bottom landing-bottom">
            <BaseNav></BaseNav>
            <Landing></Landing>
            <BaseFooter></BaseFooter>
        </div>
    )
}

export default Home;