import "../style/Table.css";
import React, { useContext } from "react";

const columnWidths = React.createContext([]);
const rowHeight = React.createContext(70);

export function TableFrame(props){

    const thisTableColumnWidths = [];
    (props.columns || []).forEach((column) => {
        thisTableColumnWidths.push(column.width);
    });

    const tableStyle = Object.assign({}, props.style || {});
    tableStyle["width"] = props.width + "px"
    if(props.condense){
        tableStyle["margin"] = "0";
    }

    return (
        <div className={ "table-bottom " + 
            (props.noSidePadding ? "table-bottom-no-side-padding" : "")} 
            style={ tableStyle }>
            
            <div className="table-title-row" 
                style={{ height: (props.titleRowHeight || 70) + "px", 
                    lineHeight: (props.titleRowHeight || 70) + "px" }} >
                { (props.columns || []).map((column, index) => {
                    return <div className="table-title-row-block"
                        style={{ width: column.width + "px" }} key={ index }> 
                        {column.content} 
                    </div>
                }) }
            </div>

            <div className={ "table-rows-container " + 
                (props.noScroll ? "table-rows-container-noscroll " : " ")}
                style={ props.rowContainerHeight ? {height: props.rowContainerHeight + "px"} : {} }>
                <columnWidths.Provider value={ thisTableColumnWidths }>
                    <rowHeight.Provider value={ props.rowHeight || 70 }>
                        { props.children }
                    </rowHeight.Provider>
                </columnWidths.Provider>
            </div>

        </div>
    )
}

export function TableRow(props){
    
    const widths = useContext(columnWidths);
    const height = useContext(rowHeight);

    const style = Object.assign({}, props.style || {});
    style.height = height === "auto" ? "auto" : height + "px";

    return (
        <div className={ "table-row " + props.className + (props.selected ? " table-row-selected " : " ") } 
            style={ style }
            onClick={ props.onClick }>
            { (props.content || []).map((rowBlock, index) => {
                return <div className="table-row-block" key={ index } 
                    style={{ width: (widths[index]) + "px" }}>
                    { rowBlock }
                </div>
            }) }
        </div>
    );
}

export function TableRowFull(props){
    const height = useContext(rowHeight);
    return (
        <div className={ "table-row " + (props.selected ? "table-row-selected" : " ") }
            style={{ height: height === "auto" ? "auto" : height + "px" }}
            onClick={ props.onClick }>
            { props.children }
        </div>
    )
}