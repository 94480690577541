import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { increment } from "@firebase/firestore";
import AccountTypeAccess from "../components/AccountTypeAccess";
import ClassOverview from "../components/ClassOverview";
import { CoinAdd } from "../components/Coin";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { DatesSelection, SelectionList, SubjectsSelection, SubmitButton, SubmitButtonCenter, TextBox } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import ProgressBar from "../components/ProgressBar";
import SubjectBlock from "../components/SubjectBlock";
import TopNavTabs from "../components/TopNavTabs";
import { createClassInActivity, getActivityPublicInfo, getClassesInActivityByVolunteerReferenceId, getStudentsFromCommunity, getUsersByReferenceIds, updateClassInActivityByClassId, updateStudentInCommunity } from "../lib/user";
import "../style/Volunteer.css";
import makeid from "../lib/makeid";
import { strings } from "../lib/strings";
import { AccessDeniedBlock } from "../components/AccessDeniedBlock";
import CalendarTimeView from "../components/CalendarTimeView";
import CalendarView from "../components/CalendarView";
import { getTodayDateString } from "../lib/dates";
import ClassLink from "../components/ClassLink";
import { ExpandDropDown } from "../components/ExpandDropDown";

export default function Volunteer(props){
    const topLevelStates = useContext(topLevelStatesContext);
    
    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [volunteerClasses, setVolunteerClasses] = useState(null);
    const [activityInfo, setActivityInfo] = useState({});

    useEffect(() => {
        if(volunteerClasses !== null || topLevelStates.user.userType !== "volunteer") return;
        fetchVolunteerClassesData();
        getActivityPublicInfo(actvid, setActivityInfo);
    }, [topLevelStates.hasLoaded]);

    function fetchVolunteerClassesData(){
        getClassesInActivityByVolunteerReferenceId(actvid, topLevelStates.user.referenceId, 
            topLevelStates.addNotification, setVolunteerClasses);
    }

    // Map the studentReferenceId arrays to their communityReferenceIds
    const communityStudentsMap = {}; 
    for(const volunteerClass of (volunteerClasses || [])){
        if(!Array.isArray(communityStudentsMap[volunteerClass.communityReferenceId])){
            communityStudentsMap[volunteerClass.communityReferenceId] = [...(volunteerClass.students || [])];
        }else{
            (volunteerClass.students || []).forEach(studentReferenceId => {
                if(!communityStudentsMap[volunteerClass.communityReferenceId].includes(studentReferenceId)){
                    communityStudentsMap[volunteerClass.communityReferenceId].push(studentReferenceId);
                }
            })
        }
    }

    // Map the student data to their communityReferenceIds
    const [studentsData, setStudentsData] = useState({});
    function updateStudentsData(communityReferenceId, loadedStudentsData){
        const newStudentsData = Object.assign({}, studentsData);
        newStudentsData[communityReferenceId] = {};
        for(const studentData of loadedStudentsData){
            newStudentsData[communityReferenceId][studentData.studentReferenceId] = studentData;
        }
        setStudentsData(newStudentsData);
    }

    Object.keys(communityStudentsMap).forEach(communityReferenceId => {
        if(!studentsData[communityReferenceId] && communityStudentsMap[communityReferenceId]?.length){
            getStudentsFromCommunity(communityReferenceId, 
                communityStudentsMap[communityReferenceId], null, (returnedData) => {
                    updateStudentsData(communityReferenceId, returnedData);
                });
        }
    });

    const tabs = [];
    const hasRendered = useRef(false);
    if(!hasRendered && !topLevelStates.user?.activities?.includes(actvid) && topLevelStates.user?.actvid !== actvid){
        tabs.push(
            {displayName: "檢視權限", name: "accessDenied", content: <AccessDeniedBlock />}
        )
    }else{
        hasRendered.current = true;
        tabs.push(
            {displayName: "課程與學生", name: "classes", content: 
                <VolunteerClasses volunteerClasses={ volunteerClasses }
                    studentsData={ studentsData } actvid={ actvid } />},
            {displayName: "服務設定", name: "setting", content: 
                <VolunteerSetting volunteerClasses={ volunteerClasses } activity={ activityInfo }
                    refetchData={ fetchVolunteerClassesData } actvid={ actvid } />}
        );
    }

    return (
        <DashboardFrame full selected="dashboard">
            <AccountTypeAccess accessFor={ ["volunteer"] }>
                <TopNavTabs pageTitle="志工服務頁面" pageSubtitle="Infinitas 線上志工"
                    tabs={ tabs } />
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function VolunteerClasses(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const studentsData = props.studentsData || {};
    const [studentPersonalData, setStudentPersonalData] = useState(null);
    useEffect(() => {
        if(studentPersonalData === null){
            const studentReferenceIds = [];
            Object.values(studentsData).forEach((communityStudents) => {
                studentReferenceIds.push(...Object.keys(communityStudents));
            });
            studentReferenceIds.length && getUsersByReferenceIds(studentReferenceIds, (returnedData) => {
                const newStudentPersonalData = {};
                returnedData.forEach(loadedUser => {
                    newStudentPersonalData[loadedUser.referenceId] = loadedUser;
                });
                setStudentPersonalData(newStudentPersonalData);
            });
        }
    });

    function saveStudentCoinChange(incrementValue, communityReferenceId, studentReferenceId){
        updateStudentInCommunity({coins: increment(incrementValue)}, 
            communityReferenceId, studentReferenceId, topLevelStates.addNotification);
    }

    const [viewingDate, setViewingDate] = useState(getTodayDateString());
    function updateViewingDate(calendarEvent){
        setViewingDate(calendarEvent.date);
    }

    return <div className="volunteer-page-content">
        { (props.volunteerClasses || []).map((volunteerClass, index) => {
            const studentsDataInCommunity = props.studentsData[volunteerClass.communityReferenceId];
            return <>
                <DashboardBlock blockTitle="課堂資料" key={ "volunteer-class-detail-" + index }
                    blockSubtitle={ `課程 classId：${volunteerClass.classId}` }>
                    <div className="overview-class-time">
                        <CalendarView viewingDate={ viewingDate } clickable miniview rowHeight="37" triggerFunction={ updateViewingDate }
                            selectedDates={ (volunteerClass.classTime || []).map(classTimeObject => classTimeObject.date) } />
                        <div className="overview-class-time-time">
                            <div className="overview-class-time-time-title">{ viewingDate }</div>
                            <div className="overview-class-time-time-container">
                                { (function (){
                                    const returnList = [];
                                    (volunteerClass.classTime || []).forEach((classTimeObject, index) => {
                                        if(classTimeObject.date === viewingDate) returnList.push(<div className="overview-class-time-time-block"
                                            key={ "class-time-object-" + index + volunteerClass.classId }>
                                            { classTimeObject.time }
                                        </div>)
                                    });
                                    if(returnList.length === 0) returnList.push(
                                        <div className="overview-class-time-time-block">今日無上課</div>
                                    );
                                    return returnList
                                })() }
                            </div>
                            <div className="overview-class-time-link-container">
                                <ClassLink classLink={ volunteerClass.classLink } />
                            </div>
                        </div>
                    </div>
                    <div className="overview-subjects overview-subjects-condense">
                        { (volunteerClass.subjects || []).map((subject, index2) => {
                            return <SubjectBlock subject={ subject } 
                                key={ "volunteer-class-detail-" + index2 } />
                        }) }
                    </div>
                    { (volunteerClass.students || []).map((studentReferenceId, index) => {
                        return <div className="volunteer-class-detail-student"
                            key={ "volunteer-class-detail-student-" + index }>
                            <div className="volunteer-class-detail-student-left">
                                <h5 className="volunteer-class-detail-student-name">
                                    { (studentsDataInCommunity || {})[studentReferenceId]?.name }（{ (studentPersonalData || {})[studentReferenceId]?.sex }）
                                </h5>
                                <IconTextPair svgName="school">{ (studentPersonalData || {})[studentReferenceId]?.school }</IconTextPair>
                                <IconTextPair svgName="age">年級：{ strings[(studentPersonalData || {})[studentReferenceId]?.grade] }</IconTextPair>
                                <IconTextPair svgName="information">{ (studentsDataInCommunity || {})[studentReferenceId]?.note || "無基本介紹" }</IconTextPair>
                                <Link to={ "/StudentProfile?communityReferenceId=" + volunteerClass.communityReferenceId + "&studentReferenceId=" + studentReferenceId }>
                                    <SubmitButton buttonText="前往學生頁面" noSidePadding condense />
                                </Link>
                                <Link to={ "/AddRecord?communityReferenceId=" + volunteerClass.communityReferenceId + "&studentReferenceId=" + studentReferenceId }>
                                    <SubmitButton buttonText="填寫教學紀錄" noSidePadding condense />
                                </Link>
                            </div>
                            <div className="volunteer-class-detail-student-right">
                                <CoinAdd coins={ (studentsDataInCommunity || {})[studentReferenceId]?.coins } 
                                    updateCoinFunction={ (incrementValue) => {
                                    saveStudentCoinChange(incrementValue, 
                                        volunteerClass.communityReferenceId, studentReferenceId);
                                } } />
                            </div>
                        </div>
                    }) }

                </DashboardBlock>
            </>
        }) }
        { !props.volunteerClasses?.length
            ? <VolunteerInitClassWarning actvid={ props.actvid } />
        : null }
    </div>
}

function VolunteerInitClassWarning(props){
    const navigate = useNavigate();
    return (
        <DashboardBlock blockTitle="尚未建置課堂">
            <div className="volunteer-init-class-warning">
                <p className="volunteer-init-class-warning-content">
                    您尚未建置您的課堂，請點擊下方按鈕，並依照指示填寫：可服務時間、偏好上課時間、偏好教學科目、學生人數等資料
                </p>
                <SubmitButton buttonText="前往建置課堂" center squeeze
                    onSubmitFunction={ () => navigate(`/Volunteer?actvid=${props.actvid}&tab=setting`) } />
            </div>
        </DashboardBlock>
    )
}

function EditClassLinkBlock(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const classLink = useRef(props.classLink);

    function copyClassLink(){
        navigator.clipboard.writeText(classLink.current);
        topLevelStates.addNotification("已複製至剪貼簿");
    }

    function updateClassLink(e){
        classLink.current = e.nativeEvent.target.value;
    }
    function saveClassLink(){
        console.log(props.actvid, props.classId);
        updateClassInActivityByClassId(props.actvid, props.classId, {
            classLink: classLink.current
        }, topLevelStates.addNotification, null);
    }

    return (
        <div className="edit-class-link-block">
            <TextBox displayName="上課 Meet 連結" valueSet={{ classLink: classLink.current }}
                name="classLink" onChangeFunction={ updateClassLink } />
            <div className="edit-class-link-block-buttons-container">
                <SubmitButton buttonText="複製連結" squeeze onSubmitFunction={ copyClassLink } />
                <SubmitButton buttonText="儲存連結" squeeze onSubmitFunction={ saveClassLink } />
            </div>
        </div>
    )
}

function VolunteerSetting(props){
    const topLevelStates = useContext(topLevelStatesContext);

    function createClass(){
        createClassInActivity(props.actvid, topLevelStates.user.referenceId, {}, 
            topLevelStates.addNotification, () => {
                props.refetchData();
            });
    }

    return <div className="volunteer-page-content">
        <DashboardBlock blockTitle="梯次課堂設定">
            { (props.volunteerClasses || []).map((volunteerClass, index) => {
                return <InformationStatusV2 key={ "volunteer-class-information-status-" + index } 
                    volunteerClass={ volunteerClass } activity={ props.activity }
                    refetchData={ props.refetchData } actvid={ props.actvid } /> 
            }) }
            { (props.volunteerClasses || []).length === 0
                ? <>
                    <SubmitButton squeeze buttonText={ <>&emsp;建立一堂課&emsp;</> }
                        onSubmitFunction={ createClass } />
                </>
            : null }
        </DashboardBlock>
    </div>
}

function ClassSetting({ volunteerClass, actvid, refetchData }){
    const topLevelStates = useContext(topLevelStatesContext);
    
    const hasEdited = useRef(false);
    const changedClassObject = useRef({});
    function updateChangedClassObject(e, name){
        hasEdited.current = true;
        changedClassObject.current[name] = e.nativeEvent.target.value;
    }
    function saveClassData(){
        if(!hasEdited.current) return;
        updateClassInActivityByClassId(actvid, volunteerClass.classId, 
            changedClassObject.current, topLevelStates.addNotification, refetchData);
    }


    return (
        <div className="class-setting">
            <div className="class-setting-title">ClassId: { volunteerClass.classId }</div>
            <TextBox displayName="上課連結" name="classLink" 
                valueSet={ volunteerClass }
                onChangeFunction={ updateChangedClassObject } />
            <SubmitButton buttonText="儲存資訊" squeeze onSubmitFunction={ saveClassData } />
        </div>
    )
}

function InformationStatusV2({ volunteerClass={}, activity={}, refetchData, actvid }){
const topLevelStates = useContext(topLevelStatesContext);

    const volunteerHandleSelection = [
        {value: 1, displayName: "一位", default: true},
       /* {value: 2, displayName: "二位"},
        {value: 3, displayName: "三位"},
        {value: 4, displayName: "四位"},
        {value: 5, displayName: "五位"}*/
    ];
    const selectibleSubjects = activity.subjects.filter(subject => subject !== "assistance") || null;
    const prefixEmojis = {done: "✅", pending: "🔴", locked: "🔓"};

    const [saveData, setSaveData] = useState({
        availableTime: (volunteerClass.availableTime || {})[topLevelStates.user.referenceId] || [],
        classTime: volunteerClass.classTime || [],
        subjects: volunteerClass.subjects || [],
        volunteerHandle: volunteerClass.volunteerHandle || 1,
        classLink: volunteerClass.classLink || ""
    });
    function updateSaveData(newData, name){
        const dataCheck = JSON.stringify(saveData[name]);
        if(dataCheck === JSON.stringify(newData)) return;
        const newSaveData = Object.assign({}, saveData);
        newSaveData[name] = newData;
        setSaveData(newSaveData);
    }

    function uploadSaveData(name){
        const uploadedObject = {};
        if(name === "availableTime"){
            const availableTimeObject = {};
            availableTimeObject[topLevelStates.user?.referenceId] = saveData[name];
            uploadedObject[name] = availableTimeObject;
        }else{
            if(name === "classTime" && !saveData.classTime?.length){
                topLevelStates.addNotification("請務必填妥最想服務的時間！");
                return;
            };
            console.log(activity.volunteerClassTimeLimit);
            if(activity.volunteerClassTimeLimit && !isNaN(activity.volunteerClassTimeLimit) && name === "classTime" && saveData.classTime.length > parseInt(activity.volunteerClassTimeLimit)){
                topLevelStates.addNotification("此欄位應填最想服務的時間，本梯次中您不應選取超過 " + activity.volunteerClassTimeLimit + " 堂課，然而您選取了 " + saveData.classTime.length + " 堂。");
                return;
            }
            if(activity.classPerWeek && !isNaN(activity.classPerWeek) && name === "classTime" && saveData.classTime){

            }
            uploadedObject[name] = saveData[name];
        }
        updateClassInActivityByClassId(actvid, volunteerClass.classId, uploadedObject, 
            topLevelStates.addNotification, () => {
                refetchData();
            });
    }

    const expandedDropDownContent = [
        {title: "所有可服務時間", requiredField: "availableTime", 
            status: (volunteerClass.availableTime || {})[topLevelStates.user.referenceId] ? "done" : "pending",
            description: "此欄位請填寫您所有可以服務的時間，以利需人工配對時，為您配對時間相符的學生。",
            content: <>
                <DatesSelection displayName="選取所有可服務的時間"
                    selectedDates={ (volunteerClass.availableTime || {})[topLevelStates.user.referenceId] }
                    onChangeFunction={ (newAvailableTime => updateSaveData(newAvailableTime, "availableTime")) }  />
                <SubmitButton center squeeze buttonText="儲存可服務的時間"
                    onSubmitFunction={ () => uploadSaveData("availableTime") } />
            </>
        },
        {title: "預設（偏好）服務時間", requiredField: "classTime",
            status: volunteerClass.students?.length ? "locked" : (volunteerClass.classTime?.length ? "done" : "pending"),
            description: "此欄位請填寫您最想要服務的時間，並非所有可服務時間。此時間為家長選課時預設所見之時間。",
            content: <>
                <DatesSelection displayName="選取預設（偏好）服務時間"
                    selectedDates={ volunteerClass.classTime || [] }
                    onChangeFunction={ (newClassTime => updateSaveData(newClassTime, "classTime")) } />
                <SubmitButton center squeeze buttonText="儲存上課時間"
                    onSubmitFunction={ () => uploadSaveData("classTime") } />
            </>
        },
        {title: "服務人數上限", requiredField: "volunteerHandle", 
            status: volunteerClass.students?.length ? "locked" : (volunteerClass.volunteerHandle ? "done" : "pending"),
            description: "並非每堂課都是一對一教學。您可在此填寫自己可以負荷的學生人數上限。",
            content: <>
                <SelectionList displayName="你認為一堂課可以帶多少學生？" options={ volunteerHandleSelection }
                    valueSet={ volunteerClass } name="volunteerHandle"
                    onChangeFunction={ (e, name) => updateSaveData(e.nativeEvent.target.value, "volunteerHandle") } />
                <SubmitButton squeeze buttonText="儲存服務人數" 
                    onSubmitFunction={ () => uploadSaveData("volunteerHandle") } />
            </>
        },
        {title: "教學科目（至多選 2）", requiredField: "subjects",  
            status: volunteerClass.students?.length ? "locked" : (volunteerClass.subjects?.length ? "done" : "pending"),
            description: "在了解教學內容後，請您填寫自己有興趣教學的科目。",
            content: <>
                <SubjectsSelection availableSubjects={ selectibleSubjects } selectionLimit={ 2 }
                    selectedSubjects={ volunteerClass.subjects || [] }
                    onChangeFunction={ (newSubjects => updateSaveData(newSubjects, "subjects")) } />
                <SubmitButton center buttonText={ <>&emsp;儲存並至下一步&emsp;</> } condense 
                    onSubmitFunction={ () => uploadSaveData("subjects") } />
            </>
        },
        {title: "上課連結", requiredField: "classLink",
            status: volunteerClass.classLink ? "done" : "pending",
            description: "請至 Google Meet 建立一個會議連結，屆時上課時將使用該連結。若連結過期失效，請於上課前於此處更新可用的連結。",
            content: <>
                <TextBox displayName="上課 Meet 連結"  valueSet={ volunteerClass } name="classLink"
                    onChangeFunction={ (e, name) => updateSaveData(e.nativeEvent.target.value, "classLink") } />
                <SubmitButton squeeze buttonText="儲存上課連結" 
                    onSubmitFunction={ () => uploadSaveData("classLink") } />
            </>
        }
    ]

    return (
        <>
            <div className="information-status-title">
                課程 classId：{ volunteerClass.classId }
            </div>
            <div className="information-status">
                { expandedDropDownContent.map((contentObject, index) => {
                    return <ExpandDropDown title={ `${prefixEmojis[contentObject.status]} ${contentObject.title}` }
                        locked={ contentObject.status === "locked" } >
                        <div className="information-status-drop-down-description">
                            { contentObject.description }
                        </div>
                        { contentObject.content || null }
                    </ExpandDropDown> 
                }) }
            </div>
        </>
    )
}
