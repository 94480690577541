import DashboardFrame from "../components/DashboardFrame";
import DashboardBlock from "../components/DashboardBlock";
import AccountTypeAccess from "../components/AccountTypeAccess";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { topLevelStatesContext } from "../App";
import TopNavTabs from "../components/TopNavTabs";
import Billboard from "../components/Billboard";
import "../style/DashboardPage.css";
import { Link, useNavigate } from "react-router-dom";
import CalendarView from "../components/CalendarView";
import { getTodayDateString } from "../lib/dates";
import { SubjectChip } from "../components/SubjectBlock";
import ClassLink from "../components/ClassLink";
import { CoinDataSplit, StudentRecordSnipset } from "./StudentProfile";
import { getStudentsFromCommunity, updateUser } from "../lib/user";
import { SubmitButton, TextBox } from "../components/Forms";
import makeid from "../lib/makeid";
// src/firebase.js
import firebase from 'firebase/app';
import 'firebase/storage';

function Dashboard(){
    const topLevelStates = useContext(topLevelStatesContext);

    const [userCommunityData, setUserCommunityData] = useState(null);
    useEffect(() => {
        if(topLevelStates.user.userType === "student" && userCommunityData === null && topLevelStates.activitiesPublicInfo?.length){
            getStudentsFromCommunity(topLevelStates.activitiesPublicInfo[0].communityReferenceId, 
                [topLevelStates.user.referenceId], null, returnedList => {
                    
                if(returnedList.length){
                    returnedList[0].communityReferenceId = topLevelStates.activitiesPublicInfo[0].communityReferenceId;
                    setUserCommunityData(returnedList[0]);
                } 
            })
        }
    });

    const dashboardTabs = [
        {displayName: "服務資訊總覽", name: 'overview', 
            content: <OverviewTab activitiesPublicInfo={ topLevelStates.activitiesPublicInfo } 
                currentActivitiesClasses={ topLevelStates.currentActivitiesClasses }
                userCommunityData={ userCommunityData } />},
        {displayName: "教育資源", name: 'resources', content: <Resources />}

    ];

    if(topLevelStates.user.userType === "student"){
        dashboardTabs.splice(1, 0, {
            displayName: "教學紀錄", name: "records", 
            content: <StudentRecordSnipset studentReferenceId={ topLevelStates.user.referenceId }
                communityReferenceId={ topLevelStates.activitiesPublicInfo?.length ? topLevelStates.activitiesPublicInfo[0].communityReferenceId : "" } />
        })
    }

    return (
        <DashboardFrame selected="dashboard" full>
            <TopNavTabs pageTitle="服務資訊總覽" 
                pageSubtitle="Infinitas 學生志工計畫" tabs={ dashboardTabs } />
        </DashboardFrame>
    );
}

function OverviewTab(props){

    const topLevelStates = useContext(topLevelStatesContext);
    const [activitiesPublicInfo, setActivitiesPublicInfo] = useState(props.activitiesPublicInfo || []);
    const [currentActivitiesClasses, setCurrentActivitiesClasses] = useState(props.currentActivitiesClasses || {});
    useEffect(() => {
        setActivitiesPublicInfo(props.activitiesPublicInfo || []);
        setCurrentActivitiesClasses(props.currentActivitiesClasses || []);
    }, [JSON.stringify(props.activitiesPublicInfo), JSON.stringify(props.currentActivitiesClasses)]);

    const [userCommunityData, setUserCommunityData] = useState(props.userCommunityData || {});
    useEffect(() => {
        setUserCommunityData(props.userCommunityData);
    }, [JSON.stringify(props.userCommunityData)]);
    
    const haventSelectedClasses = (topLevelStates.user?.activities || []).filter(actvid => {
        return (activitiesPublicInfo || {}).find(activity => activity.actvid === actvid)?.activated !== "ended";
    });
    
    return (
        <>
            <AccountTypeAccess accessFor={ ["admin"] }>
                <DashboardBlock blockTitle="管理員頁面">
                    <Link to={ "/Admin" }>
                        <SubmitButton center buttonText="點此前往管理員頁面" condense />
                    </Link>
                </DashboardBlock>
            </AccountTypeAccess>

            <AccountTypeAccess accessFor={ ["student"] }>
                { haventSelectedClasses.length
                    ? <DashboardBlock blockTitle="❣️ 有梯次可以進行選課">
                        { activitiesPublicInfo.map((activity, index) => {
                            if(!haventSelectedClasses.includes(activity.actvid)) return null;
                            return <ActivityThumbnail key={ "dashboard-activity-thumbnail-" + index } activity={ activity } 
                                classes={ currentActivitiesClasses[activity.actvid] || [] } selectClass />
                        }) }
                    </DashboardBlock>
                : null }
            </AccountTypeAccess>

            <AccountTypeAccess accessFor={ ["community"] }>
                <DashboardBlock blockTitle="社區管理帳號" blockSubtitle="您可以從下列的連結前往社區的學生管理頁面，或者點擊以下的活動至社區的活動學生列表">
                    <Link to={ "/CommunityStudents?communityReferenceId=" + topLevelStates.user.referenceId }>
                        <SubmitButton center buttonText="點此前往社區管理頁面" condense />
                    </Link>
                    <br />
                    { activitiesPublicInfo.map((activity, index) => {
                        return <ActivityThumbnail key={ "dashboard-activity-thumbnail-" + index } activity={ activity } 
                            classes={ currentActivitiesClasses[activity.actvid] || [] } />
                    }) }
                </DashboardBlock>
            </AccountTypeAccess>

            <DashboardBlock blockTitle="平台公佈欄">
                <Billboard />
            </DashboardBlock>

            <AccountTypeAccess accessFor={ ["volunteer"] }>
                <DashboardBlock blockTitle="參與的活動梯次">
                    { activitiesPublicInfo.map((activity, index) => {
                        return <ActivityThumbnail key={ "dashboard-activity-thumbnail-" + index } activity={ activity } 
                            classes={ currentActivitiesClasses[activity.actvid] || [] } />
                    }) }
                    { !activitiesPublicInfo.length
                        ? "🔴 目前尚未正式加入任何活動"
                    :null }
                </DashboardBlock>
                <DashboardBlock blockTitle="申請加入梯次"
                    blockSubtitle="如果你目前的活動梯次已經進入尾聲，你可以在此輸入下一梯次的活動代碼以申請加入下一梯次。">

                    <AddToJoinBlock />
                </DashboardBlock>
            </AccountTypeAccess>

            <AccountTypeAccess accessFor={ ["student"] }>
                <DashboardBlock blockTitle="我的課堂" blockSubtitle="點擊日曆上的日期可查看上課內容">
                    <StudentClassOverview currentActivitiesClasses={ currentActivitiesClasses }
                        activitiesPublicInfo={ activitiesPublicInfo } />
                </DashboardBlock>
                <DashboardBlock blockTitle="我的資料">
                    <CoinDataSplit studentPersonalData={ topLevelStates.user } 
                        communityStudentData={ userCommunityData } />
                    <Link to={ `/StudentProfile?communityReferenceId=${ userCommunityData?.communityReferenceId }&studentReferenceId=${ topLevelStates.user?.referenceId }` }>
                        <SubmitButton buttonText="瀏覽教學紀錄" />
                    </Link>
                </DashboardBlock>
            </AccountTypeAccess>

        </>
    )
}

function AddToJoinBlock(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const navigate = useNavigate()
    const newToJoin = useRef("");
    function updateNewToJoin(e, name){
        newToJoin.current = e.nativeEvent.target.value;
    }
    function saveNewToJoin(){
        updateUser({toJoin: newToJoin.current}, topLevelStates.addNotification, () => navigate(0));
    }

    return (
        <div className="add-to-join-block">
            { topLevelStates.user?.toJoin && !(topLevelStates.user?.activities || []).includes(topLevelStates.user?.toJoin)
                ? 
                    "🟢 我們已經收到您的申請，敬請等待審核。若有任何問題，可以聯絡 contact@infvolunteer.org 或 instagram.com/infinitas.tw"
                : <>
                    <TextBox displayName="活動代碼" onChangeFunction={ updateNewToJoin } />
                    <SubmitButton buttonText="送出並等待審核" onSubmitFunction={ saveNewToJoin } condense />
                    <p className="add-to-join-block-notice">
                        🟡 送出申請後，請等待管理員的審核。根據不同的志工活動規定，可能會有面試的需求，請確定設定頁面的聯絡資料正確，並注意 LINE 或 Email 的訊息。
                    </p>
                </>
            }
        </div>
    )
}

function StudentClassOverview(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const { currentActivitiesClasses, activitiesPublicInfo } = props;

    const [allClassTimeList, setAllClassTimeList] = useState(null);
    useEffect(() => {
        updateAllClassTime();
    }, [JSON.stringify(currentActivitiesClasses), JSON.stringify(activitiesPublicInfo)]);
    useEffect(() => {
        if(allClassTimeList === null && Array.isArray(activitiesPublicInfo)){
            updateAllClassTime();
        }
    });

    function updateAllClassTime(){
        const allClassesList = [];
        Object.keys(currentActivitiesClasses || {}).forEach(actvid => {
            (currentActivitiesClasses[actvid] || []).flat().forEach(studentClass => {
                const newClassObject = Object.assign({}, studentClass);
                newClassObject.actvid = actvid;
                allClassesList.push(newClassObject);
            });
        });
        const newAllClassTimeList = [];
        allClassesList.forEach(studentClass => {
            (studentClass.classTime || []).forEach(classTimeObject => {
                const newStudentClassObject = Object.assign({}, studentClass);
                newStudentClassObject.classTime = classTimeObject;
                newAllClassTimeList.push(newStudentClassObject);
            })
        });
        setAllClassTimeList(newAllClassTimeList);
    }

    const todayDateString = getTodayDateString()
    const [viewingDate, setViewingDate] = useState(todayDateString);
    function updateViewingDate(calendarEvent){
        setViewingDate(calendarEvent.date);
    }

    const viewingDateClasses = useMemo(() => {
        return (allClassTimeList || []).filter(modClassObject => {
            return modClassObject.classTime.date === viewingDate;
        });
    }, [viewingDate, allClassTimeList]);

    const expiredIcoUrl = require.context("../color-ico/")("./expired.svg");

    return (
        <div className="student-class-overview">
            <div className="student-class-overview-left">
                <CalendarView selectedDates={ allClassTimeList?.map(modClassObject => 
                    modClassObject.classTime.date) || [] } rowHeight="35" miniview 
                    clickable viewingDate={ viewingDate } triggerFunction={ updateViewingDate } />
            </div>
            <div className="student-class-overview-right">
                <div className="student-class-overview-right-content">
                    <div className="student-class-overview-date">
                        { viewingDate.replace(/-/g, "/") }
                        { viewingDate === todayDateString ? " (今天)" : null}
                    </div>

                    { viewingDateClasses.map((modClassObject, index) => {
                        return <div className="student-class-overview-class" 
                            key={ "student-class-overview-class-" + modClassObject.classId + makeid(10) }>

                            <div className="student-class-overview-class-title">上課時間：{ modClassObject.classTime?.time }</div>
                            <div className="student-class-overview-class-title">上課科目：</div>
                            <div className="student-class-overview-class-subjects">
                                { (modClassObject.subjects || []).map(subject => {
                                    return <SubjectChip subject={subject} style={{ margin: '5px 2px', display: 'inline-flex' }}
                                        key={ "student-class-overview-class-" + modClassObject.classId + "-" + subject } />
                                }) }
                            </div>

                            <ClassLink showCode classLink={ modClassObject.classLink } />

                        </div>
                    }) }

                    { !viewingDateClasses.length
                        ? <div className="student-class-overview-class student-class-overview-class-empty"
                            style={{ backgroundImage: `url(${expiredIcoUrl})` }} >

                            <div className="student-class-overview-class-notice">該日無上課</div>
                        </div>
                        : null
                    }
                    
                </div>
            </div>
        </div>
    )
}

function ActivityThumbnail(props){
    const { activity, classes } = props;
    const activityStatus = activity.activated ? activity.activated : "planning";
    
    const icoDir = require.context("../ico/");
    const arrowUrl = icoDir("./arrow_right_thin.svg");

    return (
        <div className={ "activity-thumbnail activity-thumbnail-" + activityStatus }>
            <div className="activity-thumbnail-left">
                <h3 className="activity-thumbnail-title">{ activity.name }</h3>
                <p className="activity-thumbnail-description">
                    { activity.description?.length > 40 ? activity.description.substring(0, 40) + "..." : activity.description }
                </p>
                <p className="activity-thumbnail-description">始：{ activity.startTime?.replace(/-/g, "/") }</p>
                <p className="activity-thumbnail-description">終：{ activity.endTime?.replace(/-/g, "/") }</p>
                <p className="activity-thumbnail-description-main">
                    { classes.length 
                        ? `🟢 你有 ${ classes.length ? classes[0].length : 0 } 堂課`
                        : (!props.selectClass ? `🔴 未建制課堂 或 梯次未開始` : `🟢 目前可以進行選課` )
                    }
                </p>
            </div>

            <AccountTypeAccess accessFor={ ["volunteer"] }>
                <Link to={ "/Volunteer?actvid=" + activity.actvid }>
                    <div className="activity-thumbnail-right" style={{ backgroundImage: `url(${arrowUrl})` }}>
                        <div className="activity-thumbnail-right-text">服務頁面</div>
                    </div>
                </Link>
            </AccountTypeAccess>

            <AccountTypeAccess accessFor={ ["student"] }>
                <Link to={ "/SelectClass?actvid=" + activity.actvid }>
                    <div className="activity-thumbnail-right" style={{ backgroundImage: `url(${arrowUrl})` }}>
                        <div className="activity-thumbnail-right-text">前往選課</div>
                    </div>
                </Link>
            </AccountTypeAccess>

            <AccountTypeAccess accessFor={ ["community"] }>
                <Link to={ "/ActivityStudents?actvid=" + activity.actvid }>
                    <div className="activity-thumbnail-right" style={{ backgroundImage: `url(${arrowUrl})` }}>
                        <div className="activity-thumbnail-right-text">學生列表</div>
                    </div>
                </Link>
            </AccountTypeAccess>


        </div>
    )
}


function Resources(props) {
    const subjects = ['國際時事', '數學', '英文', '自然科學', '語言表達', '世界文化', '中文寫作', '國語注音'];
    const grades = [1, 2, 3, 4, 5, 6];
    
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);

    // const files = fs.readdirSync("./public/ttp_ppt/");

    const handleSubjectButtonClick = (subject) => {
        setSelectedSubject(subject);
        setSelectedGrade(null); // Reset selected grade when a new subject is clicked
    };

    const handleGradeButtonClick = (grade) => {
        setSelectedGrade(grade);
    };

    const ttpTopic = ['地緣政治與軍事','科技資訊', '流行文化']

    return (
        <div>
            <DashboardBlock blockTitle="選擇科目及年級">
                <div className="subject-buttons">
                    {/* 不同科目 */}
                    {subjects.map((subject) => (
                        <button
                            key={subject}
                            className={selectedSubject === subject ? 'active' : ''}
                            onClick={() => handleSubjectButtonClick(subject)}
                        >
                            <SubmitButton center buttonText= {subject} condense />
                        </button>
                    ))}
                </div>

                {/* 1-6年級 */}
                <div className="grade-buttons">
                    {grades.map((grade) => (
                        <button
                            key={grade}
                            className={selectedGrade === grade ? 'active' : ''}
                            onClick={() => handleGradeButtonClick(grade)}
                        >
                            <SubmitButton center buttonText= {"grade" + grade} condense />
                        </button>
                    ))}
                </div>
            </DashboardBlock>
            {/* 教材 */}
            {selectedSubject && selectedGrade && (
                <div className="resourceContent">
                    <DashboardBlock>
                    {"請確認科目與年級"}
                    <h2>{selectedSubject} - Grade {selectedGrade}</h2>
                    {/*國際時事(ttp) */}
                    {selectedSubject === "國際時事" && <div>
                            <strong>TTP the Turning Point</strong> 
                            <p> 台灣一直以來不斷地在鼓勵全民與國際接軌，在認識自己土地國家的同時，希望我們能具備基本的國際觀素養，放眼未來並且和世界接軌，除了關心我們所處的台灣外，了解地球的另一端正在發生什麼，更是身為地球村一份子的我們需要關懷的事。以下為我們的PPT，可點擊左下角按鈕下載檔案！</p>
                            <a style={{color: '#35b8bd'}} href="https://ttpsince2000.org/">了解更多</a>
                            <br></br>
                            {/* <a href={process.env.PUBLIC_URL + 'ttp_ppt/' + selectedSubject + '/' + selectedGrade + '/file.pptx'}>ppt</a> */}
                            <br></br>
                            <div className="resourcesContentBlocks">
                            <DashboardBlock className="resourcesContentBlocks">
                                <h2><b>《{ttpTopic[0]}》</b></h2>
                                <h3><b>{ttpTopic[0]}</b> - Lesson 1: WAR ON TERROR 反恐戰爭</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21296&amp;authkey=!AJqFOfuk9X4_rUc&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[0]}</b> - Lesson 2: THE 911 INCIDENT 911恐怖攻擊行動</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21293&amp;authkey=!ADHzP-ORHdcqR40&amp;em=2&amp;wdAr=1.7777777777777777" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[0]}</b> - Lesson 3: Globalization 全球化</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21295&amp;authkey=!ADnZYSnbWGxYMXY&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[0]}</b> - Lesson 4: BRICS 金磚五國</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21294&amp;authkey=!AP0GEjL6yuXH8Go&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                            </DashboardBlock>
                            </div>

                            <div className="resourcesContentBlocks">
                            <DashboardBlock className="resourcesContentBlocks">
                                <h2><b>《{ttpTopic[1]}》</b></h2>
                                <h3><b>{ttpTopic[1]}</b> - Lesson 1: 社群媒體 1.0 Social Media 1.0</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21307&amp;authkey=!AJ64b9QGygZ-_8g&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[1]}</b> - Lesson 2: 社群媒體 2.0 Social Media 2.0</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21308&amp;authkey=!ALl7yxed4HQ4D8k&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[1]}</b> - Lesson 3: 網際網路的興起</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21306&amp;authkey=!ADAP9rsmb8qp_II&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[1]}</b> - Lesson 4: 行動應用程式</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21309&amp;authkey=!AFVyreDzksqRItQ&amp;em=2&amp;wdAr=1.7777777777777777&amp;wdEaaCheck=1" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                </DashboardBlock>
                            </div>

                            <div className="resourcesContentBlocks">
                            <DashboardBlock className="resourcesContentBlocks">
                                <h2><b>《{ttpTopic[2]}》</b></h2>
                                <h3><b>{ttpTopic[2]}</b> - Lesson 1: 哈利波特和魔法世界 Harry Potter and the Wizarding World</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21315&amp;authkey=!AAjXnoxNJYa3iO4&amp;em=2&amp;wdAr=1.7777777777777777" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[2]}</b> - Lesson 2: 魔戒 The Lord of the Rings</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21314&amp;authkey=!AG1OR1xmhsS4U0E&amp;em=2&amp;wdAr=1.7777777777777777" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                                <br></br>
                                <h3><b>{ttpTopic[2]}</b> - Lesson 3: 韓國流行音樂 K-Pop</h3>
                                <iframe src="https://onedrive.live.com/embed?resid=ADEA7B6C4B017C1%21316&amp;authkey=%21ALLDaYLUakXXH9o&amp;em=2&amp;wdAr=1.7777777777777777" width="400px" height="250px" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>
                            </DashboardBlock>
                            </div>
                        </div> 
                        }</DashboardBlock>
                </div>
            )}
        </div>
    );
}


export default Dashboard;