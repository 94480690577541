import { initializeApp } from 'firebase/app';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyAbHt39fvQ19jWSg2mTprBdB-mBCbczbD4",
  authDomain: "infvolunteer-web.firebaseapp.com",
  projectId: "infvolunteer-web",
  storageBucket: "infvolunteer-web.appspot.com",
  messagingSenderId: "474770392318",
  appId: "1:474770392318:web:e2cf16fbd56438677b51af",
  measurementId: "G-3RMKKB8E89"
});

  