import "../style/CommunityBanner.css";
import { getStorage, ref, getDownloadURL } from "@firebase/storage";
import { useState } from "react";

export default function CommunityBanner(props){

    const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
    const communityReferenceId = props.communityReferenceId;

    if(communityReferenceId && backgroundImageUrl === null){
        const storage = getStorage();
        getDownloadURL(ref(storage, `/user_upload/community/community_cover-${communityReferenceId}.png`))
            .then(url => {
                setBackgroundImageUrl(url);
            });
    }
    const style = Object.assign({}, props.style) || {};
    if(props.width) style["width"] = props.width + "px";
    if(props.height) style["height"] = props.height + "px";
    style.backgroundImage = `url(${backgroundImageUrl})`;

    return (
        <div className="community-banner" style={ style } onClick={ props.onClick || (() => {}) }>
            <div className="community-banner-content-wrap">
                <div className="community-banner-title">{ props.bannerTitle }</div>
                <div className="community-banner-subtitle">{ props.bannerSubtitle }</div>
            </div>
        </div>
    )
}