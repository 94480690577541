import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { CoinDisplay } from "../components/Coin";
import { CommentSection } from "../components/Chat";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { SelectionList } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import MinorPaneView from "../components/MinorPaneView";
import { getStudentsFromCommunity, getUserByReferenceId } from "../lib/user";
import "../style/StudentProfile.css";
import { strings } from "../lib/strings";

export default function StudentProfile(props){

    const [searchParams, setSearchParams] = useSearchParams();
    const studentReferenceId = searchParams.get("studentReferenceId");
    const communityReferenceId = searchParams.get("communityReferenceId");
    const recordReferenceId = searchParams.get("recordReferenceId");

    return (
        <DashboardFrame selected="dashboard" full>
            <StudentRecordSnipset studentReferenceId={ studentReferenceId } 
                communityReferenceId={ communityReferenceId } recordReferenceId={ recordReferenceId || "" } />
        </DashboardFrame>
    )
}

export function StudentRecordSnipset(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const { communityReferenceId, studentReferenceId } = props;
    const [studentPersonalData, setStudentPersonalData] = useState(null);
    const [communityStudentData, setCommunityStudentData] = useState(null);

    const [sortingMethod, setSortingMethod] = useState("time");
    const sortedStudentRecords = useMemo(() => {
        if(communityStudentData === null) return [];
        const studentRecords = communityStudentData.records || [];
        if(sortingMethod === "time"){
            return studentRecords.sort((a, b) => (a.date < b.date ? 1 : -1));
        }else if(sortingMethod === "length"){
            return studentRecords.sort((a, b) => {
                return Object.values(b).join("").length - Object.values(a).join("").length
            })
        }
    }, [sortingMethod, communityStudentData]);

    useEffect(() => {
        if(studentPersonalData === null && communityStudentData === null){
            getStudentsFromCommunity(communityReferenceId, [studentReferenceId], null, (returnedList) => {
                setCommunityStudentData(returnedList[0]);
            });
            getUserByReferenceId(studentReferenceId, setStudentPersonalData);
        }
    }, [topLevelStates.hasLoaded]);

    const targetRecordBlock = useRef();
    useEffect(() => {
        targetRecordBlock.current && targetRecordBlock.current.scrollIntoView();
    }, [props.recordReferenceId, sortedStudentRecords]);

    return (
        <MinorPaneView style={{ padding: '30px 0' }} 
            side={ <SidePanes studentPersonalData={ studentPersonalData }
                communityStudentData={ communityStudentData }
                setSortingMethod={ setSortingMethod } /> }>
            <DashboardBlock blockTitle="🟢 學生教學紀錄頁面">
                每次上課完畢後，志工老師們會填寫一份教學資料，紀錄該日的上課狀況。家長可於各紀錄下方填寫給予志工老師的回饋。
            </DashboardBlock>

            { sortedStudentRecords.map((studentRecord, index) => {
                const isTarget = studentRecord.recordReferenceId === props.recordReferenceId;
                return <DashboardBlock key={ "teaching-record-block-" + index } 
                    blockTitle={ studentRecord.date.replace(/-/g, "/") }
                    selected={ isTarget }>
                    <div className="teaching-record-volunteer-name" ref={ isTarget ? targetRecordBlock : null }>
                        { studentRecord.volunteerName }
                    </div>
                    <IconTextPair svgName="time">上課時間：{ studentRecord.classLength || "無紀錄" }</IconTextPair>
                    <IconTextPair svgName="time">備課時間：{ studentRecord.prepLength || "無紀錄" }</IconTextPair>
                    <TeachingRecordSection title={ "教學紀錄" } content={ studentRecord.teachingRecord } />
                    <TeachingRecordSection title={ "生活關懷" } content={ studentRecord.lifeRecord } />
                    <TeachingRecordSection title={ "其他紀錄" } content={ studentRecord.otherRecord } /><br />
                    <CommentSection conversationId={ studentRecord.conversationId }
                        originalTeachingRecord={ studentRecord }
                        communityReferenceId={ communityReferenceId }
                        studentReferenceId={ studentReferenceId } />
                </DashboardBlock>
            }) }

        </MinorPaneView>
    )
}

function TeachingRecordSection(props){
    if(!props.content) return null;
    return (
        <>
            <h3 className="teaching-record-section-title">
                { props.title }
            </h3>
            <div className="teaching-record-section-content">
                { props.content }
            </div>
        </>
    )
}

function SidePanes(props){
    const studentPersonalData = props.studentPersonalData || {};
    const communityStudentData = props.communityStudentData || {};

    const sortingMethodOptions = [
        {displayName: "依時間排序", value: "time", default: true},
        {displayName: "依教學紀錄長度排序", value: "length"},
    ]

    return (
        <>
            <DashboardBlock blockTitle={ `${communityStudentData.name}（${studentPersonalData.sex}）` || "未填姓名" }>
                <CoinDataSplit studentPersonalData={ studentPersonalData }
                    communityStudentData={ communityStudentData } />
            </DashboardBlock>
            <DashboardBlock blockTitle="瀏覽紀錄選項">
                <SelectionList displayName="排序模式" options={ sortingMethodOptions }
                    name="sortingMethod"
                    onChangeFunction={ (e) => props.setSortingMethod(e.nativeEvent.target.value) } />
            </DashboardBlock>
        </>
    )
}

export function CoinDataSplit(props){
    const communityStudentData = props.communityStudentData || {};
    const studentPersonalData = props.studentPersonalData || {};

    console.log(communityStudentData);

    return (
        <div className="coin-data-split">
            <div className="coin-data-split-left">
                <CoinDisplay coins={ communityStudentData.coins || 0 } />
            </div>
            <div className="coin-data-split-right">
                <IconTextPair svgName="age">{ strings[studentPersonalData.grade] }</IconTextPair>
                <IconTextPair svgName="school">{ studentPersonalData.school }</IconTextPair>
                <IconTextPair svgName="focus" wrap>{ studentPersonalData.need || "未填服務需求" }</IconTextPair>
                <IconTextPair svgName="information" wrap>{ communityStudentData.note || "無狀況資料" }</IconTextPair>
            </div>
        </div>
    )
}