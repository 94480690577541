import { useState } from "react";
import { getDatesFromDateTime, getTodayDateString } from "../lib/dates";
import CalendarView from "./CalendarView";
import { TableFrame, TableRow } from "./Table";

export default function CalendarTimeView(props){

    const classTime = props.classTime || [];
    const classDates = getDatesFromDateTime(classTime);

    const [viewingDate, setViewingDate] = useState(getTodayDateString());
    const timeOnThisDate = [];
    classTime.forEach(classTimeObject => {
        if(classTimeObject.date === viewingDate){
            timeOnThisDate.push(classTimeObject.time);
        }
    })

    return (
        <div className="class-time-view-container" style={ props.style }>
            <CalendarView clickable rowHeight={ props.rowHeight ? props.rowHeight : "45" } selectedDates={ classDates }
                viewingDate={ viewingDate } miniview={ !!props.miniview }
                triggerFunction={ (calendarEvent) => setViewingDate(calendarEvent.date) } />
            <br />
            { viewingDate || props.constantView ?
                <TableFrame columns={ [{content: viewingDate.replace(/-/g, "/"), width: 500}] } noScroll
                    condense rowHeight="45" titleRowHeight="45" >
                    { timeOnThisDate.map((timeString, index) => {
                        return <TableRow key={ "class-time-view-row-" + index } content={[
                            <div className="class-time-view-row">{ timeString }</div>
                        ]} />
                    }) }
                    { timeOnThisDate.length === 0
                        ? <TableRow content={[
                            <div className="class-time-view-row">該日無上課時間</div>
                        ]} />
                    : null }
                </TableFrame>
            : null }
        </div>
    )
}