import { getFirestore, updateDoc, setDoc, doc, getDoc, arrayUnion } from "@firebase/firestore";
import { getDateStringFromDate, getTimeStringFromDate } from "./dates";
import makeid from "./makeid";
import { updateTeachingRecordsInCommunity } from "./teachingRecords";


/**
 * Creates a new conversation in Firestore, within the collection
 * `communityStudents/${communityReferenceId}/messages/`, adds the
 * `conversationId` to the teaching record, and then returns the 
 * `conversationId` through the thenFunction
 * @param {string} communityReferenceId
 * @param {string} studnetReferenceId
 * @param {string} message
 * @param {object} originalTeachingRecord
 * @param {string} senderReferenceId
 * @param {string} senderName
 * @param {function} thenFunction
 */
export async function createConversationAndConnectToTeachingRecord(communityReferenceId, 
    studnetReferenceId, message, originalTeachingRecord, senderReferenceId, senderName,
    thenFunction=null){
    const db = getFirestore();

    const conversationId = makeid(15);
    const newTeachingRecord = Object.assign({
        conversationId: conversationId
    }, originalTeachingRecord);
    updateTeachingRecordsInCommunity(communityReferenceId, studnetReferenceId, 
        originalTeachingRecord, newTeachingRecord);

    setDoc(doc(db, `communityMessages/${communityReferenceId}/conversations/${conversationId}`), {
        conversationId: conversationId,
        messages: [{
            content: message,
            senderReferenceId: senderReferenceId,
            senderName: senderName,
            timestamp: {
                date: getDateStringFromDate(new Date()),
                time: getTimeStringFromDate(new Date())
            }
        }]
    }).then(() => {
        thenFunction && thenFunction(conversationId);
    })
}


/**
 * Adds a message to an existing conversation, and returns the message 
 * object through the thenFunction after completion, given that it succeeds
 * @param {string} communityReferenceId 
 * @param {string} conversationId 
 * @param {string} message 
 * @param {string} senderReferenceId 
 * @param {string} senderName 
 * @param {function} thenFunction 
 */
export async function addMessageToConversation(communityReferenceId, conversationId, 
    message, senderReferenceId, senderName, thenFunction=null){

    const messageObject = {
        content: message,
        senderReferenceId: senderReferenceId,
        senderName: senderName,
        timestamp: {
            date: getDateStringFromDate(new Date()),
            time: getTimeStringFromDate(new Date())
        }
    };

    const db = getFirestore();
    setDoc(doc(db, `communityMessages/${communityReferenceId}/conversations/${conversationId}`), {
        messages: arrayUnion(messageObject)
    }, {merge: true}).then(() => thenFunction(messageObject));
}


/**
 * Returns an array of conversation objects
 * @param {string} communityReferenceId
 * @param {string} conversationId
 * @param {function} thenFunction
 */
export async function getConversation(communityReferenceId, conversationId, thenFunction=null){
    const db = getFirestore();
    const conversationSnap = await getDoc(doc(db, `communityMessages/${communityReferenceId}/conversations/${conversationId}`));
    if(conversationSnap.exists()){
        console.log(conversationSnap.data());
        thenFunction && thenFunction(conversationSnap.data()["messages"]);
    }
}


/**
 * Sends a LINE message to a specific referenceId
 * @param {object} information
 * @param {string} information.message
 * @param {string} information.pwd
 * @param {string} information.target
 */
export async function sendLINEMessage(information, notificationFunction=null, thenFunction=null){
    console.log(information);
    notificationFunction && notificationFunction("正在傳送訊息...")
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://us-central1-infvolunteer-web.cloudfunctions.net/requestHandler?invoke=messaging");
    xhr.onload = () => {
        notificationFunction && notificationFunction("傳送完成！");
        thenFunction && thenFunction();
    }
    xhr.send(JSON.stringify(information));
}