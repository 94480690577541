import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardBlock from "../components/DashboardBlock";
import DashboardFrame from "../components/DashboardFrame";
import { DateBox, SelectionList, SubmitButton, TextAreaBox } from "../components/Forms";
import MinorPaneView from "../components/MinorPaneView";
import makeid from "../lib/makeid";
import { addTeachingRecord } from "../lib/teachingRecords";
import { getStudentsFromCommunity, getUserByReferenceId } from "../lib/user";
import { CoinDataSplit } from "./StudentProfile";

export default function AddRecord(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const communityReferenceId = searchParams.get("communityReferenceId");
    const studentReferenceId = searchParams.get("studentReferenceId");

    const [studentPersonalData, setStudentPersonalData] = useState(null);
    const [communityStudentData, setCommunityStudentData] = useState(null);

    useEffect(() => {
        if(studentPersonalData === null){
            getStudentsFromCommunity(communityReferenceId, [studentReferenceId], null, (returnedList) => {
                setCommunityStudentData(returnedList[0]);
            });
            getUserByReferenceId(studentReferenceId, setStudentPersonalData);
        }
    });

    const timeOptions = [];
    for(let i = 10; i <= 120; i += 10){
        timeOptions.push({
            value: i,
            displayName: i + " 分鐘",
        })
    }
    const classTimeOptions = [...timeOptions, {
        value: 20,
        displayName: "學生未上線（以 20 分鐘計算時數）",
    }];

    const uploadContent = useRef({
        classLength: 10,
        prepLength: 10
    })
    function uploadContenChanged(e, name){
        uploadContent.current[name] = e.nativeEvent.target.value;
    }

    const navigate = useNavigate()
    function uploadRecord(){
        const finalizedRecord = Object.assign(uploadContent.current, {
            volunteerReferenceId: topLevelStates.user.referenceId,
            volunteerName: topLevelStates.user.name || topLevelStates.user.userDisplayName,
            recordReferenceId: makeid(15),
        });
        const fields = ["classLength", "prepLength", "date", "teachingRecord", "lifeRecord"];
        for(const field of fields){
            if(!finalizedRecord[field]){
                topLevelStates.addNotification("請填妥每個欄位。");
                return;
            }
        }
        addTeachingRecord(finalizedRecord, communityReferenceId, studentReferenceId,
            topLevelStates.addNotification, () => navigate(0))
    }

    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["volunteer", "community", "admin"] }>
                <MinorPaneView style={{ padding: '30px 0' }}
                    side={ <SidePanes studentPersonalData={ studentPersonalData }
                    communityStudentData={ communityStudentData } communityReferenceId={ communityReferenceId } /> }>

                    <DashboardBlock blockTitle="新增教學紀錄">
                        <DateBox displayName="上課日期（注意：非填寫時的日期）"
                            name="date" onChangeFunction={ uploadContenChanged } />
                        <SelectionList displayName="上課時間長度" options={ classTimeOptions }
                            name="classLength" onChangeFunction={ uploadContenChanged } />
                        <SelectionList displayName="備課時間長度" options={ timeOptions }
                            name="prepLength" onChangeFunction={ uploadContenChanged }  />
                        <TextAreaBox displayName="教學內容"
                            placeholder="服務內容、進度、重點、孩童學習狀況、動力、態度、專注力、對照顧者的觀察等"
                            name="teachingRecord" onChangeFunction={ uploadContenChanged }/>
                        <TextAreaBox displayName="生活關懷 / 其他狀況"
                            placeholder="與孩童聊天的內容、對孩童的觀察、喜怒哀樂、心情故事、學校或家庭的大小事等"
                            name="lifeRecord" onChangeFunction={ uploadContenChanged }/>
                        <SubmitButton buttonText="確認送出"
                            onSubmitFunction={ uploadRecord } />
                    </DashboardBlock>

                </MinorPaneView>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function SidePanes(props){
    const studentPersonalData = props.studentPersonalData || {};
    const communityStudentData = props.communityStudentData || {};

    return (
        <>
            <DashboardBlock blockTitle={ `${communityStudentData.name}（${studentPersonalData.sex}）` || "未填姓名" }>
                <CoinDataSplit studentPersonalData={ studentPersonalData }
                    communityStudentData={ communityStudentData } />
                <Link to={ "/StudentProfile?studentReferenceId=" + studentPersonalData.referenceId + "&communityReferenceId=" + props.communityReferenceId }>
                    <SubmitButton center buttonText="前往教學紀錄頁面" />
                </Link>
            </DashboardBlock>
        </>
    )
}