import { getAuth, signOut } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import "../style/LeftNav.css";
import AccountTypeAccess from "./AccountTypeAccess";

function LeftNav(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const [displayQuickAction, setDisplayQuickAction] = useState(false);
    function closeQuickAction(){
        setDisplayQuickAction(false);
    }

    return (
        <nav className="left-nav">
            <div className="account-name-overlay">{ topLevelStates?.user?.name || "未填姓名" }</div>
            <div className="nav-block infinitas hidden"></div>
            <div className="nav-block hidden"></div>
            <Link to="/Dashboard" className="nav-block-link">
                <div className={"nav-block dashboard " + (props.selected == "dashboard" ? "left-nav-selected" : "")}></div>
            </Link>
            {/* <Link to="/Explore" className="nav-block-link">
                <div className={"nav-block explore " + (props.selected == "explore" ? "left-nav-selected" : "")}></div>
            </Link> */}
            <div className="nav-block-link" onClick={ () => {setDisplayQuickAction(!displayQuickAction)} }>
                <div className="nav-block tools"></div>
            </div>
            <Link to="/Setting" className="nav-block-link">
                <div className={"nav-block settings " + (props.selected == "setting" ? "left-nav-selected" : "")}></div>
            </Link>
            <LeftNavQuickAction display={ displayQuickAction }
                closeQuickAction={ closeQuickAction }></LeftNavQuickAction>
        </nav>
    );
}

function LeftNavQuickAction(props){
    const topLevelStates = useContext(topLevelStatesContext);

    function closeQuickAction(event){
        if(event.target === event.currentTarget){
            props.closeQuickAction();
        }
    }
    
    const [studentData, setStudentData] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    
    const userType = topLevelStates.user.userType;
    const actvid = topLevelStates.user.actvid;
    let studentReferenceId = searchParams.get("studentReferenceId");

    useEffect(() => {
        // getStudentFromActivityAndCommunity(topLevelStates, setStudentData, studentReferenceId);
    }, [topLevelStates.hasLoaded]);

    studentReferenceId = studentData?.activityData ? 
        studentData.activityData.studentReferenceId : studentReferenceId;

    if(!props.display) return null;

    function switchAccount(){
        const auth = getAuth();
        signOut(auth).then(() => {
            window.location.href = "/Login";
        }).catch((e) => {
            window.location.href = "/Login";
        })
    }

    return (
        <div className="left-nav-quick-action-bottom"
            onClick={ (e) => closeQuickAction(e) }>
            <div className="left-nav-quick-action">
                <h5 className="left-nav-quick-action-title">快速連結</h5>
                <div className="left-nav-quick-action-container">

                    <AccountTypeAccess accessFor={ ["community"] }>
                        <LeftNavQuickActionButton svgName="home" buttonText="首頁總覽"
                            to="/Dashboard" />
                        <LeftNavQuickActionButton svgName="contacts" buttonText="學生名單"
                            to="/ActivityStudents?actvid=[actvid]" />
                        <LeftNavQuickActionButton svgName="settings" buttonText="設定頁面"
                            to="/Setting" />
                        <LeftNavQuickActionButton svgName="list" buttonText="活動列表"
                            to="/CommunityStudents?tab=activities" />
                        <LeftNavQuickActionButton svgName="department" buttonText="社區學生"
                            to="/CommunityStudents" />
                        <LeftNavQuickActionButton svgName="process" buttonText="登出/切換"
                            onClick={ switchAccount } />
                    </AccountTypeAccess>

                    <AccountTypeAccess accessFor={ ["volunteer"] }>
                        <LeftNavQuickActionButton svgName="home" buttonText="首頁總覽"
                            to="/Dashboard" />
                        <LeftNavQuickActionButton svgName="like" buttonText="服務頁面"
                            to="/Volunteer?actvid=[actvid]" />
                        <LeftNavQuickActionButton svgName="settings" buttonText="設定頁面"
                            to="/Setting" />
                        <LeftNavQuickActionButton svgName="process" buttonText="登出/切換"
                            onClick={ switchAccount } />
                    </AccountTypeAccess>

                    <AccountTypeAccess accessFor={ ["student"] }>
                        <LeftNavQuickActionButton svgName="home" buttonText="首頁總覽"
                            to="/Dashboard" />
                        <LeftNavQuickActionButton svgName="kindle" buttonText="學習紀錄"
                            to={ `/StudentProfile?studentReferenceId=${topLevelStates.user.referenceId}&communityReferenceId=${topLevelStates.activitiesPublicInfo?.length ? topLevelStates.activitiesPublicInfo[0].communityReferenceId : ""}` }  />
                        <LeftNavQuickActionButton svgName="process" buttonText="登出/切換"
                            onClick={ switchAccount } />
                        <LeftNavQuickActionButton svgName="settings" buttonText="設定頁面"
                            to="/Setting" />
                    </AccountTypeAccess>

                </div>
            </div>
        </div>
    )
}

function LeftNavQuickActionButton(props){
    const topLevelStates = useContext(topLevelStatesContext);
    const colorIcoDir = require.context("../color-ico/");
    const actvid = topLevelStates?.user?.actvid;
    const referenceId = topLevelStates?.user?.referenceId;
    const buttonBody = <div className="left-nav-quick-action-button" 
        onClick={ !props.to ? props.onClick : () => {} } style={{ display: 'inline-block' }}>
        <div className="left-nav-quick-action-button-left" style={{ 
            backgroundImage: `url(${colorIcoDir("./" + props.svgName + ".svg")})` }} />
        <div className="left-nav-quick-action-button-right">{ props.buttonText }</div>
    </div>

    if(props.to){
        const toLink = props.to
            .replace(/\[actvid\]/g, actvid)
            .replace(/\[referenceId\]/g, referenceId);
        return (
            <Link to={ toLink } style={{ display: 'inline-block' }}>
                { buttonBody }
            </Link>
        )
    }else{
        return buttonBody
    }
}

export default LeftNav;