import { useState } from "react";
import "../style/ExpandDropDown.css";

export function ExpandDropDown({ expanded=false, title="點擊可展開", locked=false, children }){

    const [expandedState, setExpandedState] = useState(expanded);

    return (
        <div className="expand-drop-down">
            <div className="expand-drop-down-top" 
                onClick={ () => !locked && setExpandedState(!expandedState) }>
                <div className="expand-drop-down-top-title">
                    { title }
                </div>
                <div className="expand-drop-down-top-indicator" />
            </div>
            { expandedState ? 
                <div className="expand-drop-down-content">
                    { children }
                </div>
            : null }
            
        </div>
    )
}