import { useEffect, useRef, useState } from "react";
import ClassTimeList from "./ClassTimeList";
import { DatesSelection, SelectionList, SubjectsSelection, SubmitButton, TextBox } from "./Forms";

export function EditClassVolunteer(props){
    const { activityClass, allVolunteers } = props;

    const [volunteerReferenceId, setVolunteerReferenceId] = useState(activityClass.volunteerReferenceId);
    function updateVolunteerReferenceId(e, name){
        setVolunteerReferenceId(e.nativeEvent.target.value);
    }    

    const classVolunteerSelection = [{
        displayName: "無",
        value: null,
        default: true
    }];
    Object.keys(allVolunteers).forEach(thisVolunteerReferenceId => {
        classVolunteerSelection.push({
            displayName: `${allVolunteers[thisVolunteerReferenceId]?.name}（${thisVolunteerReferenceId}）`,
            value: thisVolunteerReferenceId
        });
    });

    return (
        <>
            <SubmitButton buttonText="儲存上課老師" condense
                onSubmitFunction={ () => props.updateClass({volunteerReferenceId: volunteerReferenceId}) } />
            <SelectionList options={ classVolunteerSelection }
                valueSet={{ volunteerReferenceId: volunteerReferenceId }} 
                name="volunteerReferenceId"
                onChangeFunction={ updateVolunteerReferenceId } />
        </>
    )
}

export function EditClassStudents(props){
    const { activityClass, communityStudents } = props;

    const [volunteerHandle, setVolunteerHandle] = useState(activityClass?.volunteerHandle || 1);
    function updateVolunteerHandle(e, name){
        setVolunteerHandle(e.nativeEvent.target.value);
    }

    const students = activityClass.students;
    const slotStudentsMap = useRef(Object.assign({}, students));
    useEffect(() => {
        slotStudentsMap.current = Object.assign({}, students);
    }, [activityClass.students]);

    function studentChanged(e, index){
        const newSlotValue = e.nativeEvent.target.value === "無" ? null : e.nativeEvent.target.value;
        slotStudentsMap.current[index] = newSlotValue;
    }

    function getStudentsFromSlots(){
        const returnList = [];
        for(let i = 0; i < (volunteerHandle || 1); i++){
            if(slotStudentsMap.current[i]) returnList.push(slotStudentsMap.current[i]);
        }
        return returnList;
    }

    function getOutput(){
        const returnObject = {};
        returnObject.volunteerHandle = volunteerHandle;
        returnObject.students = getStudentsFromSlots();
        return returnObject;
    }


    const volunteerHandleSelection = [
        { displayName: "一位", value: 1, default: true },
        { displayName: "二位", value: 2 },
        { displayName: "三位", value: 3 },
        { displayName: "四位", value: 4 },
        { displayName: "五位", value: 5 },
        { displayName: "六位", value: 6 },
        { displayName: "七位", value: 7 },
        { displayName: "八位", value: 8 },
        { displayName: "九位", value: 9 },
        { displayName: "十位", value: 10 },
    ];

    const classStudentSelection = [
        {displayName: "無", value: null, default: true}
    ];
    Object.keys(communityStudents).forEach(studentReferenceId => {
        classStudentSelection.push({
            value: studentReferenceId,
            displayName: `${communityStudents[studentReferenceId].name}（${studentReferenceId}）`
        })
    });
    const classStudentsSelectionLists = [];
    for(let i = 0; i < (volunteerHandle || 1); i++){
        const thisStudent = {};
        if(i < (students || []).length){
            thisStudent.studentReferenceId = students[i];
        }
        classStudentsSelectionLists.push(
            <SelectionList key={ "activity-class-students-selection-" + i }
                options={ classStudentSelection } name="studentReferenceId"
                valueSet={ thisStudent } onChangeFunction={ (e) => studentChanged(e, i) } />
        )
    }

    return (<>
        <SubmitButton buttonText="儲存上課學生" condense
            onSubmitFunction={ () => props.updateClass(getOutput()) } />
        <SelectionList displayName="志工最大負載" 
            options={ volunteerHandleSelection }
            valueSet={ activityClass } name="volunteerHandle"
            onChangeFunction={ updateVolunteerHandle } />
        
        { classStudentsSelectionLists }

    </>)
}

export function SelectClassSubjects(props){
    const { activityClass } = props;
    const subjects = useRef(activityClass.subjects);
    useEffect(() => {
        subjects.current = activityClass.subjects;
    }, [activityClass.subjects])
    return (
        <>
            <SubmitButton buttonText="儲存上課科目" condense 
                onSubmitFunction={ () => props.updateClass({subjects: subjects.current}) } />
            <SubjectsSelection selectedSubjects={ subjects.current }
                onChangeFunction={ (newSubjects) => subjects.current = newSubjects } />
        </>
    )
}

export function EditClassLink(props){
    const { activityClass } = props;
    const classLink = useRef(activityClass.classLink);
    useEffect(() => {
        classLink.current = activityClass.classLink;
    }, [activityClass.classLink]);
    return (
        <>
            <SubmitButton buttonText="儲存上課連結" condense
                onSubmitFunction={ () => props.updateClass({ classLink: classLink.current }) } />
            <TextBox valueSet={ activityClass } name="classLink"
                displayName="設置上課連結"
                onChangeFunction={ (e) => classLink.current = e.nativeEvent.target.value } />
        </>
    )
}

export function EditClassTime(props){
    const { activityClass } = props;
    const classTime = useRef(activityClass.classTime);
    useEffect(() => {
        classTime.current = activityClass.classTime;
    }, [activityClass.classTime]);
    return (
        <>
            <SubmitButton buttonText="儲存上課時間" condense
                onSubmitFunction={ () => props.updateClass({ classTime: classTime.current }) } />
            <DatesSelection displayName="重新選擇上課時間"
                onChangeFunction={ (newClassTime) => classTime.current = newClassTime } />
        </>
    )
}