import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { useSearchParams } from "react-router-dom";
import { GoogleSignInButton, FacebookSignInButton } from "../components/SignInButton";
import { addUser } from "../lib/user";
import makeid from "../lib/makeid";
import "../style/Login.css";
import Cookies from "js-cookie";

function Login(){

    const googleAuthProvider = new GoogleAuthProvider();
    const facebookAuthProvider = new FacebookAuthProvider();
    const auth = getAuth();

    const [searchParams, setSearchParams] = useSearchParams();
    const userType = searchParams.get("userType");
    const toJoin = searchParams.get("toJoin");
    const redirectLocation = searchParams.get("redirect") || "/Dashboard";

    function googleSignIn(userType){
        signInWithPopup(auth, googleAuthProvider)
          .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            addUser(user.uid, user.displayName, user.email, userType, makeid(15), redirectLocation, toJoin);
            Cookies.set('isLoggedIn', 'true', {path: '/'});
          }).catch((error) => {
              // Add error handler
          });
    }
    
    function facebookSignIn(userType){
        signInWithPopup(auth, facebookAuthProvider)
          .then((result) => {
            const credential = facebookAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            const user = result.user;
            addUser(user.uid, user.displayName, user.email, userType, makeid(15), redirectLocation, toJoin);
            Cookies.set('isLoggedIn', 'true', {path: '/'});
          }).catch((error) => {
            // Add error handler
          });
    }

    return (
        <div className="bottom" style={{display: "flex", height: "100vh",}}>
            <div className="left-block">
                <div className="logo-block"></div>
                <div className="slogan-block">學習無界<br />影響無限</div>
            </div>
            <div className="right-blcok">
                <div className="right-section">
                    <div className="login-title">登入 / 註冊</div>
                    <GoogleSignInButton userType={ userType } signInFunction={ googleSignIn }></GoogleSignInButton>
                    {/* <FacebookSignInButton userType={ userType } signInFunction={ facebookSignIn }></FacebookSignInButton> */}
                    <div className="right-hint">
                        <h3 className="right-hint-title">🙁 無法登入嗎？</h3>
                        <p className="right-hint-content">
                            從手機版 LINE 點擊平台的連結後，將會由 LINE 內的嵌入式瀏覽器開啟連結，然而，Google 登入並不支援 LINE 的嵌入式瀏覽器，因此請您：
                        </p>
                        <p className="right-hint-content">
                            1. 點擊右下角的選單圖示
                        </p>
                        <p className="right-hint-content">
                            2. 選擇「用預設瀏覽器開啟」
                        </p>
                        <p className="right-hint-content">
                            3. 進入瀏覽器後，請重新登入。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login; 