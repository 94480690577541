
export default function DashboardBlock(props){
    return (
        <div className={ "dashboard-block " + (props.selected ? "dashboard-block-selected " : "")}>
            <h2 className="dashboard-block-title" 
                style={ props.blockTitleLang == "en" ? {'letterSpacing': '0'} : {}}>
                {props.blockTitle}
            </h2>
            { !props.noSubtitle
                ? <p className="dashboard-block-subtitle">{props.blockSubtitle}</p>
            : null }
            
            <div className="dashboard-block-content" 
                style={ props.overflowXVisible ? {'overflowX': 'visible'} : {} }>
                {props.children}
            </div>
        </div>
    );
}