import { useContext } from "react";
import { topLevelStatesContext } from "../App";
import "../style/AccountStatusBlock.css";

export function AccountStatusBlock({ studentPersonalData, showLink, communityReferenceId, studentReferenceId, actvid }){
    const topLevelStates = useContext(topLevelStatesContext);
    const accountRegistered = !!Object.keys(studentPersonalData)?.length;
    const lineVerified = accountRegistered ? !!studentPersonalData.lineUserId?.length : false;

    let displayString;
    if(!accountRegistered) displayString = "🔴 帳號未建置";
    else if(!lineVerified) displayString = "🟡 尚未連結 LINE";
    else displayString = "🟢 帳號已建置";

    function copyLink(){
        let message = `家長您好，學生課後輔導活動即將開始，我們將會使用一個線上志工平台來管理學生與老師的出缺勤紀錄，方便您與志工老師的溝通。我們已經為您準備好了一個連結，請您於點擊連結後依照指示完成註冊。註冊完成後，未來您將不再需要註冊帳號。 連結： https://infvolunteer.org/parentRegister?studentReferenceId=${studentReferenceId}`;
        if(actvid) message += `&actvid=${actvid}`;
        navigator.clipboard.writeText(message);
        topLevelStates.addNotification("已複製至剪貼簿");
    }
    
    return (
        <div className="account-status-block">
            { displayString }
            { showLink && !accountRegistered
                ? <div className="account-status-block-register-link" onClick={ copyLink }>
                    複製申請連結
                </div>
            :null }
        </div>
    )
}

