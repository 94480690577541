import "../style/BaseFooter.css";

export default function BaseFooter(props){
    return (
        <footer>
            <div className="footer-logo"></div>
            <div className="footer-block footer-block-contact">contact@infvolunteer.org</div>
            <div className="footer-block footer-block-contact">instagram.com/infinitas.tw</div>
            <br/>
            <div className="footer-block">Made with 💜 for Education.</div>
            <div className="footer-block">&copy; Infinitas 學生志工計畫 保留權利</div>
        </footer>
    )
}