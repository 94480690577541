import "../style/Popup.css";

export function Popup(props){
    if(props.mode === "display" && !props.showing) return <></>;
    return (
        <div className="popup-underlay"
            style={props.showing ? {display: 'block'} : {display: 'none'}}>
            <div className={ "popup " + 
                (props.tall ? "popup-tall " : " ") +
                (props.wide ? "popup-wide " : " ") +
                (props.thin ? "popup-thin " : " ") }>
                <div className="popup-top">
                    <div className="popup-top-close" 
                        onClick={ () => {
                            props.closePopupFunction && props.closePopupFunction()}
                        } />
                </div>
                <div className="popup-content">
                    { props.children }
                </div>
            </div>
        </div>
    );
}

export function PopupSection(props){
    return(
        <div className={ "popup-section " + 
            (props.sticky ? "popup-section-sticky" : "") } 
            style={ props.style }>
            <h3 className="popup-section-title">{ props.sectionTitle }</h3>
            <p className="popup-section-subtitle">{ props.sectionSubtitle }</p>
            <div className={ (props.flex ? "popup-flex" : "") }>
                { props.children }
            </div>
        </div>
    );
}