import { getFirestore, collection, query, orderBy, getDocs, limit, startAfter, where, FieldPath, getDoc, doc, updateDoc, arrayUnion } from "@firebase/firestore";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import DashboardFrame from "../components/DashboardFrame";
import { CheckBox, SelectionList, SubmitButton, SubmitButtonCenter, TextBox } from "../components/Forms";
import IconTextPair from "../components/IconTextPair";
import { TableFrame, TableRow, TableRowFull } from "../components/Table";
import TopNavTabs from "../components/TopNavTabs";
import UserSideView from "../components/UserSideView";
import { createActivity, getActivitiesByCommunity, getBillboardData, getUsersByReferenceIds, getUsersByType, setBillboardFieldData } from "../lib/user";
import "../style/Communities.css";
import "../style/Users.css";
import "../style/Admin.css";
import DashboardBlock from "../components/DashboardBlock";
import makeid from "../lib/makeid";

export default function Admin(props){
    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["admin"] }>
                <UserSideView>
                    <TopNavTabs pageTitle="Admin管理頁面" pageSubtitle="Infinitas 學生志工計畫"
                        tabs={ [
                            {displayName: "使用者列表", content: <UserList />},
                            {displayName: "社區使用者與梯次", content: <CommunityList />},
                            {displayName: "公佈欄內容", content: <EditBillboard />},
                            {displayName: "v1 紀錄匯入", content: <ImportFromV1 />},
                        ] } />
                </UserSideView>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function ImportFromV1(props){

    const db = getFirestore();

    const [results, setResults] = useState([]);
    const searchInputRef = useRef({
        communityReferenceId: "nhDivUuFfHhqfrE55Ga3zMCsrxs2",
        newCommunityReferenceId: "7nmyd7v8xdv351k",
        studentReferenceId: ""
    });
    function searchInputChanged(e, name){
        searchInputRef.current[name] = e.nativeEvent.target.value;
    }

    const searchIndex = useRef(0);
    async function searchStudent(){
        if(!searchInputRef.current.communityReferenceId || !searchInputRef.current.studentReferenceId) return;
        const studentsDocSnap = await getDoc(doc(db, `communityStudents/${searchInputRef.current.communityReferenceId}`))
        if(studentsDocSnap.exists()){
            const originalRecords = studentsDocSnap.data()[searchInputRef.current.studentReferenceId].records || [];
            let newRecords = originalRecords.filter(recordObject => !!recordObject.createdBy);
            newRecords = newRecords.map(newRecordObject => {
                const tempRecord = Object.assign({}, newRecordObject);
                tempRecord.volunteerReferenceId = newRecordObject.createdBy;
                tempRecord.date = newRecordObject.classDate;
                tempRecord.recordReferenceId = makeid(15);
                delete tempRecord.createdBy;
                delete tempRecord.parentComment;
                delete tempRecord.classDate;
                return tempRecord;
            });
            searchIndex.current++;
            setResults(newRecords);
        }
    }

    const volunteerReferenceIds = [];
    results.forEach(recordObject => {
        if(!volunteerReferenceIds.includes(recordObject.volunteerReferenceId)){
            volunteerReferenceIds.push(recordObject.volunteerReferenceId);
        }
    })

    const [volunteerDataMap, setVolunteerDataMap] = useState(null);
    useEffect(() => {
        if(results.length && !!searchIndex){
            getUsersByReferenceIds(volunteerReferenceIds, returnedList => {
                const newVolunteerDateMap = {};
                returnedList.forEach(volunteer => {
                    newVolunteerDateMap[volunteer.referenceId] = volunteer;
                });
                const newResults = JSON.parse(JSON.stringify(results));
                for(let i = 0; i < newResults.length; i++){
                    newResults[i].volunteerName = newVolunteerDateMap[newResults[i].volunteerReferenceId]?.name;
                }
                setResults(newResults);
                setVolunteerDataMap(newVolunteerDateMap);
            })
        }
    }, [searchIndex, results.length]);

    function uploadTeachingRecords(){
        updateDoc(doc(db, `communityStudents/${searchInputRef.current.newCommunityReferenceId}/students/${searchInputRef.current.studentReferenceId}`), {
            records: arrayUnion(...results)
        }).then(() => {
            window.location.reload();
        });
    }


    return (
        <>
            <DashboardBlock blockTitle="搜尋學生">
                <TextBox displayName="原 communityReferenceId" name="communityReferenceId"
                    valueSet={ searchInputRef.current } onChangeFunction={ searchInputChanged } />
                <TextBox displayName="新 studentReferenceId" name="newCommunityReferenceId"
                    valueSet={ searchInputRef.current } onChangeFunction={ searchInputChanged } />
                <TextBox displayName="studentReferenceId" name="studentReferenceId"
                    valueSet={ searchInputRef.current } onChangeFunction={ searchInputChanged } />
                <SubmitButton buttonText="搜尋資料" onSubmitFunction={ searchStudent } squeeze />
            </DashboardBlock>
            { results.length 
                ? <DashboardBlock blockTitle="匯入這些資料">
                    <SubmitButton buttonText="確認匯入" condense
                        onSubmitFunction={ uploadTeachingRecords } />
                </DashboardBlock>
            : null }
            { results.map((recordObject, index) => {
                return <DashboardBlock key={ "import-record-block-" + index } 
                    blockTitle={ recordObject.date }>
                    
                    <IconTextPair svgName="information">
                        老師：{ recordObject.volunteerName }
                    </IconTextPair>
                    <IconTextPair svgName="information">
                        上課時間：{ recordObject.classLength }
                    </IconTextPair>
                    <IconTextPair svgName="information">
                        備課時間：{ recordObject.prepLength }
                    </IconTextPair>
                    <br />
                    { recordObject.teachingRecord }
                    <br />
                    <br />
                    { recordObject.lifeRecord }
                    
                </DashboardBlock>
            }) }
        </>
    )
}

function EditBillboard(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [viewingTab, setViewingTab] = useState("none");
    const [blankState, setBlankState] = useState({});
    const [billboardData, setBillboardData] = useState(null);
    useEffect(() => {
        if(billboardData === null){
            getBillboardData((returnedData) => {
                setBillboardData(returnedData);
            });
        }
    });
    const newBillboardData = useRef(billboardData || {});
    useEffect(() => {
        newBillboardData.current = billboardData;
    }, [JSON.stringify(billboardData || {}), viewingTab]);
    function updateBillboardData(e, name, index){
        newBillboardData.current[viewingTab][index][name] = e.nativeEvent.target.value;
    }
    function deleteBillboardItem(index){
        newBillboardData.current[viewingTab].splice(index, 1);
        setBlankState({});
    }
    function createBillboardItem(){
        newBillboardData.current[viewingTab] = [{
            emoji: "", content: "", link: ""
        }, ...newBillboardData.current[viewingTab]];
        setBlankState({});
    }
    function saveBillboardData(){
        setBillboardFieldData(viewingTab, newBillboardData.current[viewingTab], topLevelStates.addNotification);
    }

    function updateViewingTab(e, name){
        setViewingTab(e.nativeEvent.target.value);
    }
    const viewingTabOptions = [
        {displayName: "請選擇預覽種類", value: "none"},
        {displayName: "系統公告", value: "messages"},
        {displayName: "各式表單", value: "forms"},
        {displayName: "使用說明", value: "instructions"}
    ];

    return (
        <TableFrame width="850" noScroll columns={ [
            {width: 850, content: <SelectionList options={ viewingTabOptions } 
                valueSet={{ viewingTab: viewingTab }} name="viewingTab"
                onChangeFunction={ updateViewingTab } />}
        ] }>

            <div className="edit-billboard-items-container">
                { viewingTab !== "none"
                    ? <div className="edit-billboard-item edit-billboard-item-add" onClick={ createBillboardItem }>
                        <IconTextPair svgName="add">新增訊息&emsp;</IconTextPair>
                    </div>
                : null }
                { ((newBillboardData.current || {})[viewingTab] || []).map((billboardItem, index) => {
                    return <div className="edit-billboard-item" key={ "billboard-" + viewingTab + "-" + (newBillboardData.current[viewingTab].length - index) }>
                        <TextBox displayName="開頭 Emoji" name="emoji"
                            valueSet={ billboardItem } onChangeFunction={ (e, name) => updateBillboardData(e, name, index) } />
                        <TextBox displayName="訊息內容" name="content"
                            valueSet={ billboardItem } onChangeFunction={ (e, name) => updateBillboardData(e, name, index) } />
                        <TextBox displayName="連結網址" name="link"
                            valueSet={ billboardItem } onChangeFunction={ (e, name) => updateBillboardData(e, name, index) }  />
                        <SubmitButton buttonText="刪除此訊息" warning onSubmitFunction={ deleteBillboardItem } />
                    </div>
                }) }
            </div>

            <SubmitButton center buttonText={<>&emsp;儲存公布欄內容&emsp;</>} condense onSubmitFunction={ saveBillboardData } /><br/>

        </TableFrame>
    )
}

function UserList(props){
    const topLevelStates = useContext(topLevelStatesContext)

    // When the user specifies a new load mode, lodeMode is updated
    // and loadData() checks for difference between previousLoadMode
    // and loadMode, and, if different, set pagesLoaded to 0
    const [previousLoadMode, setPreviousLoadMode] = useState("all");
    const [loadMode, setLoadMode] = useState("all");
    const [skipBlankUser, setSkipBlankUser] = useState(true);

    // An object to store search queries mapped to each load mode
    const [loadReference, setLoadReference] = useState({
        all: null,
        referenceId: "",
        userType: "",
        admin: true
    });
    const [previousLoadReference, setPreviousLoadReference] = useState({
        all: null,
        referenceId: "",
        userType: "",
        admin: true
    });

    function updateLoadReference(event, mode){
        const newLoadReference = Object.assign({}, loadReference);
        newLoadReference[mode] = event.nativeEvent.target.value;
        setLoadReference(newLoadReference);
    }

    const [lastVisibleUser, setLastVisibleUser] = useState(null);

    const dataLoadLimit = 30;
    const [pagesLoaded, setPagesLoaded] = useState(0);
    const [loadedData, setLoadedData] = useState([]);
    const [loadedAll, setLoadedAll] = useState(false);

    async function loadData(){
        
        // Check if the loadMode has changed, and, if yes, remove the 
        // search results and then set the previousLoadMode to loadMode
        let newLoadMode = false;
        if(previousLoadMode !== loadMode || 
            previousLoadReference[loadMode] !== loadReference[loadMode]){
                newLoadMode = true;
                
                setLoadedData([]);
                setLoadedAll(false);
                setPagesLoaded(0);
                setPreviousLoadMode(loadMode);
                setPreviousLoadReference(loadReference);
            }
            
        if(loadedAll) return null;

        const db = getFirestore();
        const userCollectionRef = collection(db, "users");
        let loadQuery = null;

        if(pagesLoaded === 0 || newLoadMode){
            if(loadMode === "all"){
                loadQuery = query(userCollectionRef, 
                    limit(dataLoadLimit));
            }else{
                console.log(loadMode, "==", loadReference[loadMode]);
                loadQuery = query(userCollectionRef, 
                    where(loadMode, "==", loadReference[loadMode]),
                    limit(dataLoadLimit));
            }
        }else{
            if(loadMode === "all"){
                loadQuery = query(userCollectionRef,
                    limit(dataLoadLimit),
                    startAfter(lastVisibleUser));
            }else{
                loadQuery = query(userCollectionRef,
                    where(loadMode, "==", loadReference[loadMode]),
                    limit(dataLoadLimit),
                    startAfter(lastVisibleUser));
            }
        }
        const documentSnapshots = await getDocs(loadQuery);
        setLastVisibleUser(documentSnapshots.docs[documentSnapshots.docs.length-1]);
        if(!documentSnapshots.docs[documentSnapshots.docs.length-1]){
            setLoadedAll(true);
        }

        const newLoadedData = [];
        documentSnapshots.forEach((userDoc) => {
            newLoadedData.push(userDoc.data());
        });
        if(newLoadMode) setLoadedData(newLoadedData);
        else setLoadedData([...loadedData, ...newLoadedData]);
        setPagesLoaded(pagesLoaded + 1);
    }

    useEffect(() => {
        loadData();
    }, [topLevelStates.hasLoaded]);

    return (
        <>
            { /* The top header block where users can search through
                the users collection in Cloud Firestore */ }
            <div className="users-header-block">
                <h2 className="users-header-block-title">在使用者資料庫中搜尋</h2>
                <div className="users-header-block-content">
                    <LoadModeSelectionBlock mode="all" currentLoadMode={ loadMode } onChangeMode={ setLoadMode } />
                    <LoadModeSelectionBlock mode="admin" currentLoadMode={ loadMode } onChangeMode={ setLoadMode } />
                    <LoadModeSelectionBlock mode="referenceId" currentLoadMode={ loadMode } 
                        updateLoadReference={ updateLoadReference } onChangeMode={ setLoadMode } />
                    <LoadModeSelectionBlock mode="userType" currentLoadMode={ loadMode }
                        updateLoadReference={ updateLoadReference } onChangeMode={ setLoadMode } />
                    <LoadModeSelectionBlock mode="name" currentLoadMode={ loadMode }
                        updateLoadReference={ updateLoadReference } onChangeMode={ setLoadMode } />
                </div>
                <CheckBox default={ skipBlankUser } buttonText="不顯示未建制帳號"
                    onChangeFunction={ setSkipBlankUser } />
                <SubmitButtonCenter onSubmitFunction={ loadData } buttonText="確認搜尋" />
            </div>

            { /* The list where the user data and further actions are displayed */ }
            <TableFrame width="920" rowHeight="70" noScroll
                columns={ [{content: "使用者", width: 120}, {content: "referenceId", width: 190}, {content: "帳號型態", width: 130},
                {content: "學校", width: 150}, {content: "目前活動 actvid", width: 250}, {content: "頁面", width: 79}] }>

                { loadedData.map((userData, index) => {
                    if(skipBlankUser && !userData.name) return null;
                    const userSelected = topLevelStates.selectedUser === userData.referenceId;
                    return <TableRow key={ index } selected={ userSelected } content={ [
                        <div className="users-list-block">
                            { (userData.admin ? "🟡" : "") + userData.name }
                        </div>,
                        <div className="users-list-block">{ userData.referenceId }</div>,
                        <div className="users-list-block">{ userData.userType }</div>,
                        <div className="users-list-block">{ userData.school }</div>,
                        <div className="users-list-block">{ userData.actvid }</div>,
                        <Link to={ "/User?referenceId=" + userData.referenceId }>
                            <SubmitButtonCenter buttonText="前往" />
                        </Link>
                    ] } onClick={ () => topLevelStates.selectUser(userData.referenceId) } />
                }) }

            </TableFrame>

            { !loadedAll ? <div className="users-load-more" onClick={ loadData }>
                    載入更多
                </div> : null
            }
                    
        </>
    )
}


function LoadModeSelectionBlock(props){
    const modeTextBoxEnabledMap = {
        all: false, admin: false,
        referenceId: true, userType: true, name:true
    }
    const modeTextBoxDisplayNameMap = {
        all: "所有使用者帳號",
        admin: "管理員帳號",
        referenceId: "用referenceId搜尋",
        userType: "用帳號類型搜尋",
        name: "用名稱搜尋",
    }
    return (
        <div className={ "users-header-block-item " + 
            (props.mode === props.currentLoadMode 
                ? "users-header-block-item-selected " : " ") }
            onClick={ () => props.onChangeMode(props.mode) } >

            <TextBox displayName={ modeTextBoxDisplayNameMap[props.mode || "all"] } 
                name={ props.mode } onChangeFunction={ props.updateLoadReference }
                disabled={ !modeTextBoxEnabledMap[props.mode || "all"] } />

        </div>
    )
}

function CommunityList(props){

    const topLevelStates = useContext(topLevelStatesContext);

    const [communityUsers, setCommunityUsers] = useState(null);
    const [communityActivities, setCommunityActivities] = useState({});
    
    useEffect(() => {
        if(!communityUsers || !Object.keys(communityActivities).length){
            initializeCommunityUsers();
        }
    }, [topLevelStates.hasLoaded]);


    function initializeCommunityUsers(){
        getUsersByType(["community"], (communityUserList) => {
            setCommunityUsers(communityUserList);
            const newCommunityActivities = {};
            (communityUserList || []).forEach(communityUser => {
                getActivitiesByCommunity(communityUser.referenceId, (communityActivities) => {
                    newCommunityActivities[communityUser.referenceId] = communityActivities;
                    setCommunityActivities(Object.assign({}, newCommunityActivities));
                })
            });
        });
    }

    return (
        <TableFrame width="1400" rowHeight="180" 
            columns={ [{content: "使用者", width: 110}, 
            {content: "該使用者的活動", width: 1290}] }>

            { (communityUsers || []).map((communityUser, index) => {
                const communityUserSelected = 
                    communityUser.referenceId === topLevelStates.selectedUser;
                return <TableRow key={ index } selected={ communityUserSelected } clickable content={[
                    <CommunityNameBlock communityName={ communityUser.name }
                        communityReferenceId={ communityUser.referenceId } />,
                    <CommunityActivitiesRow communityReferenceId={ communityUser.referenceId }
                        communityActivities={ communityActivities[communityUser.referenceId] } />
                ]} />

            }) }
            
        </TableFrame>
    )
}

function CommunityNameBlock(props){
    const topLevelStates = useContext(topLevelStatesContext);
    return (
        <div className="community-name-block" 
            onClick={() => topLevelStates.selectUser(props.communityReferenceId)}>
            { props.communityName }
        </div>
    )
}

function CommunityActivitiesRow(props){
    return (
        <div className="community-activities-row">
            { (props.communityActivities || []).map((communityActivity, index) => {
                return <CommunityActivityBlock key={ index } 
                    communityActivity={ communityActivity } />
            }) }
            <CommunityActivityBlock mode="add"
                communityReferenceId={ props.communityReferenceId } />
        </div>
    )
}

function CommunityActivityBlock(props){

    const topLevelStates = useContext(topLevelStatesContext);

    function addNewActivity(communityReferenceId){
        topLevelStates.addNotification("正在建立活動梯次⋯");
        createActivity(communityReferenceId, () => {
            window.location.reload();
        })
    }
    
    if(props.mode === "add") return (
        <div className="community-activity-block community-activity-block-add"
            onClick={ () => addNewActivity(props.communityReferenceId) }>
            <IconTextPair svgName="add" style={{ margin: '45px auto', width: 120 }}>
                新活動梯次
            </IconTextPair>
        </div>
    )

    return (
        <div className={ "community-activity-block community-activity-block-" + 
            (props.communityActivity.activated || "planning") } >
            <div className="community-activity-block-top">
                { props.communityActivity.name }
                <p className="community-activity-block-top-description">
                    { props.communityActivity.description }
                </p>
            </div>
            <div className="community-activity-block-bottom">
                <Link to={ "/ActivityStudents?actvid=" + props.communityActivity.actvid }>
                    <div className="community-activity-block-bottom-button">列表</div>
                </Link>
                <Link to={ "/ActivitySetting?actvid=" + props.communityActivity.actvid }>
                    <div className="community-activity-block-bottom-button">設定</div>
                </Link>
            </div>
        </div>
    )
}