import "../style/Notification.css"
import { useContext, useEffect, useState } from "react";

function NotificationShade(props){
    const notificationList = props.notificationList || [];
    const [notificationListState, setNotificationListState] = useState(notificationList);

    useEffect(() => {
        if(notificationList.length !== notificationListState.length) 
            setNotificationListState(notificationList);
    }, [notificationList])

    return (
        <div id="notification-shade" className="notification-shade">
            { notificationListState }
        </div>
    );
}

function NotificationBlock(props){
    const [visible, setVisible] = useState({fade: false, visible: true});
    useEffect(() => {
        let isMounted = true;
        setTimeout(() => {
            if(isMounted) setVisible({fade: true, visible: true});
            if(isMounted) setTimeout(() => {
                props.removeNotificationFuction(props.timestamp)
            }, 600)
        }, 4400);
        return () => {isMounted = false};
    });

    return visible.visible ? (
        <div className={ "notification-block " + (visible.fade ? "notification-block-disappear" : "")}>
            {props.notificationBody}
        </div>
    ) : <></>;
}

export { NotificationShade,NotificationBlock };