import './Firebase';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from '@firebase/firestore';
import { getAuth, onAuthStateChanged } from '@firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import Cookies from "js-cookie";

import { NotificationBlock, NotificationShade } from './components/Notification';
import Home from "./pages/Home";
import Login from './pages/Login';
import Signup from './pages/Signup';
import ParentRegister from './pages/ParentRegister';
import Dashboard from './pages/Dashboard';
import User from './pages/User';
import ActivitySetting from './pages/ActivitySetting';
import CommunityStudents from './pages/CommunityStudents';
import Admin from './pages/Admin';
import Volunteer from './pages/Volunteer';
import ActivityClasses from './pages/ActivityClasses';
import StudentProfile from './pages/StudentProfile';
import AddRecord from './pages/AddRecord';
import ActivityStudents from './pages/ActivityStudents';
import Setting from './pages/Setting';
import SelectClass from './pages/SelectClass';
import { getActivitiesPublicInfoByActvids, getClassesInActivityByReferenceId } from './lib/user';
import { containsToday } from './lib/dates';
import AddLeave from './pages/AddLeave';
import ClassCheck from './pages/ClassCheck';
import ActivityMessaging from './pages/ActivityMessaging';
import Redirect from './components/Redirect';

let userAndReadiness = {user: null, ready: false};
export const topLevelStatesContext = React.createContext();
export const notificationContext = React.createContext();


const auth = getAuth();
onAuthStateChanged(auth, user => {
  console.log(user);
  if(user) {
    userAndReadiness = {user: user, ready: true};
    Cookies.set('isLoggedIn', 'true', {path: '/'});
  }else{
    userAndReadiness = {user: null, ready: true};
    Cookies.set('isLoggedIn', 'flase', {path: '/'});
  }
})

function App() {

  // Top Level States Methods
  const [topLevelStates, setTopLevelStates] = useState({
    'firebaseUser': {},
    'user': {},
    'hasLoaded': false,
    'notificationList': [],
    'addNotification': addNotification,
    'selectUser': selectUser,
    'selectedUser': null,
    'updateTopLevelStates': updateTopLevelStates,
    'activitiesPublicInfo': null,
    'currentActivitiesClasses': {},
  });
  function updateTopLevelStates(updates){
    setTopLevelStates(currentTopLevelStates => {
      const newTopLevelStates = Object.assign({}, currentTopLevelStates);
      for(const [updateKey, updateValue] of Object.entries(updates)){
        newTopLevelStates[updateKey] = updateValue;
      }
      return newTopLevelStates;
    });
  }

  // Select user to view in the user side view
  function selectUser(userReferenceId){
    updateTopLevelStates({selectedUser: userReferenceId});
  }

  // Notification Handlers
  function addNotification(newNotification){
    const timestamp = Date.now();
    updateTopLevelStates({
      notificationList: [...topLevelStates.notificationList, 
        <NotificationBlock notificationBody={ newNotification } 
          removeNotificationFuction={ removeNotification } key={ timestamp } 
          data-notification-key={ timestamp } timestamp={ timestamp } />]
    })
  }
  function removeNotification(notificationKey){
    const newNotificationList = [];
    for(let i = 0; i < topLevelStates.notificationList.length; i++){
      if(topLevelStates.notificationList[i].getAttribute && topLevelStates.notificationList[i].getAttribute("data-notification-key") === notificationKey) continue;
      newNotificationList.push(topLevelStates.notificationList[i]);
    }
    updateTopLevelStates({
      notificationList: newNotificationList
    });
  }

  // User Data Handlers
  if(!topLevelStates.hasLoaded){
    let checkReadiness = setInterval(() => {
      if(userAndReadiness.ready && userAndReadiness.user){
        async function getUser(thenFunction){
          const db = getFirestore();
          const userRef = doc(db, "users", userAndReadiness.user.uid);
          const userSnap = await getDoc(userRef);
          if(userSnap.exists()){
              thenFunction(userSnap.data());
          }else{
            userAndReadiness = {user: null, ready: true};
            Cookies.set('isLoggedIn', 'false', {path: '/'});
          }
        }
        getUser((userData) => {
          updateTopLevelStates({
            hasLoaded: true,
            firebaseUser: userAndReadiness.user,
            user: userData
          })
        })
        clearInterval(checkReadiness);
      }
    }, 300)
  }

  // if(topLevelStates.user && topLevelStates.user.referenceId !== "adhbu2xjdiiqtm9"){
  //   const newUser = Object.assign({}, topLevelStates.user);
  //   newUser.referenceId = "s17zoyf0ldpvn7a";
  //   newUser.userType = "volunteer";
  //   updateTopLevelStates({
  //     user: newUser
  //   });
  //   console.log(topLevelStates);
  // }

  useEffect(() => {
    if(topLevelStates.hasLoaded && topLevelStates.activitiesPublicInfo === null){
      getActivitiesPublicInfoByActvids(topLevelStates.user.activities || [], returnedList => {
        updateTopLevelStates({
          activitiesPublicInfo: returnedList
        });
        returnedList.forEach(activity => {
          if(containsToday(activity.startTime, activity.endTime)){
            getClassesInActivityByReferenceId(activity.actvid, topLevelStates.user.userType, 
              topLevelStates.user.referenceId, returnedClasses => {
              const newCurrentActivitiesClasses = Object.assign({}, topLevelStates.currentActivitiesClasses);
              if(!newCurrentActivitiesClasses[activity.actvid]) newCurrentActivitiesClasses[activity.actvid] = [];
              newCurrentActivitiesClasses[activity.actvid].push(returnedClasses);
              updateTopLevelStates({
                activitiesPublicInfo: returnedList,
                currentActivitiesClasses: newCurrentActivitiesClasses
              });
            });
          }
        });
        updateTopLevelStates({});
      });
    }
  }, [topLevelStates.hasLoaded])

  return (
    <topLevelStatesContext.Provider value={ topLevelStates }>
      <Router>
        <notificationContext.Provider value={ topLevelStates.notificationList }>
          <notificationContext.Consumer>
            { value =>
              <NotificationShade notificationList={ value } />
            }
          </notificationContext.Consumer>
        </notificationContext.Provider>
        <Routes>
          
          { /* Defaults to either Home or Dashboard depending on the login state */ }
          <Route path="/" index element={ Cookies.get("isLoggedIn") === "true" ? <Navigate to="/Dashboard" /> : <Navigate to="/Home" /> } />

          { /* Functional pages */ }
          <Route path='/Home' element={ <Home /> }></Route>
          <Route path='/Login' element={ <Login /> }></Route>
          <Route path='/Signup' element={ <Signup /> }></Route>
          <Route path='/ParentRegister' element={ <ParentRegister /> }></Route>

          { /* Pages where logged in users interact  */ }
          <Route path='/Dashboard' element={ <Dashboard /> }></Route>
          <Route path='/Setting' element={ <Setting /> }></Route>

          { /* General pages for the users */ }
          <Route path='/CommunityStudents' element={ <CommunityStudents /> }></Route>
          <Route path='/Volunteer' element={ <Volunteer /> }></Route>
          <Route path='/StudentProfile' element={ <StudentProfile /> }></Route>
          <Route path='/AddRecord' element={ <AddRecord /> }></Route>
          <Route path='/ActivityStudents' element={ <ActivityStudents /> }></Route>
          <Route path='/SelectClass' element={ <SelectClass /> }></Route>
          <Route path='/AddLeave' element={ <AddLeave /> }></Route>
          <Route path='/ClassCheck' element={ <ClassCheck /> }></Route>

          { /* Pages where logged in admin users control the platform data */ }
          <Route path='/Admin' element={ <Admin /> }></Route>
          <Route path='/ActivitySetting' element={ <ActivitySetting /> }></Route>
          <Route path='/User' element={ <User /> }></Route>
          <Route path="/ActivityClasses" element={ <ActivityClasses /> }></Route>
          <Route path="/ActivityMessaging" element={ <ActivityMessaging /> }></Route>

          {/* Some redirects to our friendly sites */}
          <Route path="/shunxing" element={ <Redirect to="https://shunxing-game.web.app" /> }></Route>

        </Routes>
      </Router>
    </topLevelStatesContext.Provider>
  );
}

export default App;

/**
 * TO-DO
 * 
 * 3. Finish the class selection page before 6/10
 * 6. Class cancelation system by 6/15
 * 7. Calendar view of teaching records for the admin users by 6/18
 * 8. Parent feedback form, integrated into the calendar view by 6/29
 */