import { useEffect, useState } from "react";
import { getBillboardData } from "../lib/user";
import "../style/Billboard.css";

export default function Billboard(props){

    const [selectedTab, setSelectedTab] = useState(0);
    const contentIndexMap = {0: "messages", 1: "forms", 2: "instructions"};

    const [content, setContent] = useState(null);
    useEffect(() => {
        if(content === null){
            getBillboardData(setContent);
        }
    });

    return (
        <div className="billboard">
            <div className="billboard-top-tabs">
                <div className={ "billboard-top-tab " + 
                    (selectedTab === 0 ? "billboard-top-tab-selected " : "" )}
                    onClick={ () => setSelectedTab(0) }>
                    公告
                </div>
                <div className={ "billboard-top-tab " + 
                    (selectedTab === 1 ? "billboard-top-tab-selected " : "" )}
                    onClick={ () => setSelectedTab(1) }>
                    表單
                </div>
                <div className={ "billboard-top-tab " + 
                    (selectedTab === 2 ? "billboard-top-tab-selected " : "" )}
                    onClick={ () => setSelectedTab(2) }>
                    操作說明
                </div>
            </div>
            <div className="billboard-content">
                { ((content || {})[contentIndexMap[selectedTab]] || []).map((item, index) => {
                    return <div className="billboard-content-block">
                        { item.emoji } <a href={ item.link } target="_blank">{ item.content }</a>
                    </div>
                }) }
                
            </div>
            {/* <div className="billboard-content billboard-content-blank"
                style={{ backgroundImage: `url(${require.context("../color-ico/")("./inspection.svg")})` }}>
                
                <div className="billboard-content-blank-text">
                    目前沒有公告
                </div>
            </div> */}
        </div>
    )
}