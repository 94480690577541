import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import AccountTypeAccess from "../components/AccountTypeAccess";
import { EditClassLink, EditClassStudents, EditClassTime, EditClassVolunteer, SelectClassSubjects } from "../components/ClassEdit";
import DashboardFrame from "../components/DashboardFrame";
import { SubmitButton } from "../components/Forms";
import { TableFrame, TableRow, TableRowFull } from "../components/Table";
import { arrayUnion } from "@firebase/firestore";
import { strings } from "../lib/strings";
import { createClassInActivity, deleteClassFromActivity, getActivityPublicInfo, getAllStudentsFromCommunity, getClassesInActivity, getVolunteersByActivities, updateClassInActivityByClassId, updateUserByReferenceId } from "../lib/user";
import "../style/ActivityClasses.css";
import IconTextPair from "../components/IconTextPair";
import CalendarTimeView from "../components/CalendarTimeView";
import { SubjectChip } from "../components/SubjectBlock";
import { ChipButton } from "../components/ChipButton";
import { deleteField } from "@firebase/firestore";

export default function ActivityClasses(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const actvid = searchParams.get("actvid");

    const [activityClasses, setActivityClasses] = useState(null);
    const [volunteerPersonalData, setVolunteerPersonalData] = useState({});
    const [communityStudents, setCommunityStudents] = useState({});

    useEffect(() => {
        if(activityClasses === null) {
            fetchData();
        }
    }, [topLevelStates.hasLoaded]);

    function fetchData(){
        getClassesInActivity(actvid, setActivityClasses);
        getVolunteersByActivities(actvid, (returnedVolunteerData) => {
            const newVolunteerPersonalData = {};
            returnedVolunteerData.forEach(volunteerData => {
                newVolunteerPersonalData[volunteerData.referenceId] = volunteerData;
            });
            setVolunteerPersonalData(newVolunteerPersonalData);
        });
        getActivityPublicInfo(actvid, activityPublicInfo => {
            getAllStudentsFromCommunity(activityPublicInfo.communityReferenceId, returnedStudents => {
                const newCommunityStudents = {};
                returnedStudents.forEach(returnedStudent => {
                    newCommunityStudents[returnedStudent.studentReferenceId] = returnedStudent;
                })
                setCommunityStudents(newCommunityStudents);
            });
        })
    }

    function addNewClass(){
        createClassInActivity(actvid, "", {}, topLevelStates.addNotification, fetchData);
    }

    const [viewingClass, setViewingClass] = useState("");

    return (
        <DashboardFrame selected="dashboard" full>
            <AccountTypeAccess accessFor={ ["admin"] }>
                <div className="activity-classes-table-wrap">
                    <div className="activity-classes-overview">
                        { Object.keys(volunteerPersonalData).map((volunteerReferenceId, index) => {
                            const thisVolunteerClass = (activityClasses || []).find(classObject => classObject.volunteerReferenceId === volunteerReferenceId);
                            const volunteerStatus = !thisVolunteerClass
                                ? "" : (!!thisVolunteerClass.volunteerHandle && thisVolunteerClass.classTime?.length
                                    ? "inited" : "not-inited")
                            return <div className={ "activity-classes-overview-volunteer-name activity-classes-overview-volunteer-name-" + volunteerStatus }
                                key={ "activity-classes-overview-volunteer-name-" + index }>
                                
                                { volunteerPersonalData[volunteerReferenceId].name }
                            </div>
                        }) }
                    </div>
                    <div className="activity-classes-row">
                        {/* <ChipButton svgName="share-go" buttonText="顯示選課資訊"
                            onClick={ () => setShowingSelectClassPopup(true) } /> */}
                        <ChipButton svgName="course" buttonText="教學稽核頁面"
                            link={ "/ClassCheck?actvid=" + actvid } />
                        <ChipButton svgName="list" buttonText="梯次學生列表"
                            link={ "/ActivityStudents?actvid=" + actvid } />
                        <ChipButton svgName="setting" buttonText="活動梯次設定"
                            link={ "/ActivitySetting?actvid=" + actvid } />
                    </div>
                    <TableFrame width="1502" rowHeight="auto" noScroll
                        columns={ [{content: "教學老師", width: 240}, {content: "學生", width: 290},
                        {content: "教學科目", width: 300}, {content: "上課連結", width: 250},
                        {content: "上課時間", width: 400}] }>

                        { (activityClasses || [])
                            .filter(a => a.volunteerReferenceId && a.volunteerReferenceId !== "無")
                            .sort((a, b) => (
                                a.classId > b.classId ? 1 : -1
                            ))
                            .map((activityClass, index) => {
                                return <>
                                    <TableRowFull selected={ activityClass.classId === viewingClass }>
                                        <ActivityClassPreview activityClass={ activityClass }
                                            setViewingClass={ setViewingClass } selected={ activityClass.classId === viewingClass }
                                            allVolunteers={ volunteerPersonalData } actvid={ actvid }
                                            communityStudents={ communityStudents } fetchData={ fetchData } />
                                    </TableRowFull>
                                    { viewingClass === activityClass.classId
                                        ? <ActivityClassRow activityClass={ activityClass }
                                        allVolunteers={ volunteerPersonalData } actvid={ actvid }
                                        communityStudents={ communityStudents } fetchData={ fetchData } />
                                    : null }
                                    
                                </>
                        }) }

                        { (activityClasses || [])
                            .filter(a => !a.volunteerReferenceId || a.volunteerReferenceId === "無")
                            .map((activityClass, index) => {
                                return <>
                                    <TableRowFull selected={ activityClass.classId === viewingClass }>
                                        <ActivityClassPreview activityClass={ activityClass }
                                            setViewingClass={ setViewingClass } selected={ activityClass.classId === viewingClass }
                                            allVolunteers={ volunteerPersonalData } actvid={ actvid }
                                            communityStudents={ communityStudents } fetchData={ fetchData } />
                                    </TableRowFull>
                                    { viewingClass === activityClass.classId
                                        ? <ActivityClassRow activityClass={ activityClass }
                                        allVolunteers={ volunteerPersonalData } actvid={ actvid }
                                        communityStudents={ communityStudents } fetchData={ fetchData } />
                                    : null }
                                </>
                                
                        }) }

                        <TableRow content={ [
                            <>
                                <br />
                                <SubmitButton condense center buttonText="新增一堂課"
                                    onSubmitFunction={ addNewClass } />
                                <br />
                            </>
                        ] } />

                    </TableFrame>
                </div>
            </AccountTypeAccess>
        </DashboardFrame>
    )
}

function ActivityClassPreview({ activityClass, setViewingClass, selected, allVolunteers, actvid, fetchData }){
    const topLevelStates = useContext(topLevelStatesContext);

    function updateViewingClass(){
        if(selected) setViewingClass("");
        else setViewingClass(activityClass.classId);
    }
    function requestRefill(){
        updateClassInActivityByClassId(actvid, activityClass.classId, {
            volunteerHandle: deleteField(),
            subjects: deleteField(),
            availableTime: deleteField(),
            volunteerHandle: deleteField(),
            classTime: deleteField()
        }, topLevelStates.addNotification, fetchData)
    }
    function deleteClass(){
        deleteClassFromActivity(actvid, activityClass.classId, 
            topLevelStates.addNotification, () => window.location.reload());
    }
    
    const hasInited = !!activityClass.volunteerHandle && !!activityClass.classTime?.length;

    return (
        <div className="activity-classes-preview" onClick={ updateViewingClass }>
            <div className={ "activity-classes-preview-block " + (hasInited ? "activity-classes-preview-block-inited" : "activity-classes-preview-block-not-inited")} onClick={ (e) => e.stopPropagation() }>
                <IconTextPair svgName="information" fontSize="16">志工: { ((allVolunteers || {})[activityClass.volunteerReferenceId || ""] || {}).name }</IconTextPair>
                <IconTextPair svgName="information" fontSize="16">梯次上課堂數: { activityClass.classTime?.length || 0 }</IconTextPair>
                <IconTextPair svgName="information" fontSize="16">classId { activityClass.classId }</IconTextPair>
                <IconTextPair svgName="information" fontSize="16">志工負荷人數: { activityClass.volunteerHandle }</IconTextPair>
                <IconTextPair svgName="information" fontSize="16">已經選課人數: { (activityClass.students || []).length }</IconTextPair>
                <IconTextPair svgName="information" fontSize="16" wrap>上課連結: { activityClass.classLink }</IconTextPair>
            </div>
            <div className="activity-classes-preview-block" onClick={ (e) => e.stopPropagation() } style={{ minWidth: 'unset', width: 240, textAlign: 'center' }}>
                最想上課時間
                <CalendarTimeView miniview rowHeight="32" classTime={ activityClass.classTime } />
            </div>
            <div className="activity-classes-preview-block" onClick={ (e) => e.stopPropagation() } style={{ minWidth: 'unset', width: 240, textAlign: 'center' }}>
                { ((allVolunteers || {})[activityClass.volunteerReferenceId || ""] || {}).name }可上課時間
                <CalendarTimeView miniview rowHeight="32" classTime={ (activityClass.availableTime || {})[activityClass.volunteerReferenceId || ""] || [] } />
            </div>
            <div className="activity-classes-preview-block" onClick={ (e) => e.stopPropagation() } style={{ minWidth: 'unset', width: 240, textAlign: 'center' }}>
                <IconTextPair svgName="subject" fontSize="16">上課科目：</IconTextPair>
                <>
                    { (activityClass.subjects || []).map(subject => 
                        <SubjectChip subject={ subject } />    
                    ) }
                </>
            </div>
            <div className="activity-classes-preview-block" onClick={ (e) => e.stopPropagation() } style={{ minWidth: 'unset'}}>
                <br />
                <ChipButton svgName="edit" buttonText="讓志工重新填寫" onClick={ requestRefill } />
                <br />
                <ChipButton svgName="delete" buttonText="從梯次移除課堂" onClick={ deleteClass } />
            </div>
        </div>
    )
}

function ActivityClassRow(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const allVolunteers = props.allVolunteers || {};
    const activityClass = props.activityClass || {};
    const studentReferenceIds = (activityClass.students || []);
    const communityStudents = props.communityStudents || {};

    const classSubjectString = (activityClass.subjects || []).map(subject => strings[subject + "Pure"]).join("、");
    const classStudentsString = studentReferenceIds
        .map(studentReferenceId => communityStudents[studentReferenceId]?.name).join("、");

    function updateClass(updateContent){
        (updateContent.students || []).forEach(studentReferenceId => {
            updateUserByReferenceId(studentReferenceId, {activities: arrayUnion(props.actvid)})
        });
        updateClassInActivityByClassId(props.actvid, activityClass.classId, updateContent,  
            topLevelStates.addNotification, props.fetchData)
    }

    return (
        <TableRow style={{ borderBottom: '5px solid #666', boxShadow: 'inset 5px 0 0 var(--emphasis-color)', paddingTop: 20, marginBottom: 50 }} content={ [
            
            <div className="activity-class-row-block">
                <EditClassVolunteer activityClass={ activityClass }
                    allVolunteers={ allVolunteers }
                    updateClass={ updateClass } />
            </div>,

            <div className="activity-class-row-block">
                <EditClassStudents activityClass={ activityClass }
                    communityStudents={ communityStudents }
                    updateClass={ updateClass }/>
            </div>,

            <div className="activity-class-row-block">
                <SelectClassSubjects activityClass={ activityClass }
                    updateClass={ updateClass }/>
            </div>,

            <div className="activity-class-row-block">
                <EditClassLink activityClass={ activityClass }
                    updateClass={ updateClass }/>
            </div>,

            <div className="activity-class-row-block">

                <div className="edit-class-time-container">
                    <EditClassTime activityClass={ activityClass }
                        updateClass={ updateClass }/>
                </div>
                
            </div>

            
        ] } />
    )
}
