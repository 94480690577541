import { addDoc, doc, setDoc, getFirestore, getDoc, collection, updateDoc, arrayUnion, runTransaction, getDocs, query, where, deleteDoc, arrayRemove } from "@firebase/firestore"; 
import { onAuthStateChanged, getAuth } from '@firebase/auth';
import Cookies from "js-cookie";
import makeid from "./makeid";

export async function getTeachingRecordsInCommunity(communityReferenceId, studentReferenceId,
    thenFunction=null){
    const db = getFirestore();
    const studentRef = doc(db, 
        `communityStudents/${communityReferenceId}/students/${studentReferenceId}`);
    const studentSnap = await getDoc(studentRef);
    if(studentSnap.exists()){
        thenFunction && thenFunction(studentSnap.data()["records"] || []);
        return studentSnap.data()["records"] || [];
    }
}

export async function addBlankTeachingRecord(communityReferenceId, studentReferenceId, 
    notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    const newRecordTemplate = {
        volunteerName: "",
        volunteerReferenceId: "",
        date: new Date().toISOString().slice(0, 10),
        lifeRecord: "",
        teachingRecord: "",
        recordReferenceId: makeid(15)
    }
    notificationFunction && notificationFunction("正在新增資料...");
    const studentRef = doc(db, 
        `communityStudents/${communityReferenceId}/students/${studentReferenceId}`);

    await updateDoc(studentRef, {
        records: arrayUnion(newRecordTemplate)
    }).then(() => {
        thenFunction && thenFunction();
    })
}

export async function addTeachingRecord(recordObject, communityReferenceId, studentReferenceId, 
    notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    notificationFunction && notificationFunction("正在新增資料...");
    const studentRef = doc(db, 
        `communityStudents/${communityReferenceId}/students/${studentReferenceId}`);

    const volunteerReferenceId = recordObject.volunteerReferenceId || "";

    await updateDoc(studentRef, {
        taughtBy: arrayUnion(volunteerReferenceId),
        records: arrayUnion(recordObject)
    }).then(() => {
        thenFunction && thenFunction();
    })
}

export async function updateTeachingRecordsInCommunity(communityReferenceId, studentReferenceId, 
    originalTeachingRecord, newTeachingRecord, notificationFunction=null, thenFunction=null){

    const db = getFirestore();
    const studentRef = doc(db, 
        `communityStudents/${communityReferenceId}/students/${studentReferenceId}`);
    
    notificationFunction && notificationFunction("儲存中...");
    await updateDoc(studentRef, {
        records: arrayRemove(originalTeachingRecord)
    }).then(() => {
        updateDoc(studentRef, {
            records: arrayUnion(newTeachingRecord)
        }).then(() => {
            notificationFunction && notificationFunction("儲存完成");
            thenFunction && thenFunction();
        })
    })
}