import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { topLevelStatesContext } from "../App";
import { getShowcaseActivitiesPublicInfo } from "../lib/user";
import "../style/Landing.css";
import CommunityBanner from "./CommunityBanner";
import IconTextPair from "./IconTextPair";
import { Popup, PopupSection } from "./Popup";

function Landing(){
    return (
        <div>
            <div className="landing-block header-block">
                <div className="header-block-left"></div>
                <div className="header-block-right">
                    <div className="header-block-right-text">
                        &nbsp;<span className="large-title">Infinitas</span> <br />&nbsp;志工計畫
                        <div className="header-block-description">
                            我們用每週兩個小時的時間，改變孩子們的求學之路。
                        </div>
                    </div>
                    <Link to="/Login">
                        <div className="header-block-button block">
                            立即加入
                        </div>
                    </Link>
                    {/* <div className="header-community-logo">
                        <div className="header-community-logo-left"></div>
                        <div className="header-community-logo-right">台北市文山區順<br />興社區發展協會</div>
                    </div> */}
                </div>
            </div>
            <div className="scroll-indicator"
                style={{ backgroundImage: `url(${require.context("../ico/")("./arrow_down_thin.svg")})` }}
                onClick={ () => window.scrollBy(0, 400) }>
            </div>
            <div className="landing-block">
                <div className="landing-block-title landing-block-title-montserrat">Infinitas，但為什麼？</div>
                <div className="landing-block-explain">
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top experience"></div>
                        <div className="landing-block-explain-item-title">1600+ 小時服務經驗</div>
                        <div className="landing-block-explain-item-description">自 2021 年 6 月底成立開始，我們的團隊已經完成超過 1600 小時的線上課後輔導，累積超過 70000 字的教學紀錄，不僅提供學生在學業上的各種幫助，也讓團隊獲得了教學和管理的各種經驗。</div>
                    </div>
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top team"></div>
                        <div className="landing-block-explain-item-title">完整團隊，完整教材</div>
                        <div className="landing-block-explain-item-description">Infinitas 的二十位組織幹部用完整的工作分配，處理志工和學生所遇到的各式狀況。我們也依循著教育部的課綱，訂定科目的教學內容以及備課方針，讓各志工老師都可專注教學本質，減輕他們不必要的負擔。</div>
                    </div>
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top communication"></div>
                        <div className="landing-block-explain-item-title">有效溝通，事半功倍</div>
                        <div className="landing-block-explain-item-description">透過這個線上平台，我們確保最重要的上課資訊能夠在對的時間點傳達給對的人。社區能一手掌握所有上課資訊，志工和學生家長間也可進行紀錄分享和討論。</div>
                    </div>
                </div><br/><br/>
                <div className="landing-block">
                    <div className="landing-block-title">進行中的志工計畫</div>
                    <div className="landing-block-note">僅列出目前招生中的梯次，過往梯次不列舉</div>
                    <div className="landing-block-activities no-scrollbar">
                        <ActivitiesShowcase />
                    </div>
                </div>
                <div className="landing-block-statistics">
                    <div className="landing-block-statistics-item">
                        <div className="landing-block-statistics-item-top">&gt; 15000</div>
                        <div className="landing-block-statistics-item-bottom">小時的服務經驗</div>
                    </div>
                    <div className="landing-block-statistics-item">
                        <div className="landing-block-statistics-item-top">&gt; 700</div>
                        <div className="landing-block-statistics-item-bottom">累計志工人數</div>
                    </div>
                    <div className="landing-block-statistics-item">
                        <div className="landing-block-statistics-item-top">&gt; 380000</div>
                        <div className="landing-block-statistics-item-bottom">字的教學檔案</div>
                    </div>
                </div>
            </div>
            <div className="landing-block" style={{'paddingBottom': '200px'}}>
                <div className="landing-block-title" id="account-types">誰可以加入我們？</div>
                <div className="landing-block-login-cta">
                    <div className="landing-block-login-cta-left volunteer" style={{'backgroundPositionY': '40%', 'backgroundSize': '60%'}}></div>
                    <div className="landing-block-login-cta-right">我是高中生，想<br />加入志工的行列</div>
                </div>
                <div className="landing-block-login-cta">
                    <div className="landing-block-login-cta-left community"></div>
                    <div className="landing-block-login-cta-right">我的社區或學校<br />需要志工的服務</div>
                </div>
                <div className="landing-block-login-cta">
                    <div className="landing-block-login-cta-left student"></div>
                    <div className="landing-block-login-cta-right">我想尋找一對一<br />課後輔導的志工</div>
                </div>
            </div>
            <div className="landing-block" style={{ padding: '80px 0 270px 0' }}>
                <div className="landing-block-title">「⋯⋯所以就很感謝你們，給這些孩子一個良好的典範，讓他們對未來充滿了希望⋯⋯」</div><br/>
                <div className="landing-block-note">—— 描述孩童遭霸凌的情形後，這位母親如此說</div><br/>
                <div className="landing-block-note">其實，我們憑藉那些衝勁，可以改變世界</div>
            </div>
            <div className="landing-block">
                <div className="landing-block-title">我們如何運作？</div>
                <div className="landing-block-explain">
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top volunteer"></div>
                        <div className="landing-block-explain-item-title">學生組成的志工團隊</div>
                        <div className="landing-block-explain-item-description">只要有熱誠，你就有能力成為一位影響者。我們歡迎來自全國各地的高中生，一起致力於讓更多需要教育資源的孩童能得到充足的啟發。我們熱烈歡迎能帶來影響的你加入我們的團隊！</div>
                    </div>
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top community"></div>
                        <div className="landing-block-explain-item-title">社區與學校提供機會</div>
                        <div className="landing-block-explain-item-description">Infinitas 的志工來自各地的學校，以數學和英文的輔導點亮弱勢孩子們的學習道路，透過與地方與學校社區的合作，我們為每位孩子量身訂做課程，提升他們對學習的興趣。</div>
                    </div>
                    <div className="landing-block-explain-item">
                        <div className="landing-block-explain-item-top student"></div>
                        <div className="landing-block-explain-item-title">需要的學生接受教學</div>
                        <div className="landing-block-explain-item-description">教育是一場生命影響生命的旅程。在充滿不公平的社會裡，我們希望教育能帶來公平的競爭。然而，並非所有人都擁有豐裕的學習資源精進自己。在追求無遠弗屆的知識時卻無從尋求幫助的你，我們希望 Infinitas 能成為你的學習夥伴。</div>
                    </div>
                </div>
            </div>
            <div className="landing-block landing-block-float-right">
                <div className="landing-block-title">一對一線上教學<br />幫助有需求的孩子</div>
                <div className="landing-block-image"></div>
            </div>
            <div className="landing-block">
                <div className="landing-block-title">我們的志工來自</div>
                <div className="landing-block-note">迄今累計志工來自下列學校；不代表學校立場</div>
                <div className="landing-block-school-grid">
                    <div className="landing-block-school-grid-item wego">
                        <div className="landing-block-school-grid-item-text">私立薇閣高級中學</div>
                    </div>
                    <div className="landing-block-school-grid-item chingshin">
                        <div className="landing-block-school-grid-item-text">私立靜心高級中學</div>
                    </div>
                    <div className="landing-block-school-grid-item kangchiao">
                        <div className="landing-block-school-grid-item-text">康橋國際學校</div>
                    </div>
                    <div className="landing-block-school-grid-item kweishan">
                        <div className="landing-block-school-grid-item-text">私立奎山實驗高中</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ActivitiesShowcase(props){
    const topLevelStates = useContext(topLevelStatesContext);

    const [activitiesPublicInfo, setActivitiesPublicInfo] = useState(null);
    useEffect(() => {
        if(activitiesPublicInfo === null){
            getShowcaseActivitiesPublicInfo(setActivitiesPublicInfo);
        }
    });
    const activatedStateMap = {"activated": "🏃‍♀️ 活動進行中", "planning": "🟢 熱烈招募中！"};

    const [viewingActvid, setViewingActvid] = useState(null);
    const [showingPopup, setShowingPopup] = useState(false);
    function copyViewingActvid(){
        navigator.clipboard.writeText(viewingActvid);
        topLevelStates.addNotification("已複製至剪貼簿");
    }

    return (
        <>
            { (activitiesPublicInfo || []).map((activity, index) => {
                return <CommunityBanner key={ "landing-activities-" + index } 
                    width="380" height="240" onClick={ () => {setViewingActvid(activity.actvid); setShowingPopup(true)} }
                    style={{ display: 'inline-block', margin: '0 20px', whiteSpace: 'normal', overflow: 'hidden', textAlign: 'left', cursor: 'pointer' }}
                    communityReferenceId={ activity.communityReferenceId }
                    bannerTitle={ activity.name } bannerSubtitle={ <>
                        { activatedStateMap[activity.activated || "planning"] }<br/>
                        { activity.workingDescription }
                    </> } />
            }) }
            <Popup showing={ showingPopup } closePopupFunction={ setShowingPopup }>
                <PopupSection style={{ textAlign: 'left', whiteSpace: 'normal' }}
                    sectionTitle={ "加入活動：" + (activitiesPublicInfo || []).find(activity => activity.actvid === viewingActvid)?.name }>
                    想要加入志工的行列嗎？您是第一類還是第二類呢？請依照下列指示執行。若您還有操作上的問題，可以參閱使用說明<a href="https://quiver-capacity-c77.notion.site/cdfa1ac452ec406da93eb409e3ec45ae">（點此），或者聯絡 contact@infvolunteer.org</a>
                </PopupSection>
                <PopupSection style={{ textAlign: 'left' }}
                    sectionTitle="🟢 第一類：我沒有平台帳號">
                    請點擊此連結註冊帳號：<a href={ "https://infvolunteer.org/Login?toJoin=" + viewingActvid }>{ "https://infvolunteer.org/Login?toJoin=" + viewingActvid }</a>
                </PopupSection>
                <PopupSection style={{ textAlign: 'left' }}
                    sectionTitle="🟢 第二類：我已註冊帳號" >
                    
                    請於登入後至平台<Link to="/Dashboard">首頁</Link>，於「申請加入梯次」中輸入活動代碼：<br/>
                    <IconTextPair style={{ cursor: 'pointer' }} svgName="copy" onClick={ copyViewingActvid }>{ viewingActvid }</IconTextPair>
                </PopupSection>
            </Popup>
        </>
    )
}

export default Landing;