import '../style/BaseNav.css';
import { Link } from 'react-router-dom';

function BaseNav(){
    return(
        <nav>
            <div className="nav-left">
                <div className="nav-logo"></div>
            </div>
            <Link to="/Login">
                <div className="nav-right">登入 / 註冊</div>
            </Link>
            
        </nav>
    );
}

export default BaseNav;